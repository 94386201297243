import axios from "axios";
import { BASE_URL } from "../../../utils/constants";

const baseURL = BASE_URL; //process.env.NEXT_PUBLIC_GMIND_BASEURL;

function useEndpoint() {
  async function signup(data: any) {
    try {
      const res = await axios.post(
        `${baseURL}/user/signup`,
        JSON.stringify(data),
        {
          timeout: 12000,

          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: (status) => status >= 200 && status <= 505,
        }
      );
      if (res.data.statusCode !== 1)
        throw new Error(res.data.message);
      return {
        ...res.data,
        success: true,
      };
    } catch (err: any) {
      return {
        success: false,
        message: err.message,
        statusCode: err.statusCode,
      };
    }
  }
  async function resetPassword(data: any) {
    try {
      const res = await axios.post(
        `${baseURL}/user/password/reset`,
        JSON.stringify(data),
        {
          timeout: 12000,
          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: (status) => status >= 200 && status <= 505,
        }
      );
      if (res.data.statusCode !== 1)
        throw new Error(res.data.message);
      return {
        ...res.data,
        success: true,
      };
    } catch (err: any) {
      return {
        success: false,
        message: err.message,
        statusCode: err.statusCode,
      };
    }
  }

  async function login(data: any) {
    try {
      const res = await axios.post(
        `${baseURL}/user/signin`,
        JSON.stringify(data),
        {
          timeout: 12000,
          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: (status) => status >= 200 && status <= 505,
        }
      );
      console.log(res);
      if (res.data.statusCode !== 1)
        throw new Error(res.data.message);
      return {
        ...res.data,
        success: true,
      };
    } catch (err: any) {
      return {
        success: false,
        message: err.message,
        statusCode: err.statusCode,
      };
    }
  }
  async function verifyEmail(_data: any) {
    try {
      const res = await axios.post(
        `${baseURL}/user/email/verify`,
        JSON.stringify(_data),
        {
          timeout: 12000,
          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: (status) => status >= 200 && status <= 505,
        }
      );

      if (res.data.statusCode !== 1)
        throw new Error(res.data.message);
      return {
        ...res.data,
        success: true,
      };
    } catch (err: any) {
      if (err.statusCode === 2) {
        return {
          success: false,
          message: err.message,
          statusCode: err.statusCode,
        };
      }

      return {
        success: false,
        message: err.message,
        statusCode: err.statusCode,
      };
    }
  }
  async function resendEmailOTP(_data: any) {
    try {
      const res = await axios.post(
        `${baseURL}/user/email/otp/send`,
        JSON.stringify(_data),
        {
          timeout: 12000,
          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: (status) => status >= 200 && status <= 505,
        }
      );

      if (res.data.statusCode !== 1)
        throw new Error(res.data.message);
      return {
        ...res.data,
        success: true,
      };
    } catch (err: any) {
      if (err.statusCode === 2) {
        return {
          success: false,
          message: err.message,
          statusCode: err.statusCode,
        };
      }

      return {
        success: false,
        message: err.message,
        statusCode: err.statusCode,
      };
    }
  }
  async function resetPasswordOTP(_data: { email: string }) {
    try {
      const res = await axios.post(
        `${baseURL}/user/password/otp/send`,
        JSON.stringify(_data),
        {
          timeout: 12000,
          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: (status) => status >= 200 && status <= 505,
        }
      );
      console.log(res);
      if (res?.data.statusCode !== 1)
        throw new Error(res.data.message);
      return {
        ...res.data,
        success: true,
      };
    } catch (err: any) {
      return {
        success: false,
        message: err.message,
        statusCode: err.statusCode,
      };
    }
  }
  // add the endpoints functions here
  return {
    signup,
    login,
    resetPassword,
    verifyEmail,
    resendEmailOTP,
    resetPasswordOTP,
  };
}
export default useEndpoint;