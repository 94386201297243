import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/header';
import LandingPage from '../root';
import Footer from '../components/footer';
import "./Layout.scss"
import { ToastContainer } from 'react-toastify';
import { getUrlPath } from '@src/utils/app_functions';
import DashboardFrame from '@src/components/DashboardFrame';
import FeaturesScreen from '../features';

interface LayoutProps {
    children?: React.ReactNode;
    isLoggedIn?: boolean;
}

const Layout = ({ children, isLoggedIn = false }: LayoutProps) => {
    const location = useLocation();
    const [isRoot, setIsRoot] = useState<boolean>( location.pathname === '/');
    const [isFeatures, setIsFeatures ] = useState<boolean>( location.pathname === '/features' );
    const faqRef = useRef<HTMLDivElement | null>(null);
    const featuresRef = useRef<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState(true);
    const [showFrame, setShowFrame] = useState<boolean>(false);


    useEffect(() => {
        let urlDetails = getUrlPath();
        if ( urlDetails.pagename.toLowerCase() === 'pricing' ){
            setShowFrame(true);
        }else{
            setShowFrame(false);
        }

        setIsRoot(location.pathname === '/');
        setIsFeatures( location.pathname === '/features' )
    }, [window.location.pathname])

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const navigate = useNavigate();

    return (
        <div className="layout-container flex justify-center items-start min-h-screen ">
            {
                showFrame ? (
                    <DashboardFrame
                        showSidebar={false}
                        showHistory={false}
                        showTop={!isMobile}
                        history={[]}
                        showRightComponent={false}
                        rightComponentPlaceholder='Pricing'
                        selectedHistory={(v: string) => {}}
                        closeIdValue={''}
                        showHistoryOption={false}
                        showPagePath={false}
                        onDeleteHistory={(id: string) => {
                            
                        }}
                        onDeleteAllHistory={() => {}}
                        canNavigateHome = {false}
                        
                    >
                       <Outlet /> 
                    </DashboardFrame>
                ): (
                    <div className="layout max-w-1440 min-w-custom w-full "> 
                        <header>
                            <div className="w-full px-10 md:px-10"> 
                                <Header
                                    onClickFaQ={() => scrollToSection(faqRef)} 
                                    onClickFeature={() => isFeatures ? scrollToSection(featuresRef) :navigate("/features")}
                                    onClickPricing={() => isLoggedIn ? navigate("/dashboard/pricing") : navigate("/pricing") }
                                    isLoggedIn={isLoggedIn ?? false} 
                                />
                            </div>
                        </header>
                        <main>
                            {
                                isRoot ? 
                                    <LandingPage isLoggedIn={isLoggedIn} faqRef={faqRef} />
                                : ( isFeatures ? <FeaturesScreen isLoggedIn={isLoggedIn} faqRef={faqRef} featuresRef={featuresRef} /> : <Outlet /> )
                             }
                        </main> 
                    </div>
                )
            }
            <ToastContainer />
        </div>
    );
};

export default Layout;
