import React from "react";
import { MyCircleIcon, MyFileIcon } from "@components/Svg";
interface UploadRecordProps {
  onUpload: () => void;
}

const UploadRecord = ({onUpload}: UploadRecordProps) => {
  return <div className="upload--audio--card" onClick={onUpload}>
    <MyCircleIcon/>
    <div style={{ marginLeft: "12px" }}><MyFileIcon/></div>
    <span style={{ marginLeft: "8px" }}>Upload Audio</span>
  </div>;
};

export default UploadRecord;
