export type SoundModelType = {
    id: number;
    title: string;
    name: string;
    gender: string;
    avatar_img: string;
    sound_url?: string;
};
export const sound_models: SoundModelType[] = [
    {
        id: 1,
        title: 'Gabriel',
        name: 'alloy',
        gender: 'male',
        avatar_img: '/assets/gabriel.svg',
        sound_url: 'https://res.cloudinary.com/dobksjz6x/video/upload/v1719088766/audio_h45gbk.mp3',
    },
    {
        id: 2,
        title: 'Akowe',
        name: 'echo',
        gender: 'male',
        avatar_img: '/assets/akowe.svg',
        sound_url: 'https://res.cloudinary.com/dobksjz6x/video/upload/v1719088835/audio_cyxd2y.mp3',
    },
    {
        id: 3,
        title: 'Aria',
        name: 'nova',
        gender: 'female',

        avatar_img: '/assets/aria.svg',
        sound_url: 'https://res.cloudinary.com/dobksjz6x/video/upload/v1719088915/audio_oo9m90.mp3',
    },
    {
        id: 4,
        title: 'Ritah',
        name: 'shimmer',
        gender: 'female',

        avatar_img: '/assets/ritah.svg',
        sound_url: 'https://res.cloudinary.com/dobksjz6x/video/upload/v1719088973/audio_s7axvz.mp3',
    },
    {
        id: 5,
        title: 'Zachy',
        name: 'fable',
        gender: 'male',
        avatar_img: '/assets/zachy.svg',
        sound_url: 'https://res.cloudinary.com/dobksjz6x/video/upload/v1719089050/audio_k8fgij.mp3',
    },
];
