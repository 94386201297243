import DashboardFrame from "@src/components/DashboardFrame";
import ComingSoon from "./coming_soon";
import { useEffect, useState } from "react";
import NewComingSoon from "./new_coming_soon";

const ComingSoonPage = () => {
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    
    return (
        <DashboardFrame
            showSidebar={!isMobile}
            showHistory={!isMobile}
            showTop={!isMobile}
            history={[]}
            selectedHistory={(v: string) => {
                
            }}
            showPagePath={false}
        >
            {/* <ComingSoon /> */}
            <NewComingSoon />
        </DashboardFrame>
    );
}


export default ComingSoonPage