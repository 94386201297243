/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor';
import {
    table,
    font,
    fontSize,
    formatBlock,
    list,
    align,
    video,
    imageGallery,
    hiliteColor,
    fontColor,
    link,
    image,
} from 'suneditor/src/plugins';
import { marked } from 'marked';
import { Socket, io } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { useSearchParams } from 'react-router-dom';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor's CSS
import './editor/styles/index.scss';
import { UpdateDocument } from '@src/core/endpoints/essay';

const SunEditorComponent = ({
    humanized_content,
    translated_content,
    hasAccess,
    initial_content,
    onChangeEditorContent,
}: {
    translated_content?: any;
    humanized_content?: any;
    initial_content: any;
    hasAccess: boolean;
    onChangeEditorContent: (content: string) => void;
}) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const [params] = useSearchParams();
    const documentId = params.get('document');

    const desktopToolBarValue = ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save'];
    const mobileToolBarValue = ['link', 'image', 'video', 'showBlocks', 'codeView', 'preview', 'print', 'save'];

    const [socket, setSocket] = useState<Socket | null>(null);
    const EditorRef = useRef<any>();
    const [editorContent, setEditorContent] = useState<string>(initial_content);
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const token = localStorage.getItem('token') || '';
    const editor_content = useRef<string | null>(null);
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    



    const handleContentChange = (content: string) => {
        onChangeEditorContent(content);
        setEditorContent(content);
        editor_content.current = content;
        // if (socket != null) {
        //     socket.emit('send-changes', { data: content, documentId });
        //     UpdateDocument(token, { documentId, data: { title: '', data: content } }).then(({ status }) => {
        //         status === 200 && console.log('Updated document');
        //     });
        // }
    };

    useEffect(() => {
        const Socket = io(BASE_URL.slice(0, -2));

        if (hasAccess) {
            Socket.emit('register_document_space', { documentId, userId: user?.id });
        }

        setSocket(Socket);
    }, []);

    useEffect(() => {
        let editor: any;

        if (editorRef.current) {
            editor = SunEditor.create(editorRef.current, {
                toolbarContainer: '#toolbar_container',
                showPathLabel: false,
                // charCounter: true,

                width: 'auto',
                maxWidth: '100%',
                height: '100%',
                stickyToolbar: 'true',
                // minHeight: '22rem',
                // maxHeight: '100%',
                buttonList: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    isMobile ? mobileToolBarValue : desktopToolBarValue,
                ],
                plugins: [
                    table,
                    font,
                    fontSize,
                    formatBlock,
                    list,
                    align,
                    video,
                    imageGallery,
                    hiliteColor,
                    fontColor,
                    link,
                    image,
                ],
                font: [
                    'Arial',
                    'Calibri',
                    'Comic Sans MS',
                    'Courier New',
                    'Georgia',
                    'Impact',
                    'Lucida Console',
                    'Palatino Linotype',
                    'Tahoma',
                    'Times New Roman',
                    'Trebuchet MS',
                    'Verdana',
                    // Add more fonts as needed
                ],
                callBackSave: function (contents, isChanged) {
                    console.log(contents);
                },
            });
                

            // Set initial content
            let initialContent = marked(initial_content);
            if (humanized_content) {
                initialContent = marked(humanized_content);
            } else if (translated_content) {
                initialContent = marked(translated_content);
            }

            editor.setContents(initialContent);

            editor.onChange = () => {
                handleContentChange(editor.getContents());
            };
            EditorRef.current = editor;
        }

        return () => {
            // Cleanup SunEditor instance on component unmount
            if (editor) {
                editor.destroy();
            }
        };
    }, [initial_content, translated_content, humanized_content]);

    useEffect(() => {
        // if (initial_content) {
        //     localStorage.removeItem('essay_data');
        // }
        if (!hasAccess) {
            console.log('No access');
            const marked_data = marked(
                "<p><strong>You don't have access to this document. Contact the owner to send you an invite.</strong></p>",
            );
            initial_content = marked_data;
            EditorRef.current?.disable();
        }
    }, []);

    useEffect(() => {
        if (!socket) return;
        const interval = setInterval(() => {
            // hasAccess &&
            //     UpdateDocument(token, { documentId, data: { title: '', data: editor_content.current } })
            //         .then(({ status }) => {
            //             const data_exist = localStorage.getItem('essay_data');
            //             if (data_exist) {
            //                 localStorage.removeItem('essay_data');
            //             }
            //             status !== 200 && console.log('Update document failed');
            //         })
            //         .catch((e: any) => {
            //             if (e.response) {
            //                 console.error(e.response?.data?.message);
            //             } else {
            //                 console.log(e);
            //             }
            //         });
        }, 2000);

        socket.on('receive-changes', ({ data, documentId: docId }) => {
            if (!EditorRef.current) return;
            if (docId === documentId) {
                EditorRef.current?.setContents(data);
            }
        });

        return () => {
            clearInterval(interval);
        };
    }, [socket]);

    return (
        <div className="h-[72vh] overflow-auto">
            <div id="row"></div>
            <div ref={editorRef} id="lineBreak"></div>
        </div>
    );
};

export default SunEditorComponent;
