import { newGenerationHomeDataProps } from "../interface";
import syllabusImg from '@icons/syllabus.svg';
import lessonPlanner from '@icons/lesson_planner.svg';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import research from '@icons/research.svg';
import article from '@icons/article.svg';
import comprehensive from '@icons/comphrensive.svg';
import socialMedia from '@icons/social_media.svg';
import letterImg from '@icons/letter.svg';
import { copyData, handleShare } from "@src/utils/app_functions";
import { PromptOptionsType } from "@src/core/interfaces/general";
import activity from '@assets/activity.svg';
import { AssessementWhiteImage } from "@src/components/Sidebar/images";


const newGenerationHomeData: Array<newGenerationHomeDataProps> = [
    {
        img: syllabusImg,
        head: 'Short General Content Creator',
        text: 'Transform ideas into engaging content in seconds',
        pageIndex: 1,
        pageName: "Short Content Writer",
        label: 'short_content',
    },
    {
        img: lessonPlanner,
        head: 'Learning Materials Generator',
        text: 'Turn your Topics into captivating learning materials that inspire and educate',
        pageIndex: 2,
        pageName: "Learning Materials",
        label: 'learning_materials',
    },
    {
        img: comprehensive,
        head: 'Comprehensive Content Creator',
        text: 'Convert your ideas into detailed, engaging content, effortlessly!',
        pageIndex: 3,
        pageName: "Comprehensive Content Writer",
        label: 'comprehensive_content',
    },
    {
        img: letterImg,
        head: 'Letter Writer',
        text: 'Idea to Heartfelt Letters in Minutes',
        pageIndex: 4,
        pageName: "Letter Writer",
        label: 'letter_writer',
    },
    {
        img: research,
        head: 'Make Research',
        text: 'Turn your ideas into authoritative, research-backed content, instantly!',
        pageIndex: 6,
        pageName: "research",
        naviagatePage: true,
        label: 'research',
    },
    {
        img: article,
        head: 'Article Writer',
        text: 'Turn your ideas into high-quality, engaging articles that inform, educate, and entertain, fast!',
        pageIndex: 7,
        pageName: "article",
        naviagatePage: true,
        label: 'article',
    },
    {
        img: socialMedia,
        head: 'Social Media Writer',
        text: 'Turn your ideas into shareable, likable, and lovable social media content, fast',
        pageIndex: 5,
        pageName: "Social Media",
        label: 'social_media',
    },
    {
        img: activity,
        imgElement: AssessementWhiteImage(),
        head: 'Assessment Generator',
        text: 'Create and design assessments, such as quizzes, tests, and exams, in a efficient and organized manner.',
        pageIndex: 8,
        pageName: "assessment",
        naviagatePage: true,
        label: 'activity',
    },
];

const contentPromptOptionsData = ({response, onSuccess, urlRef, showDownload}: {
    response:string;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    urlRef: React.MutableRefObject<string>;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Share',
        icon: ShareImg,
        onClick: () => handleShare({
            response,
            onSuccess,
            urlRef,
        }),
    },
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const contentPromptOptionsData2 = ({response, showDownload}: {
    response:string;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];


export {
    newGenerationHomeData,
    contentPromptOptionsData,
    contentPromptOptionsData2,
}