import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/dashboard/chat'); // Redirects to the specified route
          }, 5000);
      
          // Cleanup function to clear the timeout if the component unmounts
          return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="mx-0 md:mx-20 my-[15%] md:my-[10%] h-full w-full flex flex-col items-center justify-center">
            <div className="card-success shadow-none md:shadow-md">
                <div className="rounded-[12.5rem] h-[12.5rem] w-[12.5rem] mt-0 mx-auto shadow-md bg-[#F8FAF5]"
                >
                    <i className="checkmark text-[#9ABC66] text-[100px] ml-[30%]"
                        style={{
                            lineHeight: '200px'
                        }}
                    >✓</i>
                </div>
                <h1 className="text-[#88B04B] text-center font-extrabold text-[40px] mb-[10px] font-optimistic">Success</h1> 
                <p className="text-[#404F5E] text-[20px] m-0 text-center font-optimistic" >Your payment for your gmind package was successful.<br/> You will be redirected to your dashboard to enjoy the full experience of Gmind AI.</p>
            </div>
        </div>
    );
}


export default SuccessPage;