import { GetStartedForFree } from "@src/components/GetStartedButton";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router";
import FeaturesAbout from "./components/features_about";
import Playground from "../components/playground";
import FAQ from "../components/faq";
import { landingPagePrps } from "@src/core/interfaces/general";
import Footer from "../components/footer";
import wavyDesign from "../../../assets/Wave element.png";
import AdvancedFeatures from "./components/advanced_features";
import { useEffect } from "react";
import Services from "../components/services/indes";


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};



 const FeaturesScreen = ({faqRef, featuresRef ,isLoggedIn}: landingPagePrps) => {
    const query = useQuery();
    const navigatePage = useNavigate();

    const scrollToSection = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const scrollToFeature = query.get('showSection') || '';

        if ( scrollToFeature === '1' ){
            if ( featuresRef ){
                scrollToSection(featuresRef);
            } 
        }

        navigatePage({
            pathname: window.location.pathname,
            search: ''
        }, { replace: true });
    }, []);

    return (
        <div className="w-full bg-white pt-2">
            <Helmet>
                <title>Features</title>
                <meta title="GMIND Ai | Best AI Assistant for TEACHERS and CONTENT CREATORS" content="GMind AI is a cutting-edge tool for educators, providing AI-generated content like text, videos, and slides, aiding teachers and e-learning creators in revolutionizing education." name="description"/>
                <link rel="canonical" href="https://gmind.ai/features" />
                <meta name="keywords" content="gmind, ai, chat" />
            </Helmet>

            <div className="relative w-full h-auto md:h-[30rem] mb-10 md:mb-16 z-10">
                <div className="w-full h-full px-10 md:px-10">
                    <FeaturesAbout isLoggedIn={isLoggedIn} />
                </div>
                <img className="absolute top-[70%] bottom-[0%] w-full h-[7rem] md:h-[10rem] z-0" src={wavyDesign} alt="wavyDesign" />
            </div>
            <div className="flex md:hidden justify-center items-center">
                <GetStartedForFree isLoggedIn={isLoggedIn} className="w-[10rem]" onclick={() => {
                        if ( isLoggedIn ) {
                            navigatePage("/dashboard/chat");
                        }else {
                        navigatePage("/signup?forFree=1"); 
                        } 
                    }}  
                />
            </div>
            <div className="w-full">
                <section ref={featuresRef}  className="w-full mt-5 md:mt-0 px-0 md:px-20">
                    <Playground />
                    {/* <Partners /> */}
                </section>
                {/* <section className="w-full mt-10">
                    <AdvancedFeatures />
                </section> */}
                <section className="w-full mt-20 md:mt-5 px-10 md:px-20 mb-10">
                    <Services />
                </section>
                <section ref={faqRef} className="w-full mt-5 md:mt-0 px-10 md:px-20">
                    <FAQ />
                </section>
                <section>
                    <Footer />
                </section>
            </div>
        </div>
    );
 }


 export default FeaturesScreen;