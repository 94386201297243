import LoadingComponent from "@src/components/LoadingComponent";
import { useState } from "react";
import MarkdownDesign from '@src/components/Markdown';
import AcquisitionFrame from "../components/aquisition_frame";
import BackButton from "@src/components/BackButton";
import PromptSentToAI from "@src/components/prompt_sent";
import ResponseTextBox from "./components/response_box";
import { useNavigate } from "react-router";


const PlaygroundResponse = () => {
    const navigate = useNavigate();
    const [generating, setGenerating] = useState(false);
    const [response, setResponse] = useState('');
    const [promptSent, setPromptSent] = useState('');
    return (
        <AcquisitionFrame>
            <div className="w-full flex flex-col py-5 gap-y-5 bg-white">
                <BackButton />
                <div className="px-[20%] w-full flex flex-col gap-y-3 bg-white">
                    <PromptSentToAI promptSent={promptSent}  />
                    {generating ? (
                        <LoadingComponent width="100%" />
                    ) : (
                        <>
                          <div className="relative">
                            <ResponseTextBox 
                                promptResponse={
                                    <MarkdownDesign className="">
                                        {/* {response} */}
                                        {
                                            `# **Best Fashion Designing Techniques - Your Stepping Stone to become a Top Fashion Designer**

Fashion designing is an art that gives expression to one's creativity, personal style, and individuality. It is a field that sees constant innovation and evolution, making it a thrilling and highly creative avenue for those who seek to make their mark in the world of design. But how does one go from obscurity to the brightness of the fashion runway? In this comprehensive guide, we'll explore the various techniques and step-by-step approach to help you become a top fashion designer. 

## **Understanding the Basics**

The world of fashion design is wide and diverse, offering a myriad of techniques to create beautiful and unique pieces. But the basics are often the building blocks of any spectacular design. Here are some basic techniques to master:

1. **Sketching**: This is your preliminary step, where you sketch your ideas and concepts. Try to be as detailed as possible. Include various angles, colours, fabric details, embroidery or embellishments.

2. **Draping**: This technique involves arranging and pinning fabric on a dress form to visualize the overall design before sewing.

3. **Pattern Making**: It involves creating a blueprint for your design. It is a crucial step that ensures your design correctly fits the body shape for which it is intended.

4. **Sewing**: The core of fashion designing. If you dream of becoming a top-notch fashion designer, basic understanding and application of different sewing techniques is a must.

5. **Fashion Illustration**: Another invaluable skill. Understanding the human anatomy and being able to put it on paper is crucial for visualising and presenting your designs to clients and the fashion world.

## **Elevating your Skills - Step-by-step**

### **Step 1: Education and Skill Acquisition**

Acquire the necessary education in fashion designing. There are many prestigious fashion schools where you can earn a degree or diploma. Courses typically cover subjects like fashion history and theory, textiles, sketching and drawing, pattern drafting, and sewing. Also, hone your skills in areas like Adobe Illustrator and Photoshop. 

### **Step 2: Practical Experience**

Intern with reputed fashion designers or design houses. Here, you'll gain hands-on experience in the fashion industry and understand the workflow and process behind creating a collection and launching it into the market.

### **Step 3: Develop Your Portfolio**

Keep adding your best work
                                            `
                                        }
                                    </MarkdownDesign>
                                }
                            />
                            <div className="absolute w-full top-[70%] right-0 h-[75%] flex flex-row items-start justify-center" style={{
                                backgroundColor: "rgba(255, 255, 255, 0.8)"
                            }}>
                                <button 
                                    onClick={() => navigate('/signup')}
                                    className="text-white w-[13rem] rounded-[0.6rem] bg-[var(--gmind-orange)] px-5 py-3 font-normal text-[0.8rem]">
                                    See All
                                </button>
                            </div> 
                          </div>
                        </>
                    )}
                </div>
            </div>
        </AcquisitionFrame>
    );
}


export default PlaygroundResponse