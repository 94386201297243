import menu from '@assets/transcriber/menu.svg';
import logo from '@assets/gmind_logo_blue.png';
import history from '@assets/history_clock.svg';
import { useNavigate } from 'react-router-dom';
import BackButton from '@src/components/BackButton';
import { useEffect } from 'react';
const MobileNavbar = ({
    showSidebar,
    showHistory,
    style,
    showMobileHistory,
    className,
}: {
    showSidebar: () => void;
    showHistory: () => void;
    style?: React.CSSProperties;
    showMobileHistory?: boolean;
    className?: string;
}) => {
    const navigate = useNavigate();

    useEffect(() => {
        console.log(window.location.pathname);
    }, [])

    return (
        <section style={style} className={'p-6 flex items-center justify-between ' + className}>
            {
                window.location.pathname == 'pricing' || window.location.pathname == '/pricing' ? (
                    <>
                        <BackButton
                            onclick={() => {
                                navigate(-1);
                            }}
                        />
                    </>
                ) :(
                    <button onClick={showSidebar} type="button">
                        <img src={menu} alt="menu" />
                    </button>
                ) 
            } 
            <div>
                <img onClick={() => navigate('/')} src={logo} alt="" />
            </div>
            {showMobileHistory ? (
                <button onClick={showHistory} type="button">
                    <img src={history} alt="history" />
                </button>
            ) : (
                <div></div>
            )}
        </section>
    );
};

export default MobileNavbar;
