import React, { useEffect, useRef, useState } from 'react';
import { LucidePlay, LucidePause, Download, Copy, Share2 } from 'lucide-react';
import useSpeechSynthesizerStore from '@src/providers/useSpeechSynthesizerStore';
import { Tag } from 'antd';
import { Toaster, toast } from 'sonner';
import { sound_models } from '.';

const AudioPlayer = () => {
    const { audioUrl } = useSpeechSynthesizerStore();
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState('0:00');
    const [duration, setDuration] = useState('0:00');
    const [blob, setBlob] = useState(null);
    const { voiceModel, setVoiceModel } = useSpeechSynthesizerStore();

    useEffect(() => {
        const audio = audioRef.current;

        if (audioUrl && audio) {
            fetch(audioUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    setBlob(blob);
                    audio.src = URL.createObjectURL(blob);
                });
        }

        if (audio) {
            const updateProgress = () => {
                if (audio.currentTime && audio.duration) {
                    const newTime = formatTime(audio.currentTime);
                    setCurrentTime(newTime);
                }
            };

            const updateDuration = () => {
                if (audio.duration) {
                    const newDuration = formatTime(audio.duration);
                    setDuration(newDuration);
                }
            };

            const handleEnded = () => {
                setIsPlaying(false);
                setCurrentTime('0:00');
            };

            audio.addEventListener('timeupdate', updateProgress);
            audio.addEventListener('loadedmetadata', updateDuration);
            audio.addEventListener('ended', handleEnded);

            return () => {
                audio.removeEventListener('timeupdate', updateProgress);
                audio.removeEventListener('loadedmetadata', updateDuration);
                audio.removeEventListener('ended', handleEnded);
            };
        }
    }, [audioUrl]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${formattedSeconds}`;
    };

    const togglePlay = () => {
        const audio = audioRef.current;
        if (audio) {
            if (audio.paused) {
                audio.play();
                setIsPlaying(true);
            } else {
                audio.pause();
                setIsPlaying(false);
            }
        }
    };
    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Audio Share',
                    text: 'Check out this audio!',
                    url: audioUrl,
                })
                .then(() => toast.success('Audio shared successfully!', { duration: 3000 }))
                .catch((error) => toast.error(`Error sharing audio: ${error}`, { duration: 3000 }));
        } else {
            toast.error('Sharing not supported on this browser.', { duration: 3000 });
        }
    };

    const handleCopy = () => {
        navigator.clipboard
            .writeText(audioUrl)
            .then(() => toast.success('Audio URL copied to clipboard!', { duration: 3000 }))
            .catch((error) => toast.error(`Error copying audio URL: ${error}`, { duration: 3000 }));
    };

    // const handleDownload = () => {
    //     // Creating an anchor element
    //     const a = document.createElement('a');
    //     // Setting the href attribute to the dynamic audio URL
    //     a.href = audioUrl;
    //     // Setting the download attribute to the desired file name
    //     a.download = 'audio.mp3';
    //     a.addEventListener('click', (event) => event.preventDefault());

    //     // Appending the anchor to the body
    //     document.body.appendChild(a);
    //     // Simulating a click on the anchor to trigger the download
    //     a.click();
    //     // Removing the anchor from the body after the click
    //     document.body.removeChild(a);

    //     // Showing a success message using toast
    //     toast.success('Audio file downloaded!', { duration: 3000 });
    // };

    const handleVoiceModelChange = (event) => {
        const { value } = event.target;
        setVoiceModel(value);
    };
    return (
        <div className="  mt-6 min-h-[40vh]">
            <Toaster richColors />
            <div className="f lex  items-center justify-between mb-4 bg-[#F9FAFC] rounded-lg shadow-lg p-6 pt-2">
                <p className="flex flex-row gap-2 items-center">
                    <img src="/assets/gmind-icon.png" className="w-[20px] h-[20px]" />
                    <small> G-mind</small>
                </p>
                <div className="flex items-center space-x-4 border border-[#e55901] p-2 rounded-md mt-1 border-dashed">
                    {isPlaying ? (
                        <LucidePause
                            onClick={togglePlay}
                            className="cursor-pointer text-gray-600 hover:text-[#e55901] w-5 h-5"
                        />
                    ) : (
                        <LucidePlay
                            onClick={togglePlay}
                            className="cursor-pointer text-gray-600 hover:text-[#e55901] w-5 h-5"
                        />
                    )}
                    <div className="flex items-center w-full">
                        <div className="flex-1 bg-gray-200 h-1 rounded-full overflow-hidden">
                            <div
                                className="bg-[#e55901] h-1 rounded-full"
                                style={{
                                    width: `${(audioRef.current?.currentTime / audioRef.current?.duration) * 100}%`,
                                }}
                            />
                        </div>
                        <span className="text-xs font-medium text-gray-600 ml-2">
                            {currentTime} / {duration}
                        </span>
                    </div>
                </div>
                <audio ref={audioRef} />
            </div>
            <div className="flex justify-between text-center items-center gap-4  !bg-transparent ">
                <div className="flex gap-2">
                    {/* <Tag>Voice model:{voiceModel ?? ''}</Tag> */}
                    <div className="flex gap-1 items-center !text-[12px]">
                        <label>Voice Model:</label>
                        <select
                            // placeholder="Select Voice Model"
                            value={voiceModel || ''}
                            onChange={handleVoiceModelChange}
                            className="!text-[12px] border p-[0.5px] rounded-sm"
                        >
                            <option value="" disabled>
                                Select Voice Model
                            </option>
                            {sound_models.map((model) => (
                                <option key={model.name} value={model.name}>
                                    {model.title}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex gap-2">
                    
                    <a
                        className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px] shadow-md hover:cursor-pointer"
                        // onClick={handleDownload}
                        href={audioUrl}
                        target='_blank'
                        download={`gmind-genrated-audio-${voiceModel}.mp3`}
                    >
                        <Download size="14px" color="#E55109" />
                        <small>Download</small>{' '}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AudioPlayer;
