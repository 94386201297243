/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import Sidebar from '@components/Sidebar/';
import { Outlet, useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { getUrlPath } from '@src/utils/app_functions';

export default function Root() {
    useEffect(() => {
        const { pathname } = window.location;
        if (pathname === '/dashboard' || pathname === '/dashboard/') {
            // window.location.replace('chat');
        }
    }, []);


    return (
        <div className="flex flex-row overflow-hidden" style={{ scrollbarWidth: 'none' }}>
            {/* <Sidebar
                selected={(v) => {
                    navigate(v)
                }}
            /> */}
            <ToastContainer />
            <Outlet />
        </div>
    );
}
