import axios from 'axios';
import { cloud_name, upload_preset } from '../../slide/component/Forms/second-form';
import { VoiceModel } from '@src/providers/useSpeechSynthesizerStore';
import { BASE_URL } from '@src/utils/constants';

const CLOUDINARY_UPLOAD_URL = `https://api.cloudinary.com/v1_1/${cloud_name}/upload`;
const CLOUDINARY_UPLOAD_PRESET = upload_preset;
interface TextToSpeechParams {
    text: string;
    voice: string;
    // model?: 'tts-1' | 'tts-1-hd';
    // responseFormat?: 'mp3' | 'opus' | 'aac' | 'flac' | 'wav' | 'pcm';
}
export const textToSpeech = async (text: string, voice: string): Promise<string> => {
    const baseurl = BASE_URL;
    try {
        const response = await axios.post(
            `${baseurl}/gmind/textspeech`,
            {
                text: text,
                voice,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'blob',
            },
        );

        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });

        const formData = new FormData();

        formData.append('file', audioBlob, 'audio.mp3');
        formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

        const cloudinaryResponse = await axios.post(CLOUDINARY_UPLOAD_URL, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log(cloudinaryResponse);

        return cloudinaryResponse.data.secure_url;
    } catch (error) {
        console.error('Error generating audio:', error);
        throw new Error('Failed to generate audio');
    }
};
