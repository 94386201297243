import create from 'zustand';

interface ImageStoreState {
    imageType: '' | 'avatars' | 'anime' | 'realistic' | 'cartoon' | 'abstract';
    prompt: string;
    size: string;

    generatedImages: string[];
    setImageType: (type: '' | 'avatars' | 'anime' | 'realistic' | 'cartoon' | 'abstract') => void;
    setPrompt: (prompt: string) => void;
    addGeneratedImage: (url: string) => void;
    clearGeneratedImages: () => void;
    setSize: (size: string) => void;
}

const useImageStore = create<ImageStoreState>((set) => ({
    imageType: 'realistic',
    prompt: '',
    size: '1024x1024',

    generatedImages: [],
    setImageType: (type) => set({ imageType: type }),
    setPrompt: (prompt) => set({ prompt }),
    setSize: (size) => set({ size }),

    addGeneratedImage: (url) => set({ generatedImages: [url] }),
    clearGeneratedImages: () => set({ generatedImages: [] }),
}));

export default useImageStore;
