import textLogo from '@assets/text_logo.svg';

const ContentTypingComponent = ({isTyping}: {isTyping: boolean}) => {
    return ( 
        <div className='mx-[25%] w-[200px] flex items-center gap-x-3 font-normal text-[var(--gmind-orange)] text-[0.8rem] rounded-[0.8rem] self-center border border-[var(--gmind-orange)] px-3 py-2'>
            {isTyping && <img src={textLogo} alt="logo" />}
            <p>
                {isTyping ? 'is Typing' : 'Content is Ready'}
            </p>
        </div>
    );
}

export  default ContentTypingComponent;