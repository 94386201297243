/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from 'react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import SyntaxHighlighter from 'react-syntax-highlighter';
import agate from 'react-syntax-highlighter/dist/esm/styles/hljs/agate';
import 'github-markdown-css';
import './index.css';

interface MarkdownProps {
    children: string;
    className?: string;
}

const Markdown: FC<MarkdownProps> = ({ children, className }) => {
    
    return (
        <div className={clsx(className, 'markdown-body', '!font-optimistic')}>
            <div>
                <ReactMarkdown
                    children={children}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        //ts-ignore
                        code({ className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            return match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={agate as any}
                                    language={match[1]}
                                    showLineNumbers
                                    lineNumberStyle={{ color: '#999' }}
                                    PreTag="div"
                                    {...props}
                                />
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            );
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default Markdown;
