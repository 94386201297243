import { useEffect, useState } from "react";
import { Modal, Form, Input } from 'antd';
import { Button as CustomButton } from "@src/pages/acquisition/pricing/components/ui/button";
import { toast } from "react-toastify";
import { changePassword } from "@src/core/endpoints/user";


interface passwordModalProps {
    isVisible: boolean;
    onCancel: () => void;
}

const UpdatePasswordModal = ({isVisible, onCancel}: passwordModalProps) => {
    const [isModalVisible, setIsModalVisible] = useState(isVisible);
    const [loading, setLoading] = useState(false);
    const [ oldPassword, setOldPassword ] = useState<string>('');
    const [ newPassword, setNewPassword ] = useState<string>('');
    const [ confirmPassword, setConfirmPassword ] = useState<string>('');
    const [canProceed, setCanProceed] = useState<boolean>(false);


    useEffect(() => {
        setIsModalVisible(isVisible);
    }, [isVisible])

    const onupdatePassword = async () => {
        if ( !oldPassword || !newPassword || !confirmPassword ){
            toast.error('Kindly fill all fields');
            return;
        }
        if ( newPassword != confirmPassword ) return;

        try {
            setLoading(true);
            const dataToSend = {
                'password': oldPassword,
                'newPassword': newPassword,
            }
            
            const updated = await changePassword(dataToSend);

            if ( updated.statusCode != 1 ){
                toast.error(updated.message ?? 'Error updating details');
            }else{
                toast.success('Password updated successfully');
                setIsModalVisible(false);
            }

        } catch (error) {
            
        }finally{
            setLoading(false);
        }

    }

    useEffect(() => {
        let value: boolean = Boolean( oldPassword && newPassword && confirmPassword && newPassword == confirmPassword )
        setCanProceed (value);
    }, [oldPassword, newPassword, confirmPassword])


    return (
        <div>
          <Modal
            open={isModalVisible}
            onCancel={() => { onCancel();}}
            closable={true}
            maskClosable={false}
            width={500}
            footer={[
                <CustomButton disabled={loading} variant={'outline'} key="ok" className={`${canProceed ? 'bg-[var(--gmind-orange)] text-white' : 'bg-[#A5A3AE30] text-[#333237]'}`} onClick={onupdatePassword}>
                    Update
                </CustomButton>
            ]}
            className="centered-footer-modal"
          >
            <div className="p-5 flex flex-col gap-y-4">
                <Form layout="vertical" className="flex flex-col gap-y-2 gap-x-3 w-full" onFinish={() => {}} >
                    <Form.Item
                        className={`w-full md:w-full mb-1`}
                        name={'old_password'}
                        label= {
                            <label>Current Password</label>
                        }
                    >       
                        <Input.Password
                            className='w-full'
                            placeholder={'Enter your current password'} 
                            style={{
                                border: '1px solid #E5E8F1',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                            value={oldPassword}
                            onChange={(e) => { setOldPassword(e.target.value) }}
                        />
                        
                    </Form.Item>
                    <Form.Item
                        className={`w-full md:w-full mb-1`}
                        name={'new_password'}
                        label= {
                            <label>New Password</label>
                        }
                    >       
                        <Input.Password
                            className='w-full'
                            placeholder={'***************'} 
                            style={{
                                border: '1px solid #E5E8F1',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                            value={newPassword}
                            onChange={(e) => { setNewPassword(e.target.value) }}
                        />
                        
                    </Form.Item>
                    <Form.Item
                        className={`w-full md:w-full mb-1`}
                        name={'confirm_password'}
                        label= {
                            <label>Confirm Password</label>
                        }
                    >       
                        <Input.Password
                            className='w-full'
                            placeholder={'*****************'} 
                            style={{
                                border: '1px solid #E5E8F1',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                            value={confirmPassword}
                            onChange={(e) => { setConfirmPassword(e.target.value) }}
                        />
                        
                    </Form.Item>  
                </Form>
            </div>
            
          </Modal>
        </div>
      );
}


export default UpdatePasswordModal;