import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';
import { fabric } from 'fabric';
import axios from 'axios';
import { Loader } from 'lucide-react';
import { message } from 'antd';

const REMOVE_BG_API_KEY = 'yUq4CSeb7VpQpE6auAyaY1kt';

const Canvas = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { imageUrl, setCanvas, saveState } = useImageEditorStore();
    const [localCanvas, setLocalCanvas] = useState<fabric.Canvas>();
    const [contextMenu, setContextMenu] = useState<{ x: number; y: number; visible: boolean }>({
        x: 0,
        y: 0,
        visible: false,
    });
    const [activeImage, setActiveImage] = useState<fabric.Image | null>(null);
    const [loading, setLoading] = useState(false);
    let canvas: fabric.Canvas;

    useEffect(() => {
        if (canvasRef.current) {
            canvas = new fabric.Canvas(canvasRef.current, {
                backgroundColor: '#ffffff',
                width: 800,
                height: 600,
                fireRightClick: true, // <-- enable firing of right click events
                fireMiddleClick: true, // <-- enable firing of middle click events
                stopContextMenu: true, // <--  prevent context menu from showing
            });
            setLocalCanvas(canvas);
            setCanvas(canvas);

            canvas.on('mouse:down', (e) => {
                e.e.preventDefault();
                if (e.button === 3) {
                    console.log('hello');
                    setActiveImage(e.target as fabric.Image);
                    const pointer = canvas.getPointer(e.e);
                    setContextMenu({ x: pointer.x, y: pointer.y, visible: true });
                    // setContextMenu({ ...contextMenu, visible: true });
                } else {
                    setContextMenu({ ...contextMenu, visible: false });
                }
            });
        }

        return () => {
            if (canvas) {
                canvas.dispose();
            }
        };
    }, [setCanvas]);
    function handleContextMenu(e: React.MouseEvent<HTMLCanvasElement>) {
        e.preventDefault();
        setContextMenu({ ...contextMenu, visible: true, x: e.clientX, y: e.clientY });
    }

    useEffect(() => {
        console.log(imageUrl);
        if (imageUrl && canvas) {
            fabric.Image.fromURL(imageUrl, (img) => {
                const maxImageSize = Math.min(canvas.getWidth(), canvas.getHeight()) * 0.8;
                img.scaleToWidth(maxImageSize);
                img.scaleToHeight(maxImageSize);
                img.set({
                    left: (canvas.getWidth() - img.getScaledWidth()) / 2,
                    top: (canvas.getHeight() - img.getScaledHeight()) / 2,
                    originX: 'left',
                    originY: 'top',
                });
                canvas.add(img);
                canvas.renderAll();
            });
        }
    }, [imageUrl, localCanvas]);

    const handleDelete = () => {
        if (canvas && activeImage) {
            canvas.remove(activeImage);
            canvas.renderAll();
            setContextMenu({ ...contextMenu, visible: false });
        }
    };

    const handleRemoveBackground = async (image: fabric.Image | null) => {
        if (canvas) {
            const image = canvas.getActiveObject() as fabric.Image;
            if (image && image.type === 'image') {
                setLoading(true);
                const imgElement = image.getElement() as HTMLImageElement;
                const response = await fetch(imgElement.src);
                const blob = await response.blob();
                const formData = new FormData();
                formData.append('image_file', blob);

                try {
                    const res = await axios.post('https://api.remove.bg/v1.0/removebg', formData, {
                        headers: {
                            'X-Api-Key': REMOVE_BG_API_KEY,
                        },
                        responseType: 'blob',
                    });
                    const resultURL = URL.createObjectURL(res.data);
                    fabric.Image.fromURL(resultURL, (img) => {
                        img.set({
                            left: image.left,
                            top: image.top,
                            scaleX: image.scaleX,
                            scaleY: image.scaleY,
                        });
                        canvas.remove(image);
                        canvas.add(img);
                        canvas.renderAll();
                        setLoading(false);
                        setContextMenu({ ...contextMenu, visible: false });
                    });
                } catch (error) {
                    console.error('Error removing background:', error);
                    setLoading(false);
                }
            }
        }
    };
    const handleHideLayer = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                activeObject.visible = !activeObject.visible;
                canvas.renderAll();
                saveState();
            }
        }
    };

    return (
        <div style={{ position: 'relative' }} className="mb-[10%]">
            <canvas
                className="border border-gray-300 shadow-xl  rounded"
                ref={canvasRef}
                // onContextMenu={(e) => handleContextMenu(e)}
            />
            {contextMenu.visible && (
                <div
                    className="absolute bg-white shadow-md p-2 rounded-md"
                    style={{ top: contextMenu.y, left: contextMenu.x }}
                >
                    <button
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full rounded"
                        onClick={handleHideLayer}
                    >
                        Delete
                    </button>
                    <button
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full rounded"
                        onClick={() => handleRemoveBackground(activeImage)}
                    >
                        Remove Background
                    </button>
                </div>
            )}
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                    <Loader className="animate-spin text-gray-600" size={40} />
                </div>
            )}
        </div>
    );
};

export default Canvas;
