import React, { useRef, useState } from 'react';
import { Modal, Button, Input, Upload, message, Col, Row, Space, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';
import { fabric } from 'fabric';
import { LucideUpload } from 'lucide-react';

interface BackgroundImagePickerProps {
    visible: boolean;
    onCancel: () => void;
}

const BackgroundImagePicker: React.FC<BackgroundImagePickerProps> = ({ visible, onCancel }) => {
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const { canvas, saveState } = useImageEditorStore();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleUpload = (info: any) => {
        if (info.file.status === 'done') {
            setImageUrl(info.file.response.url);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };
    const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (canvas && event.target.files) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (fEvent) => {
                const data = fEvent.target?.result as string;
                setImageUrl(data);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOk = () => {
        if (canvas && imageUrl) {
            fabric.Image.fromURL(
                imageUrl,
                (img) => {
                    img.scaleToWidth(canvas.getWidth());
                    img.scaleToHeight(canvas.getHeight());
                    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                        originX: 'left',
                        originY: 'top',
                    });
                    saveState();
                    onCancel();
                },
                { crossOrigin: 'anonymous' },
            );
        }
    };

    return (
        <Modal title="Choose Background Image" visible={visible} onOk={handleOk} onCancel={onCancel}>
            <Space direction="vertical" size="large">
                <Row align="middle" className="flex flex-row">
                    <Col span={16}>Upload Image:</Col>
                    <Col span={18}>
                        {/* <Upload
                            name="image"
                            action="https://your-upload-api-endpoint"
                            onChange={handleUpload}
                            showUploadList={false}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload> */}
                        <Tooltip title="Add a new image" className="!flex flex-row">
                            <button
                                className="flex gap-1 text-center items-center text-[12px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <LucideUpload size="12px" color="#E55109" />
                                <small>Add Image</small>
                            </button>
                        </Tooltip>
                        <input type="file" ref={fileInputRef} onChange={handleAddImage} style={{ display: 'none' }} />
                    </Col>
                </Row>
                {/* <Row align="middle">
                    <Col span={6}>Background Color:</Col>
                    <Col span={18}>
                        <Input type="color" onChange={(e) => setImageUrl(e.target.value)} />
                    </Col>
                </Row> */}
            </Space>
        </Modal>
    );
};

export default BackgroundImagePicker;
