import React from 'react';
import { sound_models } from '.';
import AudioCard from './audio-card';
import useFormStore from '@src/providers/formStore';
import useSpeechSynthesizerStore from '@src/providers/useSpeechSynthesizerStore';
import { ChevronLeft, Circle, FileText, Folder } from 'lucide-react';
import { Toaster, toast } from 'sonner';

type Props = {};
type source = 'manual' | 'upload';

function SecondScreen({}: Props) {
    const { textSource, setTextSource } = useSpeechSynthesizerStore();
    const { nextStep, previousStep } = useFormStore();
    function handleSourceClick(source: source) {
        setTextSource(source);
        // toast.info('Switched to ' + textSource + ' mode');
    }
    function handleNext(e: React.MouseEvent) {
        e.preventDefault();
        nextStep();
    }
    function Back() {
        previousStep();
    }
    return (
        <section>
            <Toaster richColors />
            <button
                onClick={Back}
                className="text-center text-[12px] flex justify-center items-center mb-[2] pt-2 gap-3 "
            >
                <ChevronLeft className="bg-gray-200 rounded-full !font-[300] text-gray-700 !text-sm" />
                <span>Back</span>
            </button>
            <h1 className="text-[#232323] text-[30px] mt-[3%]">Speech Synthesizer</h1>
            <p className="text-gray-400 text-[14px] font-[400] mb-[4%]">
                Select whether you want to write the text or upload from your device{' '}
            </p>
            {/* buttttons */}
            <div className="flex flex-col md:flex-row  gap-y-5 md:gap-36">
                <button
                    onClick={() => handleSourceClick('manual')}
                    className={`flex border-[1.7px] p-2 rounded-md gap-4 ${textSource === 'manual' ? 'border border-[#E55109] bg-[#ffebdf] !text-[#e55901] ' : ''}`}
                >
                    <Circle color={`${textSource === 'manual' ? '#e55901' : 'gray'}`} size={18} />
                    <div className="flex gap-1">
                        <FileText color={`${textSource === 'manual' ? '#e55901' : 'gray'}`} size={18} />
                        <small>Manual</small>
                    </div>
                </button>
                <button
                    onClick={() => handleSourceClick('upload')}
                    className={`flex border-[1.7px] p-2 rounded-md gap-4 ${textSource === 'upload' ? 'border border-[#E55109] bg-[#ffebdf] !text-[#e55901] ' : ''}`}
                >
                    <Circle color={`${textSource === 'upload' ? '#e55901' : 'gray'}`} size={18} />
                    <div className="flex gap-1">
                        <Folder color={`${textSource === 'upload' ? '#e55901' : 'gray'}`} size={18} />
                        <small>Upload Document</small>
                    </div>
                </button>
            </div>

            <button
                onClick={handleNext}
                className="bg-[#E55109] text-white text-center text-[10px] px-3 py-2 rounded-sm w-[7rem] mt-[4%]"
            >
                Next
            </button>
        </section>
    );
}

export default SecondScreen;
