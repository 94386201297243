import React from "react";
import { MyPhoneIcon, MyRedCircleIcon } from "@components/Svg";

interface RecordAudioCardProps {
  onRecord: () => void;
}

const RecordAudioCard = ({onRecord}: RecordAudioCardProps) => {
  return (
    <div className="record--audio--card" onClick={onRecord}>
      <MyRedCircleIcon />
      <div style={{ marginLeft: "12px" }}>
        <MyPhoneIcon />
      </div>

      <span style={{ marginLeft: "8px" }}>Record Audio</span>
    </div>
  );
};

export default RecordAudioCard;
