import React, { useRef, useState } from 'react';
import { ColorPicker, Slider, Tooltip } from 'antd';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';
import {
    LucideCrop,
    LucideRotateCw,
    LucideUpload,
    LucideContrast,
    LucideDownload,
    Sun,
    LightbulbIcon,
    Loader,
    LucidePaperclip,
    Download,
    RemoveFormatting,
    ImageDownIcon,
    SquareActivityIcon,
} from 'lucide-react';
import { fabric } from 'fabric';
import axios from 'axios';
import BackgroundImagePicker from './backgrund-image-picker';
import CanvasSizePicker from './canva-size-picker';
import { BiColorFill } from 'react-icons/bi';

const REMOVE_BG_API_KEY = 'yUq4CSeb7VpQpE6auAyaY1kt';

const EditorNav = () => {
    const { canvas, saveState } = useImageEditorStore();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
    const [backgroundPickerVisible, setBackgroundPickerVisible] = useState(false);
    const [canvasSizePickerVisible, setCanvasSizePickerVisible] = useState(false);

    const handleBackgroundPickerVisible = () => {
        setBackgroundPickerVisible(true);
    };

    const handleCanvasSizePickerVisible = () => {
        setCanvasSizePickerVisible(true);
    };
    const handleRemoveBackground = async () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject() as fabric.Image;
            if (activeObject && activeObject.type === 'image') {
                setLoading(true);
                const imgElement = activeObject.getElement() as HTMLImageElement;
                const response = await fetch(imgElement.src);
                const blob = await response.blob();
                const formData = new FormData();
                formData.append('image_file', blob);

                try {
                    const res = await axios.post('https://api.remove.bg/v1.0/removebg', formData, {
                        headers: {
                            'X-Api-Key': REMOVE_BG_API_KEY,
                        },
                        responseType: 'blob',
                    });
                    const resultURL = URL.createObjectURL(res.data);
                    fabric.Image.fromURL(resultURL, (img) => {
                        img.set({
                            left: activeObject.left,
                            top: activeObject.top,
                            scaleX: activeObject.scaleX,
                            scaleY: activeObject.scaleY,
                        });
                        canvas.remove(activeObject);
                        canvas.add(img);
                        canvas.renderAll();
                        setLoading(false);
                    });
                } catch (error) {
                    console.error('Error removing background:', error);
                    setLoading(false);
                }
            }
        }
    };

    const handleCrop = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject() as fabric.Image;
            if (activeObject && activeObject.type === 'image') {
                const cropped = new fabric.Image(activeObject.getElement(), {
                    left: activeObject.left,
                    top: activeObject.top,
                    scaleX: activeObject.scaleX,
                    scaleY: activeObject.scaleY,
                });
                cropped.set({
                    clipPath: new fabric.Rect({
                        left: activeObject.left,
                        top: activeObject.top,
                        width: 100,
                        height: 100,
                        absolutePositioned: true,
                    }),
                });
                canvas.remove(activeObject);
                canvas.add(cropped);
                canvas.renderAll();
                saveState();
            }
        }
    };

    const handleRotate = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                activeObject.rotate((activeObject.angle ?? 0) + 90);
                canvas.renderAll();
            }
        }
    };

    const handleBrightnessChange = (value: number) => {
        if (canvas) {
            const activeObject = canvas.getActiveObject() as fabric.Image;
            if (activeObject) {
                activeObject.filters = [
                    new fabric.Image.filters.Brightness({
                        brightness: value,
                    }),
                ];
                activeObject.applyFilters();
                canvas.renderAll();
                saveState();
            }
        }
    };

    const handleContrastChange = (value: number) => {
        if (canvas) {
            const activeObject = canvas.getActiveObject() as fabric.Image;
            if (activeObject) {
                activeObject.filters = [
                    new fabric.Image.filters.Contrast({
                        contrast: value,
                    }),
                ];
                activeObject.applyFilters();
                canvas.renderAll();
                saveState();
            }
        }
    };

    const handleSaturationChange = (value: number) => {
        if (canvas) {
            const activeObject = canvas.getActiveObject() as fabric.Image;
            if (activeObject) {
                activeObject.filters = [
                    new fabric.Image.filters.Saturation({
                        saturation: value,
                    }),
                ];
                activeObject.applyFilters();
                canvas.renderAll();
            }
        }
    };

    const handleExport = () => {
        if (canvas) {
            const dataURL = canvas.toDataURL({
                format: 'png',
                quality: 1,
            });
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = 'design.png';
            link.click();
        }
    };

    const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (canvas && event.target.files) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (fEvent) => {
                const data = fEvent.target?.result as string;
                fabric.Image.fromURL(
                    data,
                    (img) => {
                        img.scaleToWidth(canvas.getWidth());
                        img.scaleToHeight(canvas.getHeight());
                        canvas.centerObject(img);
                        canvas.add(img);
                        canvas.renderAll();
                        saveState();
                    },
                    { crossOrigin: 'anonymous' },
                );
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCanvasResize = (width: number, height: number) => {
        if (canvas) {
            canvas.setDimensions({ width, height });
            setCanvasSize({ width, height });
            canvas.renderAll();
        }
    };

    const handleCanvasBackgroundColorChange = (color: any, hex: string) => {
        if (canvas) {
            canvas.setBackgroundColor(hex, canvas.renderAll.bind(canvas));
            saveState();
        }
    };

    const handleCanvasBackgroundImageChange = (url: string) => {
        if (canvas) {
            fabric.Image.fromURL(url, (img) => {
                img.scaleToWidth(canvas.getWidth());
                img.scaleToHeight(canvas.getHeight());
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            });
        }
    };

    return (
        <div className="bg-white text-[#e55901] pt-2 px-2 flex justify-between w-full">
            <Tooltip title="Set Canvas Background Color" className="flex">
                <button
                    className="flex gap-1 text-center items-center text-[12px] p-1 px-2 !py-[0px] bg-[#F9FAFC] rounded-[12px]"
                    // onClick={handleCrop}
                >
                    <SquareActivityIcon size="12px" color="#E55109" />
                    <small>Change canvas Background color</small>
                </button>
                <ColorPicker onChange={handleCanvasBackgroundColorChange} />
            </Tooltip>
            <Tooltip title="Set Canvas Background Image">
                {/* <BackgroundImagePicker onChange={handleCanvasBackgroundImageChange} /> */}
                <BackgroundImagePicker
                    visible={backgroundPickerVisible}
                    onCancel={() => setBackgroundPickerVisible(false)}
                />
            </Tooltip>
            <Tooltip title="Resize Canvas">
                {/* <CanvasSizePicker onChange={handleCanvasResize} /> */}
                <CanvasSizePicker
                    visible={canvasSizePickerVisible}
                    onCancel={() => setCanvasSizePickerVisible(false)}
                />
            </Tooltip>
            <Tooltip title="Crop the selected image">
                <button
                    className="flex gap-1 text-center items-center text-[12px] p-1 px-2 !py-[0px] bg-[#F9FAFC] rounded-[12px]"
                    onClick={handleCrop}
                >
                    <LucideCrop size="12px" color="#E55109" />
                    <small>Crop</small>
                </button>
            </Tooltip>
            <Tooltip title="Choose background image">
                <button
                    className="flex gap-1 text-center items-center text-[12px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                    onClick={handleBackgroundPickerVisible}
                >
                    <LucidePaperclip size="12px" color="#E55109" />
                    <small>Canvas Backround</small>
                </button>
            </Tooltip>
            <Tooltip title="Choose the canva size">
                <button
                    className="flex gap-1 text-center items-center text-[12px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                    onClick={handleCanvasSizePickerVisible}
                >
                    <LucideCrop size="12px" color="#E55109" />
                    <small>Change canva size</small>
                </button>
            </Tooltip>
            <Tooltip title="Rotate the selected image">
                <button
                    className="flex gap-1 text-center items-center text-[12px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                    onClick={handleRotate}
                >
                    <LucideRotateCw size="12px" color="#E55109" />
                    <small>Rotate</small>
                </button>
            </Tooltip>
            <Tooltip title="Add a new image">
                <button
                    className="flex gap-1 text-center items-center text-[12px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                    onClick={() => fileInputRef.current?.click()}
                >
                    <LucideUpload size="12px" color="#E55109" />
                    <small>Add Image</small>
                </button>
            </Tooltip>
            <input type="file" ref={fileInputRef} onChange={handleAddImage} style={{ display: 'none' }} />
            {/* <Tooltip title="Adjust brightness">
                <div className="flex items-center gap-2 bg-[#F9FAFC] p-1 px-2 rounded-[12px]">
                    <Sun size="12px" color="#E55109" />
                    <Slider min={-1} max={1} step={0.1} onChange={handleBrightnessChange} />
                </div>
            </Tooltip> */}
            {/* <Tooltip title="Adjust contrast">
                <div className="flex items-center gap-2 bg-[#F9FAFC] p-1 px-2 rounded-[12px]">
                    <LucideContrast size="12px" color="#E55109" />
                    <Slider min={-1} max={1} step={0.1} onChange={handleContrastChange} />
                </div>
            </Tooltip>
            <Tooltip title="Adjust saturation">
                <div className="flex items-center gap-2 bg-[#F9FAFC] p-1 px-2 rounded-[12px]">
                    <LightbulbIcon size="12px" color="#E55109" />
                    <Slider min={-1} max={1} step={0.1} onChange={handleSaturationChange} />
                </div>
            </Tooltip> */}
            {loading ? (
                <div className="flex items-center">
                    <Loader className="animate-spin text-gray-600 mr-2" size={12} />
                    Removing background...
                </div>
            ) : (
                <Tooltip title="Remove background">
                    <button
                        className="flex gap-1 text-center items-center text-[12px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                        onClick={handleRemoveBackground}
                    >
                        <ImageDownIcon size="12px" color="#E55109" />
                        <span>Remove BG</span>
                    </button>
                </Tooltip>
            )}
            <Tooltip title="Export your design">
                <button
                    className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#E55109] text-white rounded-[12px]"
                    onClick={handleExport}
                >
                    <LucideDownload size="14px" />
                    <small>Export</small>
                </button>
            </Tooltip>
        </div>
    );
};

export default EditorNav;
