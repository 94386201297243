import sun from '@assets/solar_sun.svg';
import two_arrows from '@assets/chat/two_arrows.svg';
import discover from '@assets/chat/discover.svg';
import support from '@assets/chat/support.svg';
import guide from '@assets/chat/guide.svg';

import './style.css';
import { GetDay } from './logic';

const Welcome = ({onclick}: {onclick: (e:string) => void}) => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const suggestions = [
        {
            icon: guide,
            color: '#d9efff',
            title: 'How-To Guide',
            description: 'Create a step-by-step guide on how to apply a topic in real life.',
            clickValue: 'how_to'
        },
        {
            icon: discover,
            color: '#fbdddd',
            title: 'Topic Exploration',
            description: 'Delve into various subjects with comprehensive texts',
            clickValue: 'explore'

        },
        {
            icon: two_arrows,
            color: '#fadcce',
            title: 'Lesson Hook',
            description: 'Plan a compelling hook that draws student in',
            clickValue: 'hooks'
        },
        
        {
            icon: support,
            color: '#d4f4e2',
            title: 'Exit Ticket',
            description: `Develop effective exit strategies to assess student learning`,
            clickValue: 'exit_ticket'
        },
    ];
    return (
        <div className="flex flex-col w-full max-md:absolute max-lg:fixed max-lg:h-screen max-md:inset-0 max-md:h-[70vh] z-50 max-md:w-screen max-md:px-5 h-full overflow-auto pt-3 text-center gap-2 max-md:mx-auto max-md:pt-4">
            <p className="m-0 text-brandOrange font-[600] text-xs flex items-center max-md:text-lg justify-center">
                <img src={sun} className="w-4" /> &nbsp; Hi {user?.firstName + '!' || 'user!'}👋. Good {GetDay()}.
            </p>
            <h1 className="text-2xl m-0 p-0 font-[600] max-md:text-lg">How can I help you today?</h1>
            <p className=" text-gray-400 font-normal m-0 p-0 text-center text-xs max-md:px-12">
                Get started by asking me anything! Feeling stuck?
                <br />Click on the prompts below for inspiration
            </p>

            <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-5 gap-y-5 w-fit max-w-[28rem] mx-auto mt-1">
                {suggestions.map(({ icon, color, title, description, clickValue }) => (
                    <Card onclick={() => onclick(clickValue) } color={color} key={title} icon={icon} title={title} description={description} />
                ))}
            </div>
        </div>
    );
};

const Card = ({
    icon,
    color,
    title,
    description,
    onclick,
}: {
    icon: string;
    color: string;
    title: string;
    description: string;
    onclick: () => void;
}) => {
    return (
        <section onClick={() => onclick()} className="flex flex-col gap-y-5 max-md:gap-4 transition-all w-full items-center px-3 py-2 select-none border border-gray-300 rounded-lg gap-1 text-left shadow-md hover:shadow-2xl">
            <div style={{ backgroundColor: `${color}` }} className="rounded-lg flex justify-center p-2 w-full">
                <img src={icon} alt="title" className="w-10 max-md:min-h-32" />
            </div>
            <div className="flex flex-col max-md:gap-2 items-start w-full">
                <p className="font-[600] max-md:text-[1rem] m-0">{title}</p>
                <p className="text-[0.7rem] max-md:text-sm font-normal  text-start">{description}</p>
            </div>
        </section>
    );
};
export default Welcome;
