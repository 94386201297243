import React from 'react';
import { sound_models } from '.';
import AudioCard from './audio-card';
import useFormStore from '@src/providers/formStore';

type Props = {};

function FirstScreen({}: Props) {
    const { nextStep } = useFormStore();
    function handleNext(e: React.MouseEvent) {
        e.preventDefault();
        nextStep();
    }

    return (
        <section>
            <h1 className="text-[#232323] text-[30px] mt-[3%]">Select a Voice Model</h1>
            <p className="text-gray-400 text-[14px] font-[400] mb-[4%]">
                To get started click on the play button to preview and select a voice model of your choice
            </p>
            <div className="flex flex-wrap gap-4 w-full md:w-[80%]">
                {sound_models.map((model) => (
                    <AudioCard key={model.id} model={model} />
                ))}
            </div>
            <button
                onClick={handleNext}
                className="bg-[#E55109] text-white text-center text-[10px] px-3 py-2 rounded-sm w-[7rem] mt-[4%]"
            >
                Next
            </button>
        </section>
    );
}

export default FirstScreen;
