import React from 'react';
import { Modal, Button } from 'antd';

interface Props {
    visible: boolean;
    onEdit: () => void;
    onCancel: () => void;
}

const EditImageModal: React.FC<Props> = ({ visible, onEdit, onCancel }) => {
    return (
        <Modal visible={visible} footer={null} onCancel={onCancel} className="rounded-lg w-96">
            <div className="p-4">
                <div className="relative h-40">
                    <iframe
                        title="animation"
                        src="https://lottie.host/embed/0a030f92-6902-4a4b-9456-d7af16ab1126/1FcUT9iEeI.json"
                        className="absolute inset-0 w-full h-full"
                        style={{ border: 'none' }}
                    />
                </div>
                <div className="mt-4">
                    <p className="text-center text-gray-700 mb-4">Do you want to edit the generated image?</p>
                    <div className="flex justify-center gap-6">
                        <button
                            // type="primary"
                            className="bg-[#E55109] text-[white] mt-6 border-none hover:bg-[#b6511e] text-sm py-2 px-3 disabled:bg-[#e4af95] rounded-md"
                            onClick={onEdit}
                        >
                            Start Editing
                        </button>
                        <button
                            className="bg-[] text-[#e55109] mt-6 border  text-sm py-2 px-3 disabled:bg-[#e4af95] rounded-md"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EditImageModal;
