import moment from 'moment';

export const GetDay = () => {
    const time = moment().format('a'); // Returns "am" or "pm"
    if (time === 'am' && moment().hour() < 12) {
        return 'Morning';
    } else if (time === 'pm' && moment().hour() < 18) {
        return 'Afternoon';
    } else {
        return 'Evening';
    }
};

