import React from 'react';
import TextBox from './text-box';
import { ChevronLeft } from 'lucide-react';
import useFormStore from '@src/providers/formStore';
// import AudioPlayer from './audio-player';
import useSpeechSynthesizerStore from '@src/providers/useSpeechSynthesizerStore';
import AudioPlayer from './audio-player';
import { Upload } from 'antd';
import UploadRecord from '../../transcriber/components/UploadRecord';
import UploadDocument from './upload-document';

type Props = {};

function ManualScreen({}: Props) {
    const { previousStep } = useFormStore();
    const { setTextContent, audioUrl, setAudioUrl } = useSpeechSynthesizerStore();
    function Back() {
        previousStep();
        setTextContent('');
        setAudioUrl(null);
    }

    return (
        <section>
            <button
                onClick={Back}
                className="text-center text-[12px] flex justify-center items-center mb-[2] pt-2 gap-3 "
            >
                <ChevronLeft className="bg-gray-200 rounded-full !font-[300] text-gray-700 !text-sm" />
                <span>Back</span>
            </button>
            <div className="mt-3 md:mt-[1%] p-2 md:p-4 ">
                <TextBox />
                {audioUrl && <AudioPlayer />}
                {/* <UploadDocument /> */}
            </div>
        </section>
    );
}

export default ManualScreen;
