import useFormStore from '@src/providers/formStore';
import useSpeechSynthesizerStore from '@src/providers/useSpeechSynthesizerStore';
import { ChevronLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import UploadDocument from './upload-document';
import TextBox from './text-box';
import AudioPlayer from './audio-player';

type Props = {};

function UploadOption({}: Props) {
    const { textContent, audioUrl } = useSpeechSynthesizerStore();
    const { previousStep } = useFormStore();
    const [showUpload, setShowUpload] = useState<boolean>(true);
    const [showTextBox, setShowTextBox] = useState<boolean>(false);
    const [showAudioPlayer, setShowAudioPlayer] = useState<boolean>(false);

    useEffect(() => {
        if (textContent) {
            setShowUpload(false);
            setShowTextBox(true);
        }
    }, [textContent]);

    useEffect(() => {
        if (audioUrl) {
            setShowAudioPlayer(true);
        }
    }, [audioUrl]);

    function Back() {
        previousStep();
    }

    return (
        <section>
            <button
                onClick={Back}
                className="text-center text-[12px] flex justify-center items-center mb-[2] pt-2 gap-3 "
            >
                <ChevronLeft className="bg-gray-200 rounded-full !font-[300] text-gray-700 !text-sm" />
                <span>Back</span>
            </button>
            <div className="mt-[1%] p-4">
                {showUpload && <UploadDocument />}
                {showTextBox && <TextBox />}
                {audioUrl && <AudioPlayer />}
            </div>
        </section>
    );
}

export default UploadOption;
