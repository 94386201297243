import React, { useState } from 'react';
import { ColorPicker, Slider } from 'antd';
import { fabric } from 'fabric';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';

const ShapeTool = () => {
    const { canvas, saveState } = useImageEditorStore();
    const [shapeColor, setShapeColor] = useState('#000000');
    const [lineThickness, setLineThickness] = useState(2);

    const handleAddShape = (shape: string) => {
        if (canvas) {
            let shapeObj;
            switch (shape) {
                case 'rectangle':
                    shapeObj = new fabric.Rect({
                        left: 100,
                        top: 100,
                        fill: shapeColor,
                        width: 100,
                        height: 100,
                    });
                    break;
                case 'circle':
                    shapeObj = new fabric.Circle({
                        left: 100,
                        top: 100,
                        fill: shapeColor,
                        radius: 50,
                    });
                    break;
                case 'triangle':
                    shapeObj = new fabric.Triangle({
                        left: 100,
                        top: 100,
                        fill: shapeColor,
                        width: 100,
                        height: 100,
                    });
                    break;
                case 'line':
                    shapeObj = new fabric.Line([50, 100, 200, 200], {
                        stroke: shapeColor,
                        strokeWidth: lineThickness,
                    });
                    break;
                case 'freeDraw':
                    canvas.isDrawingMode = true;
                    canvas.freeDrawingBrush.color = shapeColor;
                    canvas.freeDrawingBrush.width = lineThickness;
                    break;
                default:
                    break;
            }
            if (shape !== 'freeDraw' && shapeObj) {
                canvas.add(shapeObj);
                canvas.renderAll();
                saveState();
            }
        }
    };

    const handleBorderRadiusChange = (value: number) => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'rect') {
                (activeObject as fabric.Rect).set('rx', value);
                (activeObject as fabric.Rect).set('ry', value);
                canvas.renderAll();
                saveState();
            }
        }
    };

    const handleColorChange = (color: any, hex: string) => {
        setShapeColor(hex);
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                if (activeObject.type === 'line') {
                    (activeObject as fabric.Line).set('stroke', hex);
                } else {
                    activeObject.set('fill', hex);
                }
                canvas.renderAll();
                saveState();
            }
        }
    };

    const handleLineThicknessChange = (value: number) => {
        setLineThickness(value);
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'line') {
                (activeObject as fabric.Line).set('strokeWidth', value);
                canvas.renderAll();
                saveState();
            }
        }
    };

    return (
        <div className="mb-20 p-4 bg-white rounded-lg shadow-md">
            <select onChange={(e) => handleAddShape(e.target.value)} className="mb-2 p-2 border rounded-md w-full">
                <option value="">Choose shape</option>
                <option value="rectangle">Rectangle</option>
                <option value="circle">Circle</option>
                <option value="triangle">Triangle</option>
                <option value="line">Line</option>
                <option value="freeDraw">Free Draw</option>
            </select>
            <div className="mb-2">
                <label className="block mb-1">Color:</label>
                {/* <input
                    type="color"
                    value={shapeColor}
                    onChange={handleColorChange}
                    className="w-full p-1 border rounded-md"
                /> */}
                <ColorPicker onChange={handleColorChange} />
            </div>
            <div className="mb-2">
                <label className="block mb-1">Line Thickness:</label>
                <Slider
                    min={1}
                    max={10}
                    step={1}
                    value={lineThickness}
                    onChange={handleLineThicknessChange}
                    className="w-full"
                />
            </div>
            <div className="mb-2">
                <label className="block mb-1">Border Radius:</label>
                <Slider min={0} max={100} step={1} onChange={handleBorderRadiusChange} className="w-full" />
            </div>
        </div>
    );
};

export default ShapeTool;
