import DashboardFrame from '@src/components/DashboardFrame';
import React, { useEffect, useState } from 'react';
import { ChatType, getHistory, handleDeleteHistory } from '../../endpoints';
import { toast } from 'react-toastify';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';
import TextTool from './components/text-box';
import RightSidebar from './components/rightbar';
import EditorNav from './components/editor-nav';
import Canvas from './components/canvas';

type Props = {};

function ImageEditor({}: Props) {
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);

    const pageHistory = 'slide';

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    const getPageHistory = () => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                const filtered = data?.map((c: any) => {
                    return [...c.conversation, { id: c._id, date: c.createdAt }];
                });
                setHistories(filtered);
            }
        });
    };
    useEffect(() => {
        getPageHistory();
    }, []);
    const setClickedHistory = (id: string) => {
        const filterHistory = histories
            .filter((h: any) => {
                const { id: history_id } = h[h.length - 1];
                return String(history_id) === String(id);
            })
            .flat()
            .filter((h: any) => !h?.id);
        setChatList(filterHistory);
    };
    const handleDeleteContentHistory = async (id: string) => {
        await handleDeleteHistory(id, pageHistory);
        getPageHistory();
        toast.success('Chat deleted successfully');
    };
    const setImageUrl = useImageEditorStore((state) => state.setImageUrl);

    // useEffect(() => {
    //     // Assuming you fetch the image URL and store it in Zustand
    //     setImageUrl(imageUrl);
    // }, [setImageUrl]);

    return (
        <div>
            {' '}
            <DashboardFrame
                canNavigateHome
                showSidebar={!isMobile}
                showHistory={!isMobile}
                showTop={!isMobile}
                history={histories}
                showPagePath={false}
                selectedHistory={(v: string) => {
                    setClickedHistory(v);
                }}
                onDeleteHistory={(id: string) => {
                    handleDeleteContentHistory(id);
                }}
                showHistoryOption={false}
            >
                <div className=" flex flex-col h-screen">
                    <EditorNav />
                    <div className="flex flex-1">
                        <div className="canvas-container flex-1 flex flex-col items-center justify-center p-4">
                            <Canvas />
                            {/* <TextTool /> */}
                        </div>
                        <RightSidebar />
                    </div>
                </div>
            </DashboardFrame>
        </div>
    );
}

export default ImageEditor;
