import syllabusImg from '@icons/syllabus.svg';
import syllabusImg2 from '@icons/template.svg';
import lessonPlanner from '@icons/lesson_planner.svg';
import lessonPlannerImg2 from '@icons/report-medical.svg';
import activityGenerator from '@icons/activity_generator.svg';
import activityGeneratorImg2 from '@icons/dna-2.svg';
import chevronRight from '@icons/chevron-right.svg';
import { activityGeneratorPrompt, curriculumBasePrompt, newCurriculumTypes } from '../interfaces';
import { useState } from 'react';
import BackButton from '@src/components/BackButton';
import SyllabusScreen from './syllabus_screen';
import LessonPlanner from './lesson_planner';
import ActivityGenerator from './activity_generator';

const NewCurriculum = ({ onGenerate, setTopic, setPageName, setPageClickFn }: curriculumBasePrompt) => {
    // comments for page
    // 0 - homepage
    // 1 - syllabus generator
    // 2 - lesson planner
    // 3 - learning activity generator

    const [currentPage, setCurrentPage] = useState(0);
    const curriculumPrompts: Array<newCurriculumTypes> = [
        {
            img: syllabusImg,
            head: 'Syllabus Generator',
            text: 'Streamline the creation of comprehensive and professional course syllabi',
            onTap: () => {
                setCurrentPage(1);
                setPageName("Syllabus Generator")
                setPageClickFn(() => {setCurrentPage(1)})
            },
            color: 'var(--gmind-green)'
        },
        {
            img: lessonPlanner,
            head: 'Lesson Planner',
            text: 'Versatile tool that aids educators in designing detailed and effective lesson plans',
            onTap: () => {
                setCurrentPage(2);
                setPageName("Lesson Planner")
                setPageClickFn(() => {setCurrentPage(2)})
            },
            color: 'var(--gmind-orange80)'
        },
        {
            img: activityGenerator,
            head: 'Learning Activity Generator',
            text: 'Dynamic tool designed to help educators create engaging and interactive learning activities.',
            onTap: () => {
                setCurrentPage(3);
                setPageName("Activity Generator");
                setPageClickFn(() => {setCurrentPage(3)})
            },
            color: '#EA545580'
        },
    ];

    return (
        <div className={`w-full flex flex-col ${currentPage > 0? 'pt-5 pb-20' : 'py-10'} px-[2rem] gap-y-3`}>
            {currentPage > 0 && (
                <BackButton
                    onclick={() => {
                        setCurrentPage(0);
                        setPageName("");
                        console.log('hdjdd');
                    }}
                />
            )}
            {currentPage == 1 && <SyllabusScreen onGenerate={onGenerate} onSetTopic={setTopic} className="" />}
            {currentPage == 2 && <LessonPlanner onGenerate={onGenerate} onSetTopic={setTopic} />}
            {currentPage == 3 && <ActivityGenerator onGenerate={onGenerate} onSetTopic={setTopic} />}
            {currentPage == 0 && (
                <div className="w-full flex flex-col gap-y-2 items-center justify-center">
                    <h2 className="self-start md:self-center font-medium text-[2rem] md:text-[2.75rem] text-[var(--gmind-black)]">Curriculum Planner</h2>
                    <p className="self-start md:self-center text-[1.125rem] md:text-[0.875rem] text-[var(--gmind-white60)] text-normal">Design advanced and standard-aligned curriculum using G-Mind</p>

                    <div className="self-start md:self-center mt-5 items-center justify-center w-full md:w-[80%] flex flex-col md:flex-row flex-wrap gap-x-5 gap-y-5 md:gap-y-5">
                        {
                            curriculumPrompts.map((item, index) => (
                                <div key={index} onClick={() => item.onTap()} className={`${ ( index == ( curriculumPrompts.length - 1) ) ? 'w-full md:w-[65%] lg:w-[55%]' : 'w-full md:w-[65%] lg:w-[45%]'} rounded-[0.75rem] px-[0.6rem] py-5 h-[7rem] flex items-center cursor-pointer shadow-md hover:shadow-2xl`}
                                    style={{
                                        border: "1px solid var(--gmind-border-black8)"
                                    }}
                                >
                                    <div className="flex gap-x-2">
                                        <img className="w-[3rem] md:w-auto h-[3rem] md:h-auto" src={item.img} alt={item.head} />
                                        <div className="flex flex-col gap-y-1">
                                            <h5 className="font-medium text-[0.8rem] md:text-[1rem] text-[var(--gmind-light-black)]">{item.head}</h5>
                                            <p className="font-normal text-[0.625rem] md:text-[0.75rem] text-[var(--gmind-white60)]">{item.text}</p>
                                        </div>
                                        <img src={chevronRight} alt="syllaus" />
                                    </div>
                                </div>
                             ))
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewCurriculum;
