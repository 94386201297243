import React from 'react';
import { MySmallLogoIcon } from './Svg';

const LoadingComponent = ({
    className = '',
    width,
    isMobile,
}: {
    isMobile: boolean;
    width: string;
    className?: string;
}) => {
    return (
        <div
            className={className}
            style={{
                width: width ? width : isMobile ? '100%' : 'calc(100% - 282px)',
                height: '109px',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '38px',
            }}
        >
            <MySmallLogoIcon />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between w-full h-fit',
                }}
                className=""
            >
                <p className="mt-2 text-gray-500 w-full font-normal">Your generated text would be here.</p>
            </div>
        </div>
    );
};

export default LoadingComponent;
