import React, { useState, useEffect } from 'react';
import { Button, Input, Select, Modal, Slider, ColorPicker } from 'antd';
import { fabric } from 'fabric';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';
import { Color } from 'antd/es/color-picker';
import { Text } from 'lucide-react';

const { Option } = Select;

const fontOptions = [
    'Arial',
    'Courier New',
    'Georgia',
    'Times New Roman',
    'Verdana',
    'Comic Sans MS',
    'Impact',
    'Lucida Sans Unicode',
    'Tahoma',
    'Trebuchet MS',
    'Helvetica',
    'Roboto',
    'Open Sans',
    'Lato',
    'Oswald',
    'Raleway',
    'PT Sans',
    'Noto Sans',
    'Merriweather',
    'Ubuntu',
    'Playfair Display',
    'Montserrat',
    'Poppins',
    'Nunito',
    'Raleway',
    'Quicksand',
    'Fira Sans',
    'Rubik',
    'Oxygen',
    'Muli',
    'Droid Sans',
    'Arimo',
    'Signika',
    'Cabin',
];

const TextTool = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [text, setText] = useState('');
    const [fontWeight, setFontWeight] = useState('normal');
    const [fontStyle, setFontStyle] = useState<'' | 'normal' | 'italic' | 'oblique' | undefined>('normal');
    const [textDecoration, setTextDecoration] = useState('');
    const [textColor, setTextColor] = useState('#000000');
    const [fontSize, setFontSize] = useState(20);
    const [fontFamily, setFontFamily] = useState('Arial');
    const [isEditing, setIsEditing] = useState(false);
    const [activeObject, setActiveObject] = useState<fabric.Text | null>(null);

    const { canvas, saveState } = useImageEditorStore();

    const resetFields = () => {
        setText('');
        setFontWeight('normal');
        setFontStyle('normal');
        setTextDecoration('');
        setTextColor('#000000');
        setFontSize(20);
        setFontFamily('Arial');
    };

    const handleAddText = () => {
        if (canvas && text) {
            if (isEditing && activeObject) {
                activeObject.set({
                    text,
                    fontWeight,
                    fontStyle,
                    // textDecoration,
                    fill: textColor,
                    fontSize,
                    fontFamily,
                });
                canvas.renderAll();
                saveState();
                setIsEditing(false);
                setActiveObject(null);
            } else {
                const textObj = new fabric.Text(text, {
                    left: 100,
                    top: 100,
                    fill: textColor,
                    fontSize,
                    fontWeight,
                    fontStyle,
                    // textDecoration,
                    fontFamily,
                });
                canvas.add(textObj);
                canvas.renderAll();
                saveState();
            }
            // resetFields();
        }
        setIsModalVisible(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsEditing(false);
        setActiveObject(null);
        resetFields();
    };

    const handleObjectSelected = (e: fabric.IEvent) => {
        const selectedObject = e.target;
        if (selectedObject && selectedObject.type === 'text') {
            const textObj = selectedObject as fabric.Text;
            setText(textObj.text || '');
            setFontWeight(textObj.fontWeight as string);
            setFontStyle(textObj.fontStyle as '' | 'normal' | 'italic' | 'oblique' | undefined);
            // setTextDecoration(textObj.textDecoration || '');
            setTextColor(textObj.fill as string);
            setFontSize(textObj.fontSize || 20);
            setFontFamily(textObj.fontFamily || 'Arial');
            setIsEditing(true);
            setActiveObject(textObj);
            setIsModalVisible(true);
        }
    };

    useEffect(() => {
        if (canvas) {
            canvas.on('object:selected', handleObjectSelected);
            return () => {
                canvas.off('object:selected', handleObjectSelected);
            };
        }
    }, [canvas]);
    const handleColorChange = (color: Color, hex: string) => {
        setTextColor(hex);
    };

    return (
        <div className="mb-20 p-4 bg-white rounded-lg shadow-md">
            <button
                onClick={showModal}
                className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
            >
                <Text size="14px" color="#E55109" />
                <small> {isEditing ? 'Edit Text' : 'Add Text'}</small>
            </button>
            <Modal
                title={isEditing ? 'Edit Text' : 'Add Text'}
                visible={isModalVisible}
                onOk={handleAddText}
                onCancel={handleCancel}
            >
                <Input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter text"
                    className="mb-2"
                />
                <Select
                    defaultValue="normal"
                    value={fontWeight}
                    onChange={(value) => setFontWeight(value)}
                    className="mb-2 w-full"
                >
                    <Option value="normal">Normal</Option>
                    <Option value="bold">Bold</Option>
                </Select>
                <Select
                    defaultValue="normal"
                    value={fontStyle}
                    onChange={(value: '' | 'normal' | 'italic' | 'oblique' | undefined) => setFontStyle(value)}
                    className="mb-2 w-full"
                >
                    <Option value="normal">Normal</Option>
                    <Option value="italic">Italic</Option>
                </Select>
                <Select
                    defaultValue=""
                    value={textDecoration}
                    onChange={(value) => setTextDecoration(value)}
                    className="mb-2 w-full"
                >
                    <Option value="">None</Option>
                    <Option value="underline">Underline</Option>
                    <Option value="line-through">Line-through</Option>
                </Select>
                <Select
                    showSearch
                    placeholder="Select a font"
                    optionFilterProp="children"
                    value={fontFamily}
                    onChange={(value) => setFontFamily(value)}
                    className="mb-2 w-full"
                    filterOption={(input, option) => {
                        const children = option?.children;
                        return typeof children === 'string' && children.toLowerCase().includes(input.toLowerCase());
                    }}
                >
                    {fontOptions.map((font) => (
                        <Option key={font} value={font}>
                            {font}
                        </Option>
                    ))}
                </Select>
                <div className="mb-2">
                    <label className="block mb-1">Text Color:</label>
                    {/* <input
                        type="color"
                        value={textColor}
                        onChange={(e) => setTextColor(e.target.value)}
                        className="w-full p-1 border rounded-md"
                    /> */}
                    <ColorPicker onChange={handleColorChange} />
                </div>
                <div className="mb-2">
                    <label className="block mb-1">Font Size:</label>
                    <Slider
                        min={8}
                        max={72}
                        value={fontSize}
                        onChange={(value) => setFontSize(value)}
                        className="w-full"
                    />
                </div>
            </Modal>
        </div>
    );
};

export default TextTool;
