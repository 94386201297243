export const BASE_URL = import.meta.env.VITE_NEXT_PUBLIC_GMIND_BASEURL || '';
export const socketUrl = 'https://api-v2.gmind.ai/v1';
const dictionary: any = {
    chat: 'Chat',
    course_content: 'Course Content',
    article: 'Article Writer',
    curriculum: 'Curriculum Planner',
    assessment: 'Create Assessment',
    transcriber: 'Transcriber',
    research: 'Make Research',
    generate_content: 'Generate Content',
    slide: 'Slider Maker',
};
const reverseDictionary: any = {
    'Chat': 'chat',
    'Course Content': 'course_content',
    'Article Writer': 'article',
    'Curriculum Planner': 'curriculum',
    'Create Assessment': 'assessment',
    'Make Research': 'research',
    'Generate Content': 'generate_content',
    'Slide Maker': 'slide',
    'Transcriber': 'transcriber',
};


export {
    dictionary,
    reverseDictionary,
}