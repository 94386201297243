import bgImage from '@assets/testimonial_img.svg';
import unFilledStar from '@icons/unfilled_star.svg';
import filledStar from '@icons/filled_star.svg';
import quotes from '@assets/quotes.svg';
import bolade from '@assets/bolade.png';
import teni from '@assets/teni.png';
import richard from '@assets/richard.png';
import comment from '@icons/comment.svg';
import { useEffect, useState } from 'react';

const starLength = 5;

interface TestimoniesProps {
    name: string;
    rating: number;
    image: string;
    feedback: string;
    role: string;
}

const testimoniesData: TestimoniesProps[] = [
    {
        name: 'Jasmine George',
        rating: 5,
        image: bolade,
        feedback:
            "Using G-Mind AI has really revolutionized the way I design my courses and assessments. It's a game-changer for me in my curriculum development, making it so much easier and efficient. This tool has saved me hours of work and helped me deliver more engaging content to my students.",
        role: 'Teacher',
    },
    {
        name: 'Teniola Friday',
        rating: 5,
        image: teni,
        feedback:
            "G-Mind AI has transformed the way I approach my studies. I now have a tool that helps me navigate complex course content, conduct in-depth research, and understand my assessment performance. It's like having a personal tutor at my fingertips, and it's made learning more engaging and efficient.",
        role: 'Student',
    },
    {
        name: 'Richard Smith',
        rating: 5,
        image: richard,
        feedback:
            "G-Mind AI has become my go-to platform for research and analysis. It streamlines the entire research process, helping me gather insights quickly and effectively. It's an indispensable tool for educators looking to stay on top of the latest educational trends.",
        role: 'Teacher',
    },
];

const Testimonies = () => {
    const [currentComment, setCurrentComment] = useState(0);

    const changeCommentEveryTenSeconds = () => {
        setInterval(() => {
            setCurrentComment(prevComment =>
                prevComment >= testimoniesData.length - 1 ? 0 : prevComment + 1
            );
            console.log(currentComment);
        }, 20000);
    };

    useEffect(() => {
        const intervalId = changeCommentEveryTenSeconds();
        // return () => clearInterval(); // Cleanup on unmount
    }, []);

    return (
        <div className="w-full mt-0 mb-5 flex flex-col gap-y-3">
            <h3 style={{ alignSelf: 'center' }} className="hidden md:flex font-medium text-[1.25rem] md:text-[2rem] text-[var(--gmind-orange)] md:text-[var(--gmind-black)]">
                Testimonies
            </h3>
            <div className="flex flex-col md:flex-row items-center py-10 md:py-20 gap-x-5">
                <div className="w-full md:w-[40%] flex flex-col gap-y-5">
                    <h2 className="self-center font-bold text-[1.25rem] text-[var(--gmind-orange)]">Testimonials</h2>
                    <p className="self-center text-center font-medium text-[1.5rem] md:text-[2rem] text-[var(--gmind-testimonial)]">
                        See what our users are saying about G-Mind
                    </p>
                </div>

                <div className="flex flex-col gap-y-3 md:hidden mt-10">
                    <img src={quotes} className='w-[2.5rem] h-[1.625rem]' alt="quotes" />
                    <div className="self-center w-[19.563rem] h-auto relative">
                        <div className="w-full h-full flex flex-col gap-4 shadow p-5">
                            <p
                                className="font-normal text-[0.9rem] text-[var(--gmind-rating)]"
                                style={{
                                    fontStyle: 'italic',
                                }}
                            >
                                {testimoniesData[currentComment].feedback}
                            </p>
                            <div className="flex gap-x-3">
                                <div
                                    className="w-[3rem] h-[3rem] rounded-full"
                                    style={{
                                        backgroundImage: `url(${testimoniesData[currentComment].image})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',
                                    }}
                                ></div>
                                <div className="flex flex-col gap-y-2">
                                    <h2 className="font-semibold  text-[var(--gmind-testimonial)]">
                                        {testimoniesData[currentComment].name}
                                    </h2>
                                    <p className="text-[var(--gmind-rating)] font-normal text-[0.75rem]">
                                        {testimoniesData[currentComment].role}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <img src={comment} alt="comment" className='absolute bottom-[-0.2rem] right-[1.5rem]' /> */}
                    </div>
                </div>

                <div className="hidden md:flex w-[60%] relative">
                    <div
                        className="w-[70%] h-[20rem] rounded-[0.9rem] flex justify-center gap-x-1 pb-5"
                        style={{
                            backgroundImage: `url(${bgImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            alignItems: 'end',
                        }}
                    >
                        {testimoniesData.map((comment, index) => (
                            <div
                                onClick={() => setCurrentComment(index)}
                                className={`${index == currentComment ? 'bg-[var(--gmind-orange)] w-[1.6rem]' : 'bg-white w-[3rem]'} h-[0.3rem]`}
                            ></div>
                        ))}
                        {/* <div className="bg-[var(--gmind-orange)]  h-[0.3rem]"></div>
                        <div className="bg-white w-[3rem] h-[0.3rem]"></div> */}
                    </div>

                    <div className="speech-bubble absolute w-[21.313rem] md:h-[290px] top-0 right-0 bottom-[6%] shadow-md flex flex-col gap-y-3 p-5">
                        <div className="flex gap-x-2">
                            {Array(starLength)
                                .fill(0)
                                .map((item, index) => (
                                    <img
                                        key={index}
                                        src={
                                            index <= testimoniesData[currentComment].rating ? filledStar : unFilledStar
                                        }
                                        alt="star"
                                    />
                                ))}
                        </div>

                        <p
                            className="font-normal text-[0.9rem] text-[var(--gmind-rating)]"
                            style={{
                                fontStyle: 'italic',
                            }}
                        >
                            {testimoniesData[currentComment].feedback}
                        </p>

                        <div className="flex gap-x-3">
                            <div
                                className="w-[3rem] h-[3rem] rounded-full"
                                style={{
                                    backgroundImage: `url(${testimoniesData[currentComment].image})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100% 100%',
                                }}
                            ></div>
                            <div className="flex flex-col gap-y-2">
                                <h2 className="font-semibold  text-[var(--gmind-testimonial)]">
                                    {testimoniesData[currentComment].name}
                                </h2>
                                <p className="text-[var(--gmind-rating)] font-normal text-[0.75rem]">
                                    {testimoniesData[currentComment].role}
                                </p>
                            </div>

                            <img src={quotes} alt="quotes" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonies;
