import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Root from './pages/dashboard/pages/root';
import Chat from './pages/dashboard/pages/chat';
import NotFoundPage from './pages/404';
import Curriculum from './pages/dashboard/pages/curriculum/index.tsx';
import Slide from './pages/dashboard/pages/slide/index.tsx';
import Transcriber from './pages/dashboard/pages/transcriber';
import EditorPage from './pages/dashboard/pages/essay/editor/index.tsx';
import Essay from './pages/dashboard/pages/essay';
import Generate from './pages/dashboard/pages/generate/index.tsx';
import Research from './pages/dashboard/pages/research/index.tsx';
import Image from './pages/dashboard/image/index.tsx';
import Assessment from './pages/dashboard/pages/assessment_2/index.tsx';
import NewEssayPage from './pages/dashboard/pages/essay/new_index.tsx';
import ComingSoonPage from './pages/dashboard/pages/coming_soon/index.tsx';
import Speech from './pages/dashboard/pages/speech/index.tsx';
import PricingHome from './pages/acquisition/pricing/home/page.tsx';
import DashboardPricing from './pages/dashboard/pages/dashboard_pricing/index.tsx';
import Layout from './pages/acquisition/layout/Layout.tsx';
import { Cookies } from './pages/acquisition/policies/cookies/index.tsx';
import { TermsOfService } from './pages/acquisition/policies/tos/index.tsx';
import { Privacy } from './pages/acquisition/policies/privacy/index.tsx';
import ImageEditor from './pages/dashboard/pages/image_editor/index.tsx';
import SuccessPage from './pages/dashboard/pages/payment_successful/index.tsx';
import ProfileScreen from './pages/dashboard/pages/profile/index.tsx';

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path={'/dashboard'} element={<Root />} errorElement={<NotFoundPage />}>
                <Route path="chat" element={<Chat />} />
                <Route path="transcriber" element={<Transcriber />} />
                <Route path="generate_content" element={<Generate />} />
                <Route path="assessment" element={<Assessment />} />
                <Route path="article" element={<NewEssayPage />} />
                <Route path="article/editor" element={<EditorPage />} />
                <Route path="curriculum" element={<Curriculum />} />
                <Route path="slide" element={<Slide />} />
                <Route path="speech_synthesizer" element={<Speech />} />
                <Route path="video_mode" element={<ComingSoonPage />} />
                <Route path="image_maker" element={<Image />} />
                <Route path="image_maker/edit" element={<ImageEditor />} />
                <Route path="research" element={<Research />} />
                <Route path="pricing" element={<DashboardPricing />} />
                <Route path="payment-success" element={<SuccessPage />} />
                <Route path="profile" element={<ProfileScreen />} />
            </Route>
            ,
            <Route path="" element={<Layout isLoggedIn={true} />} errorElement={<NotFoundPage />}>
                <Route path="" element={<Layout isLoggedIn={true} />} errorElement={<NotFoundPage />} />
                <Route path="features" element={<Layout isLoggedIn={true} />} errorElement={<NotFoundPage />} />
                <Route path="cookies" element={<Cookies />} errorElement={<NotFoundPage />} />
                <Route path="terms-of-service" element={<TermsOfService />} errorElement={<NotFoundPage />} />
                <Route path="privacy-policy" element={<Privacy />} errorElement={<NotFoundPage />} />
            </Route>
        </>,
    ),
);

export default router;
