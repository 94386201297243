import { useState } from "react";
import { InputTypeProps, PageProps } from "../interface";
import { curriculumOptions } from "../../curriculum/constants";
import { Form, Input } from "antd";
import { Select } from "@src/pages/dashboard/components/Select";
import BackButton from "@src/components/BackButton";
import { toast } from "react-toastify";
import HomeAssessment from "../sub_pages/home";
import QuizAssessment from "../sub_pages/quiz";

const AssessmentHome = ({onSubmit}: PageProps ) => {
    const [ currentPage, setCurrentPage ] = useState<number>(0);
    const [ basePrompt, setBasePrompt ] = useState<string>('');

    const onGenerate = ( prompt: string ) => {
        let combinedPrompt = `${basePrompt}${prompt}`;
        let promptGenerated = `Create an assessment with these details:\n${combinedPrompt}\nKindly Ensure all instructions are followed to the later.`;

        console.log(promptGenerated);
        onSubmit(promptGenerated, combinedPrompt);
    }

    return (
        <section className="w-full py-5 px-5 md:px-[2rem] gap-y-3">
            { (
                <BackButton
                    onclick={ currentPage > 0 ? () => {
                        setCurrentPage(0);
                        console.log('hdjdd');
                    }: undefined }
                />
            )}
            {
                currentPage == 0 && <HomeAssessment nextPage={1} onSubmit={(prompt, nextPage) => { 
                    setBasePrompt(prompt); 
                    if ( nextPage ){
                        setCurrentPage(nextPage);
                    }
                }} />
            }
            { currentPage == 1 && <QuizAssessment onSubmit={(prompt) => onGenerate(prompt) } /> }
        </section>
    );
}


export default AssessmentHome;