import React, { useState } from 'react';
import { Modal, Button, InputNumber, Form, message } from 'antd';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';
import { fabric } from 'fabric';

interface CanvasSizePickerProps {
    visible: boolean;
    onCancel: () => void;
}

const CanvasSizePicker: React.FC<CanvasSizePickerProps> = ({ visible, onCancel }) => {
    const { canvas } = useImageEditorStore();
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                if (canvas && values.width && values.height) {
                    const newWidth = Number(values.width);
                    const newHeight = Number(values.height);
                    canvas.setDimensions({ width: newWidth, height: newHeight });
                    onCancel();
                }
            })
            .catch(() => {
                message.error('Please enter valid dimensions.');
            });
    };

    return (
        <Modal title="Choose Canvas Size" visible={visible} onOk={handleOk} onCancel={onCancel}>
            <Form form={form} layout="vertical">
                <Form.Item label="Width" name="width" rules={[{ required: true, message: 'Width is required' }]}>
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item label="Height" name="height" rules={[{ required: true, message: 'Height is required' }]}>
                    <InputNumber min={1} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CanvasSizePicker;
