/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import Acquisition from './acquisitionRouter';
import Dashboard from './dashboardRouter';
import './App.scss';

import { getUrlPath } from './utils/app_functions';
import Loading from './components/Loading';

export default function App() {
    const [mounted, setPageMounting] = useState(false);
    const [page, setPage] = useState('');
    useEffect(() => {
        // check if user token exist
        const user = localStorage.getItem('token');
        const userData = localStorage.getItem('user');
        const pagePathDetails = getUrlPath();
        if (user && userData && pagePathDetails.pagename != 'verify' && pagePathDetails.pagename != 'verify_mail') {
            // get url path
            const userDetails = JSON.parse(userData);

            if (
                pagePathDetails.folder !== 'dashboard' &&
                ( pagePathDetails.pagename === 'login' || pagePathDetails.pagename === 'signup')
            ) {
                // if ( userDetails.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';   
                // }
                window.location.href = '/dashboard/chat';   
            }
            setTimeout(() => {
                setPage('dashboard');
            }, 1000);
        } else {
            const { pathname } = window.location;
            const split = pathname.split('/');
            

            if (split[1] && split[1] === "dashboard") {
                const { pathname, origin, href } = window.location;
                localStorage.setItem("callback", href)
                window.location.href = `/login?callback=${href}`;
            }
            setPage('acquisition');
        }
        setPageMounting(true);
    }, []);

    return mounted ? (
        page == 'acquisition' ? (
            <RouterProvider router={Acquisition} />
        ) : page == 'dashboard' ? (
            <RouterProvider router={Dashboard} />
        ) : (
            // <p className="w-full flex items-center justify-center text-4xl font-semibold h-screen border">Loading...</p>
            <Loading />
        )
    ) : null;
}
