import GmindPurple from "@icons/gmind_purlpe.svg";

interface AdvancedFeaturesInterface {
    head: string,
    content: string
    bgColor: string;
}

const AdvancedFeatures = () => {
    const purple: string = `rgba(227, 39, 243, 0.12)`;
    const yellow: string = `rgba(255, 198, 10, 0.12)`;
    const green: string = `rgba(44, 243, 39, 0.12)`;

    const AdvancedFeaturesData: Array<AdvancedFeaturesInterface> = [
        {
            head: "Humanizer",
            content: "Experience the Art of Human-like Conversation with GMind",
            bgColor: purple,
        },
        {
            head: "Advanced Text Editor",
            content: "Enjoy a robust tool for text creation and editing",
            bgColor: yellow,
        },
        {
            head: "High Quality Image Editor",
            content: "Enjoy a robust tool for text creation and editing",
            bgColor: green,
        },
        {
            head: "Prompt assist",
            content: "Achieve success easily: no prompt engineering skills needed.",
            bgColor: green,
        },
        {
            head: "Collaborate and Thrive",
            content: "Invite others to achieve more, together we excel",
            bgColor: yellow,
        },
        {
            head: "Multilingual Translator",
            content: "Breaking language barriers with content in native languages",
            bgColor: purple,
        },
    ]

    return (
        <div className="w-full flex flex-col gap-y-5">
            {/* header section */}
            <div className="w-[55%] flex self-end mr-20">
                <div className="w-full flex items-center justify-between">
                    <p className="font-[Poppins] font-medium text-[2rem] text-[var(--gmind-black)] mt-10">Advanced Features</p>
                    <img className="hidden md:flex h-[7rem] w-[7rem]" src={GmindPurple} alt="logo" />
                </div>
            </div>

            <div className="px-[5%] w-[90%] self-center flex flex-col md:flex-row gap-x-5 gap-y-5 flex-wrap justify-center">
                {
                    AdvancedFeaturesData.map((item, index) => (
                            <div key={index} className={`flex flex-col gap-y-3 w-full md:w-[30%] p-[1.5rem] rounded-[0.75rem]`}
                                style={
                                    {
                                        backgroundColor: `${item.bgColor}`,
                                        border: `1px solid var(--gmind-border-black10)`,
                                    }
                                }   
                                >
                                <h1 className="font-medium text-[1.5rem] text-black font-[Inter]">{item.head}</h1>
                                <p className="font-normal text-[1rem] text-[var(--gmind-accordion-header)]">{item.content}</p>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    );
}


export default AdvancedFeatures;