import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import LandingPage from './pages/acquisition/root';
import NotFoundPage from './pages/404';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './pages/acquisition/login';
import SignUpPage from './pages/acquisition/signup';
import PlaygroundResponse from './pages/acquisition/response';
import Layout from './pages/acquisition/layout/Layout';
import { Cookies } from './pages/acquisition/policies/cookies';
import { TermsOfService } from './pages/acquisition/policies/tos';
import { Privacy } from './pages/acquisition/policies/privacy';
import PricingHome from './pages/acquisition/pricing/home/page';
import { useRef } from 'react';
import EmailVerification from './pages/acquisition/verify_mail';
import OtpScreen from './pages/acquisition/verify_mail/otp';
import FeaturesScreen from './pages/acquisition/features';

const router = createBrowserRouter(
        
    createRoutesFromElements(
        <Route element={<Layout />}>
            <Route path="/" element={<Layout />} errorElement={<NotFoundPage />} />
            <Route path="login" element={<LoginPage />} errorElement={<NotFoundPage />} />
            <Route path="signup" element={<SignUpPage />} errorElement={<NotFoundPage />} />
            <Route path="cookies" element={<Cookies />} errorElement={<NotFoundPage />} />
            <Route path="terms-of-service" element={<TermsOfService />} errorElement={<NotFoundPage />} />
            <Route path="privacy-policy" element={<Privacy />} errorElement={<NotFoundPage />} />
            <Route path="playground" element={<PlaygroundResponse />} errorElement={<NotFoundPage />} />
            <Route path="pricing" element={<PricingHome />} errorElement={<NotFoundPage />} />
            <Route path="success" element={<PricingHome />} errorElement={<NotFoundPage />} />
            <Route path="cancel" element={<PricingHome />} errorElement={<NotFoundPage />} />
            <Route path="verify_mail" element={<EmailVerification />} errorElement={<NotFoundPage />} />
            <Route path="verify" element={<OtpScreen />} errorElement={<NotFoundPage />} />
            <Route path="features" element={<Layout />} errorElement={<NotFoundPage />} />
        </Route>,
    ),
);

export default router;
