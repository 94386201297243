import {Footer} from '../../components/footer/footer';
import { Card, ContentElement } from '../card';
import { Hero } from '../hero';

import '../style.css';

export const Privacy = () => {
    const Contents = [
        {
            title: 'Introduction',
            content: (
                <div className="flex flex-col gap-2">
                    <span>
                        G-Mind AI is committed to protecting your privacy and safeguarding your personal information in
                        accordance with global privacy standards. We strive to provide a secure and reliable educational
                        environment where you can use our services with confidence. Your trust is essential to us, and
                        this Privacy Policy explains how we collect, use, and protect your personal data.
                    </span>
                    <span>
                        The services of Gmind .ai have been designed with the aim to use as little personal data as
                        possible. In this context, personal data is understood to be all individual information about
                        personal or factual circumstances of a specific or identifiable natural person (so-called data
                        subject).
                    </span>
                </div>
            ),
        },
        {
            title: 'We collect two types of information:',
            content: (
                <div className="space-y-4">
                    <ContentElement
                        heading= {<strong>1. Information You Provide</strong>}
                        list={[
                            <span>
                                <strong>Personal Information:</strong> Data necessary for account creation and management, such as name,
                                email address, billing information (if applicable), and school/institution affiliation
                                (if applicable).
                            </span>,
                            <span>
                                <strong>Course Material Interactions:</strong> Information gathered from your interactions with our
                                courses and content, including the materials you view and your engagement with them.
                            </span>,
                            <span>
                                <strong>Feedback and Responses:</strong> Information you provide through surveys, reviews, or other
                                forms of feedback about our services.
                            </span>,
                        ]}
                    />
                    <ContentElement
                        heading={<strong></strong>}
                        list={[
                            <span>
                                <strong>Technical Data:</strong> Information about how you interact with our platform, including
                                browsing activity logs, device information (type, operating system, etc.), and cookies.
                            </span>,
                        ]}
                    />
                </div>
            ),
        },
        {
            title: 'Data Use',
            content: <ContentElement heading="We will not share your personal information with third parties without your explicit consent, except in the following circumstances:" list={[<span><strong>Legal Obligations: </strong>To comply with lawful requests, court orders, or legal investigations.</span>, <span><strong>Dispute Resolution: </strong>To resolve disputes related to our services or agreements.</span>, <span><strong>Enforcing Policies: </strong>To enforce our Terms of Use or other policies to maintain a secure and user-friendly environment.</span>]} />
        },
        {
            title: 'Data Security',
            content:
                "We prioritize the security of your data and implement robust security measures, including encryption, firewalls, and regular system checks. However, no internet transmission method or electronic storage solution is completely infallible..",
        },
        {
            title: 'Your Rights under Global Privacy Regulations',
            content:
            <div className="flex flex-col gap-2">

               <ContentElement heading="In accordance with applicable data privacy regulations, you have certain rights regarding your personal information. These rights may include:" 
              list={[
                <span><strong>Right to Access:</strong> You have the right to request a copy of your personal information that we hold.</span>,
                <span><strong>Right to Rectification:</strong> You have the right to request that we correct any inaccuracies in your personal information.</span>,
                <span><strong>Right to Erasure:</strong> In some cases, you may have the right to request that we erase your personal information.</span>,
                <span><strong>Right to Restrict Processing:</strong> You may have the right to restrict or object to how we process your personal information.</span>,
                <span><strong>Right to Data Portability:</strong> You may have the right to request that we transfer your personal information to another service provider.</span>
            ]}
               />
               <span>We will provide you with reasonable means to exercise these rights. To learn more or submit a request, please contact us at (insert contact information.)</span>
        </div>
        },
        {
            title: 'Changes to the Policy',
            content:
                'We may periodically update this Privacy Policy to reflect changes in our practices or comply with evolving regulations. The revised policy will be readily available on our website. We will notify you of significant changes by posting the updated policy.',
        },
        
        {
            title: 'Contact Us',
            content: (
                <div className="space-y-2">
                    <p>
                        If you have any questions regarding this Cookie Policy or your personal information, please
                        contact us at{' '}
                        <a className="text-brandOrange" href="mailto:support@gotocourse.com">
                            support@gotocourse.com
                        </a>
                    </p>
                    <p>By using our website or services, you consent to the terms of this Cookie Policy.</p>
                    <p>This Cookie Policy was last updated on 05/26/2024.</p>
                    <p>
                        Remember to seek legal advice or have a legal professional review the policy to ensure it
                        complies with relevant laws and regulations in your jurisdiction.
                    </p>
                </div>
            ),
        },
        {
            title: 'Regional Compliance',
            content: <div className='flex flex-col gap-2'>
                <span>
              <strong>
              California Privacy Rights:
                </strong>  If you reside in California, you have the right to request details about our disclosure of personal information for direct marketing purposes by third parties. Please contact us at [insert contact information] to submit such a request.
                </span>
                <span><strong>General Data Protection Regulation (GDPR) Compliance:</strong> We adhere to the GDPR for users within the European Union. If you have questions or concerns about our GDPR compliance, please contact us at [insert contact information].</span>
            </div>
        },
        {title: "Consent", content: "By using our services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy."}
    ];

    return (
        <section className="tos-parent space-y-8">
            <Hero label="Privacy" highlighted="Policy" link="privacy-policy" />

            <div className="space-y-12 px-16 max-md:px-8">
                {Contents.map(({ title, content }) => (
                    <Card title={title} content={content} />
                ))}
            </div>
            <Footer />
        </section>
    );
};
