import {Footer} from '../../components/footer/footer';
import { Card } from '../card';
import { Hero } from '../hero';

import "../style.css"

export const TermsOfService = () => {
    const Contents = [
        {
            title: 'Acceptance of Terms',
            content:
                'By accessing and using G-Mind AI, you are agreeing to these Terms of Use. Therefore, it is important that you read and understand these terms prior to using our services.',
        },
        {
            title: 'Eligibility',
            content:
                'The G-Mind AI service is exclusively designed for educational purposes and for users who are at least 15 years old. By utilizing our service, you affirm that you meet these requirements.',
        },
        {
            title: 'Service Description',
            content:
                'G-Mind AI is an artificial intelligence tool that generates educational content. We strive to provide accurate and reliable information, but we do not guarantee the infallibility of the content created by our AI tool. Therefore, it is essential to cross-verify the credibility of the content.',
        },
        {
            title: 'User Responsibilities',
            content:
                "You are accountable for any activity resulting from your access to our service. You must not use our services in a manner that could interfere with, disrupt, or negatively impact other users' ability to fully utilize our services.",
        },
        {
            title: 'Copyright Policy',
            content:
                'G-Mind AI respects the rights of copyright holders and expects its users to do the same. We may remove or disable access to content that violates copyright laws.',
        },
        {
            title: 'Terms Amendments',
            content:
                'We may amend or update these Terms from time to time. If we make significant changes, we will notify you directly or through a notification on the G-Mind AI service.',
        },
        {
            title: 'References disclaimer',
            content:
                'References provided by G-Mind AI are for informational purposes only and are not intended to be relied upon as advice, guidance, or endorsement. G-Mind AI makes no representations or warranties, express or implied, as to the accuracy, completeness, or reliability of the references. In no event shall G-Mind AI be liable for any damages arising from the use of the references. Users should consult primary sources and exercise their own judgment when using references.',
        },
        {
            title: 'Contact Us',
            content: (
                <div className="space-y-2">
                    <p>
                        If you have any questions regarding this Cookie Policy or your personal information, please
                        contact us at{' '}
                        <a className="text-brandOrange" href="mailto:support@gotocourse.com">
                            support@gotocourse.com
                        </a>
                    </p>
                    <p>By using our website or services, you consent to the terms of this Cookie Policy.</p>
                    <p>This Cookie Policy was last updated on 05/26/2024.</p>
                    <p>
                        Remember to seek legal advice or have a legal professional review the policy to ensure it
                        complies with relevant laws and regulations in your jurisdiction.
                    </p>
                </div>
            ),
        },
    ];

    return (
        <section className='tos-parent space-y-8'>
            <Hero label="Terms of" highlighted="Service" link='terms-of-service' />

            <div className='space-y-12 px-16 max-md:px-8'>
                {Contents.map(({ title, content }) => (
                    <Card title={title} content={content} />
                ))}
            </div>
            <Footer />
        </section>
    );
};
