import useSpeechSynthesizerStore from '@src/providers/useSpeechSynthesizerStore';
import { Tag, notification } from 'antd';
import { CloudUpload, User, Loader } from 'lucide-react';
import React, { useRef, useState } from 'react';
import pdfToText from 'react-pdftotext';
import { sound_models } from '.';

const UploadDocument: React.FC = () => {
    const { voiceModel, setTextContent, setVoiceModel } = useSpeechSynthesizerStore();
    const [uploading, setUploading] = useState(false);
    const [extractedText, setExtractedText] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setUploading(true);

        try {
            console.log(file);
            const text = await pdfToText(file);
            if (!text) {
                notification.error({ message: 'Seems like an issue' });
                return;
            }
            setTextContent(text);
            setExtractedText(text);
            notification.success({ message: 'Text extracted successfully' });
        } catch (error) {
            console.error('Failed to extract text from PDF:', error);
        } finally {
            setUploading(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleAreaClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleVoiceModelChange = (event: any) => {
        const { value } = event.target;
        setVoiceModel(value);
    };

    return (
        <div className="bg-[#F9FAFC] mt-[3rem] mb-[3cm] p-6 rounded-md">
            <div className="flex justify-between items-center text-center">
                <div className="flex items-center text-center mb-2">
                    <User className="mr-2 bg-[#e55901] rounded-full p-1 text-white" />
                    <span className="text-[12px] font-semibold">Upload Document To Synthesize</span>
                </div>
                <p className="flex flex-row gap-2 items-center">
                    <img src="/assets/gmind-icon.png" className="w-[20px] h-[20px]" />
                    <small> G-mind</small>
                </p>
            </div>
            <div
                className="flex border border-dashed p-3 rounded-md items-center text-center justify-center cursor-pointer"
                onClick={handleAreaClick}
            >
                <div className="flex gap-2 text-center items-center justify-center">
                    {uploading ? (
                        <Loader color="#e55901" className="animate-spin bg-[#f0c9b1] p-1 rounded-full" />
                    ) : (
                        <CloudUpload color="#e55901" className="bg-[#f0c9b1] p-1 rounded-full" />
                    )}
                    <small>Upload your document</small>
                </div>
            </div>
            <input
                type="file"
                accept="application/pdf"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                id="file-upload"
                ref={fileInputRef}
            />
            <div className="flex items-center text-center justify-between mt-4">
                {/* <Tag>Voice Model: {voiceModel.toLocaleUpperCase()}</Tag> */}
                <div className="flex gap-1 items-center !text-[12px]">
                    <label>Voice Model:</label>
                    <select
                        // placeholder="Select Voice Model"
                        value={voiceModel || ''}
                        onChange={handleVoiceModelChange}
                        className="!text-[12px] border p-[0.5px] rounded-sm"
                    >
                        <option value="" disabled>
                            Select Voice Model
                        </option>
                        {sound_models.map((model) => (
                            <option key={model.name} value={model.name}>
                                {model.title}
                            </option>
                        ))}
                    </select>
                </div>
                <button className="bg-[#E55109] text-white text-center text-[10px] px-3 py-2 rounded-md w-[rem]">
                    Text is Extracted automatically
                </button>
            </div>
        </div>
    );
};

export default UploadDocument;
