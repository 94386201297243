import Profile from '@assets/Avatar.svg';

interface PromptSentProps {
    promptSent: string;
}

const PromptSentToAI = ({ promptSent }: PromptSentProps) => {
    return (
        <div className="w-full flex flex-row gap-0 bg-white ">
            <div
                style={{
                    fontFamily: 'Inter',
                }}
                className="text-[0.8rem] font-normal px-[1.5rem] py-[1rem] w-full bg-[var(--gmind-header)] h-[4rem] flex flex-row gap-10"
            >
                <img src={Profile} alt="profile" />
                <span>{promptSent}</span>
            </div>
        </div>
    );
};

export default PromptSentToAI;
