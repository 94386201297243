import axios from "axios";
import { BASE_URL } from "../../../utils/constants";

const baseURL = BASE_URL; //process.env.NEXT_PUBLIC_GMIND_BASEURL;


const  handleAddHistory = async (conversation: any, type: string, specificType?: string ) => {
    const token = localStorage.getItem('token') || '';

    let payload: any = {
      conversation
    }

    if ( specificType ){
      payload['type'] = specificType
    }
    
    try {
      const response = await axios.post(
        `${BASE_URL}/gmind/${type}`,
        payload,
        {headers: {
            Authorization: `Bearer ${token}`,
        },}
      );
    } catch (error) {
      console.error("Error adding chat history:", error);
    }
}


const handleDeleteHistory = async (id: string, type: string ) => {
  const token = localStorage.getItem('token') || '';


  try {
    const response = await axios.delete(
      `${BASE_URL}/gmind/${type}/${id}`,
      {headers: {
          Authorization: `Bearer ${token}`,
      },}
    );
  } catch (error) {
    console.error("Error adding chat history:", error);
  }
}

const handleDeleteAllHistory = async (type: string ) => {
  const token = localStorage.getItem('token') || '';


  try {
    const response = await axios.delete(
      `${BASE_URL}/gmind/${type}`,
      {headers: {
          Authorization: `Bearer ${token}`,
      },}
    );
  } catch (error) {
    console.error("Error adding chat history:", error);
  }
}

const handleDeleteAllWithTypeHistory = async (type: string, specificType: string) => {
  const token = localStorage.getItem('token') || '';


  try {
    const response = await axios.delete(
      `${BASE_URL}/gmind/${type}/all/${specificType}`,
      {headers: {
          Authorization: `Bearer ${token}`,
      },}
    );
  } catch (error) {
    console.error("Error adding chat history:", error);
  }
}

const  getHistory = async (type: string) => {
  const token = localStorage.getItem('token') || '';

  try {
    const response = await axios.get(`${BASE_URL}/gmind/${type}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const { data } = response;

    return data;
  } catch (error) {
    return {
        statusCode: 0,
        message: error,
    };
  }
}

export type  Chats = { role: string; content: string; type?: 'gpt-4'; };
export type ChatType = {
  role: 'assistant' | 'user';
  content: string;
};

export {
  handleAddHistory,
  getHistory,
  handleDeleteHistory,
  handleDeleteAllHistory,
  handleDeleteAllWithTypeHistory,
}