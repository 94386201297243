import create from 'zustand';
export type VoiceModel = 'alloy' | 'echo' | 'nova' | 'shimmer' | 'pable' | null;

interface SpeechSynthesizerState {
    textSource: 'manual' | 'upload' | null;
    setTextSource: (source: 'manual' | 'upload') => void;

    voiceModel: string;
    setVoiceModel: (model: string) => void;

    textContent: string;
    setTextContent: (content: string) => void;

    document: File | null;
    setDocument: (file: File | null) => void;
    audioUrl: string | null;
    setAudioUrl: (url: string | null) => void;

    reset: () => void;
}

const useSpeechSynthesizerStore = create<SpeechSynthesizerState>((set) => ({
    textSource: null,
    setTextSource: (source) => set({ textSource: source }),

    voiceModel: '',
    setVoiceModel: (model) => set({ voiceModel: model }),

    textContent: '',
    setTextContent: (content) => set({ textContent: content }),

    document: null,
    setDocument: (file) => set({ document: file }),
    audioUrl: null,
    setAudioUrl: (url) => set({ audioUrl: url }),

    reset: () =>
        set({
            textSource: null,
            voiceModel: '',
            textContent: '',
            document: null,
        }),
}));

export default useSpeechSynthesizerStore;
