import { Form, Input } from "antd";
import { LessonMaterialProps, LetterWriterProps } from "../interface";
import { toast } from "react-toastify";

const LetterWriter = ({ onGenerate, setTopic }: LetterWriterProps) => {

    const onFinish = (values: any) => {
        let promptMessage = '';
        
        const purpose = values.purpose;
        const contentFeeling = values.feeling;
        const recipient = values.audience;
        const information = values.information;

        // check if all data is passed
        if ( !purpose || !recipient) {
            toast.error('Please enter all prompts field');
            return;
        }

        let informationData = information ? `and this Additional Information:${information}` : '';


        promptMessage = `Generate a letter for ${purpose} to ${recipient} with this tone ${contentFeeling} ${informationData}`;

        onGenerate(promptMessage, true);
        
    };

    return (
        <div className="w-full flex flex-col gap-0 bg-white h-auto max-h-full overflow-auto">
            <div className="w-full flex flex-row gap-0 bg-white">
                <div className="w-full flex flex-col gap-0 bg-white px-3 mb:px-10 mb-10">
                    <div className="text-[2rem] md:text-[2.75rem] curriculum--title">Letter Writer</div>
                    <div className="text-[1.125rem] md:text-[0.875rem] mb-3 curriculum--subtitle">
                    Provide us the information requested below to get your learning material ready.
                    </div>
                    <Form layout="vertical" className='flex flex-col md:flex-row flex-wrap gap-x-5' onFinish={onFinish}>
                        
                        <Form.Item
                            className='w-full md:w-[48%]'
                            name={'purpose'}
                            label={
                                <label className="curriculum--label">
                                    {' '}
                                    What is the purpose of the letter?
                                </label>
                            }
                            rules={[
                                {
                                    message: 'purpose is required',
                                    required: false,
                                },
                            ]}
                        >
                            <input
                                className='w-full'
                                placeholder="e.g., Science, History, Literature, Art, Business"
                                style={{
                                    border: '1px solid #DBDADE',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item>
                        

                        <Form.Item
                            className='w-full md:w-[48%]'
                            name={'feeling'}
                            label={
                                <label className="curriculum--label">
                                    What tone do you want the letter to have?
                                </label>
                            }
                            rules={[
                                {
                                    message: 'content feeling is required',
                                    required: false,
                                },
                            ]}
                        >
                            <input
                                className='w-full'
                                placeholder="e.g., Formal, Informal, Academic, Humorous, Informative, Inspirational"
                                style={{
                                    border: '1px solid #DBDADE',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item>

                        <Form.Item
                            className='w-full md:w-[48%]'
                            name={'audience'}
                            label={<label className="curriculum--label">Who is the target recipient of the letter?</label>}
                            rules={[
                                {
                                    message: 'content target audience is required',
                                    required: false,
                                },
                            ]}
                        >
                            <input
                                className='w-full'
                                placeholder="e.g., Elementary School, High School, College Students, General Public"
                                style={{
                                    border: '1px solid #DBDADE',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item>

                        <Form.Item
                            className='w-full md:w-[48%]'
                            name={'information'}
                            label={<label className="curriculum--label"> Additional Information (optional)</label>}
                            rules={[
                                {
                                    message: 'field is required',
                                    required: false,
                                },
                            ]}
                        >
                            <Input.TextArea
                                className='w-full'
                                placeholder="e.g Make it comprehensive"
                                style={{
                                    border: '1px solid #DBDADE',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    height: '64px',
                                    padding: '7px 10px',
                                }}
                                autoSize={{minRows: 3}}
                            />
                        </Form.Item>
                        
                        <button type="submit" className="text-white transcribe">
                            Generate
                        </button>
                    </Form>
                </div>
            </div>
        </div> 
    );
}


export default LetterWriter;