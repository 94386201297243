/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import DashboardFrame from '@src/components/DashboardFrame';
import { useEffect, useRef, useState } from 'react';
import { ChatType, getHistory, handleAddHistory, handleDeleteAllHistory, handleDeleteHistory } from '../../endpoints';
import LoadingComponent from '@src/components/LoadingComponent';
import ContentTypingComponent from '../../components/content_typing';
import BackButton from '@src/components/BackButton';
import Profile from '@assets/Avatar.svg';
import MarkdownDesign from '@src/components/Markdown';
import ResponseTextbox from '../../components/responseTextBox';
import { useNavigate } from 'react-router';
import { BASE_URL } from '@src/utils/constants';
import { toast } from 'react-toastify';
import { Chats } from '../../components/interface/interface';
import { io, Socket } from 'socket.io-client';
import { prompts } from '@src/utils/prompts';
import { openEditor } from '@src/utils/app_functions';
import { v4 as uuidv4 } from 'uuid';
import Welcome from './welcome';
import { CreateDocument } from '@src/core/endpoints/essay';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';

const NewEssayPage = () => {
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [response, setResponse] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({role: 'assistant', content: ''});


    const pageHistory = 'essay';
    const navigate = useNavigate();
    const baseurl = BASE_URL;
    const token = localStorage.getItem('token') || '';
    const bottomRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if ( isTyping && response != '' ){
            console.log("scrolling")
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
        
    }, [isTyping, response]);

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const handleStreamEnd = async (prompt: string, data: ChatType ) => {
    
        // let dataToSave: ChatType[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        await handleAddHistory(dataToSave, pageHistory);
        getPageHistory();
    }

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant)
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);
            

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
            
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    useEffect(() => {
        if ( !isTyping && response != '' ){
            handleStreamEnd(promptSent, streamEndMessage);
        }

        let base = prompts.essay;
        if (canNavigate && response != '') {
            openEditor({ response, pageId: docId, promptSent: promptSent, promptType: base, navigate });
        }
    }, [response, canNavigate, isTyping]);

    const getPageHistory = () => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                // const filtered = data?.map((c: any) => {
                //     return [...c.conversation, { id: c._id, date: c.createdAt }];
                // });
                const filtered: HistoryTypeFormat[]  = data?.map((d: any) => {
                    return {
                            'date': d.date,
                            'histories': d.histories?.map((history: any) => {
                                return [
                                    ...history.conversation,
                                    { chat_id: history?.chat_id },
                                    { id: history._id, },
                                    { date: history.updatedAt },
                                ];
                            })
                        }
                    
                });

                console.log(filtered);
                setHistories(filtered);
            }else{
                setHistories([])
            }
        });
    };

    useEffect(() => {
        getPageHistory();
    }, []);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const setClickedHistory = (id: string) => {
        let filterHistory: any = histories
            .flatMap((historyFormat: any) => historyFormat.histories)
            .filter((history: any) => {
                const chatIdObj = history.find((h: any) => h.id === id);
                return chatIdObj !== undefined;
            })
            .flat();

        // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
        filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
        let userPrompt = filterHistory.find((element: any) => ( element.role == 'user' ) );
        let assistantResponse = filterHistory.find((element: any) => ( element.role == "assistant" ) );
        setPromptSent(userPrompt.content);
        setResponse(assistantResponse.content);
        setShowingHistory(true);
    };

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: prompts.essay,
                            type: 'gpt-4'
                        },
                        msgs,
                    ],
                },
            });
            await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory);
            getPageHistory();
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const onFinish = async (promptMessage: string, combinedPrompt: string) => {
        console.log(promptMessage);
        setPromptSent(combinedPrompt);
        await onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const sendPrompt = async (message: string) => {
        if (!option) {
            toast.error('Kindly select article type');
            return;
        }
        if (!message) {
            toast.error('Kindly enter message');
            return;
        }
        let essayOption = option;
        let input = message;
        let combinedMessage = `Article type: ${essayOption}\nContent: ${input}`;
        let prompt = `Write an essay with this details:\n${combinedMessage}.\nKindly make the essay comprehensive from this details`;

        await onFinish(prompt, combinedMessage);
    };

    const handleDeleteContentHistory = async (id: string) => {
        await handleDeleteHistory(id, pageHistory);
        await getPageHistory();
        toast.success("Chat deleted successfully");
    }

    const handleDeleteAllAssesmentHistory = async () => {
        await handleDeleteAllHistory(pageHistory);
        getPageHistory();
        toast.success("Chat cleared successfully");
    }

    return (
        <DashboardFrame
            canNavigateHome={true}
            showSidebar={!isMobile}
            showHistory={!isMobile}
            showTop={!isMobile}
            history={histories}
            selectedHistory={(v: string) => {
                setClickedHistory(v);
            }}
            showPagePath={false}
            onDeleteHistory={(id: string) => {
                handleDeleteContentHistory(id);
            } }
            onDeleteAllHistory={() => handleDeleteAllAssesmentHistory()}
            showHistoryOption={true}
        >
            <div ref={bottomRef} className='h-full overflow-auto'>
                {!generate && !showingHistory ? (
                    <Welcome isMobile={isMobile} option={(v) => setOption(v)} textInput={(v) => sendPrompt(v)} />
                ) : (
                    <div className="w-full flex flex-col gap-0 bg-white">
                        <div className="w-full flex flex-row gap-0 bg-white">
                            <div className="w-full flex flex-col gap-y-3 bg-white px-10 py-10">
                                <div style={{ marginTop: '10px' }}>
                                    {showingHistory && (
                                        <BackButton
                                            onclick={() => {
                                                setShowingHistory(false);
                                                if (generate) {
                                                    setGenerate(false);
                                                }
                                            }}
                                        />
                                    )}
                                    <div className="w-full flex flex-row gap-0 bg-white ">
                                        <div
                                            style={{
                                                backgroundColor: '#F9FAFC',
                                                fontFamily: 'Inter',
                                                fontSize: '0.8125rem',
                                                fontWeight: '400',
                                                paddingRight: '24px',
                                                paddingLeft: '24px',
                                                paddingTop: '16px',
                                                paddingBottom: '16px',
                                            }}
                                            className="mb-10 w-full px-[1.5rem] py-[1rem] flex flex-row gap-10 bg-white "
                                        >
                                            <img src={Profile} alt="profile" />
                                            <span>{promptSent}</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        {!showingHistory && (
                                            <BackButton
                                                onclick={() => {
                                                    setGenerate(false);
                                                    console.log('hdjdd');
                                                }}
                                            />
                                        )}
                                        {<ContentTypingComponent isTyping={isTyping} />}
                                    </div>

                                    {generating ? (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <>
                                            <ResponseTextbox
                                                content={<MarkdownDesign className="">{response}</MarkdownDesign>}
                                                options={[]}
                                                disLikeClick={() => {}}
                                                messageClick={() => {}}
                                                likeClick={() => {}}
                                                profileClick={() => {}}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DashboardFrame>
    );
};

export default NewEssayPage;
