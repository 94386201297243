import { ToastContainer } from "react-toastify";
import Header from "./header";

type Props = {
    children: React.ReactNode
}

const AcquisitionFrame = ({children}: Props) => {
    return (
        <div className="w-full flex flex-col h-screen bg-white gap-y-5 py-5 px-20">
            <Header />
            {children}
            <ToastContainer />
        </div>
    );
}


export default AcquisitionFrame