import SvgType from '@assets/collapse.svg';

const Option = ({
    icon,
    text,
    collapsed,
    isMobile,
    label,
    selected,
    click,
    index,
    comingSoon= false,
    selectedElemen,
}: {
    icon: typeof SvgType;
    text?: string;
    collapsed: boolean;
    label: string;
    selected: string;
    isMobile: boolean;
    click: (v: string) => void;
    comingSoon: boolean
    index: number
    selectedElemen? : JSX.Element;
}) => {
    return (
        <button
            onClick={() => click(label)}
            style={
                collapsed
                    ? { justifyContent: 'center', background: selected === label ? '#eff1f7' : '#f9fafc' }
                    : {
                          fontWeight: selected === label ? '500' : '400',
                          background: selected === label ? '#eff1f7' : '#f9fafc',
                      }
            }
            type="button"
            className={`${selected ? 'bg-[rgba(0, 31, 115, 0.04)]': 'bg-defaultWhiteLighter'} flex max-lg:gap-1 hover:bg-defaultWhiteLight transition-all p-[0.5rem] rounded-[0.5rem] items-center gap-2 w-full`}
        >
            {
                selectedElemen ? 
                selectedElemen
                : <img
                src={icon}
                alt={text}
                className={`${selected ? 'text-[var(--gmind-orange)]': ''} max-lg:w-[0.7rem!important]`}
                style={collapsed ? { width: '1rem' } : isMobile ? { width: '1.2rem' } : { width: '1.5rem' }}
            />

            }
            

            {!collapsed && (
                <p className={`${index == 0 ? 'text-[#001F73]': 'text-gray-600'} ${selected ? 'font-semibold': 'font-normal'} text-[0.8rem] max-lg:text-[0.55rem] text-nowrap text-left transition-all`}>{text}</p>
            )}

            {
              comingSoon && !collapsed && 
                <div 
                    style={{whiteSpace: "nowrap"}} 
                    className='overflow-x-hidden font-normal text-[0.5rem] text-[var(--gmind-black)] text-center py-[0.25rem] px-[0.3rem] rounded-[0.5rem] bg-[var(--gmind-orange8)]'
                >Coming soon</div>
            }
        </button>
    );
};

export default Option;
