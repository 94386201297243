import React, { useState } from 'react';
import {
    Button,
    ColorPicker,
    Input,
    Modal,
    Progress,
    Radio,
    Tag,
    Tooltip,
    Upload,
    UploadProps,
    message,
    notification,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
    CheckCheckIcon,
    ChevronLeft,
    Copy,
    Download,
    MessageCircleMore,
    Repeat,
    Share2,
    ThumbsDown,
    ThumbsUp,
} from 'lucide-react';
import pptxgen from 'pptxgenjs';
// import toast from "react-hot-toast";
import { Toaster, toast } from 'sonner';
import usePowerPointStore from '@src/providers/store';
import useFormStore from '@src/providers/formStore';
import { generateOpenAimage } from '../../helper';
import { uploadToCloudinary } from '../../upload';
import { Spinner } from './first-form';
import BackgroundTemplateDisplayer from '../BackgroundTemplateDisplayer';
import PptViewer from './preview-slide';
import axios from 'axios';
import storage from '@src/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
const { TextArea } = Input;

type Props = {};
export const cloud_name = 'dobksjz6x';
export const upload_preset = 'ml_default';

function ThirdForm({}: Props) {
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const slides = usePowerPointStore((state) => state.slides);
    const background = usePowerPointStore((state) => state.background);
    const setBackground = usePowerPointStore((state) => state.setBackground);
    const previousStep = useFormStore((state) => state.previousStep);
    const [selectedOption, setSelectedOption] = useState('ai');
    const [aiCommand, setAiCommand] = useState('');
    const [uploadUrl, setUploadUrl] = useState('');
    const [fntColor, setfntColor] = useState('#ffffff');
    const [loading, setIsLoading] = useState<boolean>(false);
    const [loadingp, setIsLoadingp] = useState<boolean>(false);

    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showCheck, setShowCheck] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleUploadChange = (info: any) => {
        toast.info('Uploading....');
        const { status } = info.file;
        if (status === 'done') {
            const image_url = info.file.response.secure_url;
            //   message.success(`${info.file.name} file uploaded successfully.`);
            toast.success(`${info.file.name} file uploaded successfully.`);
            setUploadUrl(image_url);
            setBackground(image_url);
        } else if (status === 'error') {
            toast.error(`${info.file.name} file upload failed.`);
        }
    };

    const uploadProps: UploadProps = {
        name: 'file',
        action: `https://api.cloudinary.com/v1_1/${cloud_name}/upload`,
        headers: {
            'X-Requested-With': '',
        },
        data: {
            upload_preset: upload_preset,
        },
        onChange: handleUploadChange,
    };

    const handleRadioChange = (value: string) => {
        setSelectedOption(value);
        if (value !== 'upload') {
            setUploadUrl('');
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setShowProgress(true);
            handleGenerateAIImage();
        }
    };

    const handleGenerateAIImage = async () => {
        setShowProgress(true);
        try {
            setProgress(0);

            const timer = setInterval(() => {
                if (progress < 100) {
                    setProgress(progress + 10);
                } else {
                    clearInterval(timer);
                }
            }, 1500);

            const aiImageUrl = await generateOpenAimage(aiCommand, '1024x1024');
            const url = await uploadToCloudinary(aiImageUrl?.data.data[0].b64_json);

            setBackground(url);
            setTimeout(() => {
                clearInterval(timer);
                setProgress(100);
                setShowProgress(false);
                setShowCheck(true);
            }, 4000);
            if (aiImageUrl) {
                toast.success('image genarated sucessfully');
            }
        } catch (error) {
            console.error('Error generating AI image:', error);
            setProgress(100);
            setShowProgress(false);
            setShowCheck(false);
        }
    };

    function Back() {
        previousStep();
    }
    //   async function generatePresentation(slidesData: any) {
    //     const pptx = new pptxgen();
    //     const ImagePath = background || "/pptx/p1.jpg";

    //     for (const slide of slidesData) {
    //       const { title, content, image } = slide;
    //       const slideLayout = pptx.addSlide();

    //       slideLayout.background = {
    //         path: ImagePath,
    //         sizing: { type: "cover", w: "100%", h: "100%" },
    //       };

    //       slideLayout.addText(title, {
    //         x: 0.5,
    //         y: 0.5,
    //         w: "90%",
    //         h: 1.0,
    //         align: "center",
    //         color: fntColor,
    //         fontSize: 24,
    //         bold: true,
    //       });

    //       slideLayout.addText(content, {
    //         x: 0.5,
    //         y: 1.5,
    //         w: "90%",
    //         h: 2.0,
    //         align: "left",
    //         color: fntColor,
    //         fontSize: 18,
    //       });

    //       if (image) {
    //         slideLayout.addImage({
    //           path: image,
    //           x: "50%",
    //           y: 3.0,
    //           w: 4.0,
    //           h: 4.0,
    //         });
    //       }
    //     }

    //     await pptx.writeFile({ fileName: "AiGeneratedSlides" });
    //   }
    async function generatePresentation(slidesData: any) {
        const pptx = new pptxgen();
        const ImagePath = background || '/def.jpg';

        for (const slide of slidesData) {
            const { title, content, image } = slide;
            const slideLayout = pptx.addSlide();

            slideLayout.background = {
                path: ImagePath,

                // sizing: { type: 'cover', w: '100%', h: '100%' },
            };

            slideLayout.addText(title, {
                x: 0.5,
                y: 0.5,
                w: '45%',
                h: 1.0,
                align: 'left',
                color: fntColor,
                fontSize: 24,
                bold: true,
            });

            slideLayout.addText(content, {
                x: 0.5,
                y: 1.5,
                w: '45%',
                h: 3.0,
                align: 'left',
                color: fntColor,
                fontSize: 16,
            });

            if (image) {
                slideLayout.addImage({
                    path: image,
                    x: '55%',
                    y: 0.5,
                    w: '35%',
                    h: '75%',
                });
            }
        }

        await pptx.writeFile({ fileName: 'GmindGeneratedSlides' });
    }
    async function preview(slidesData: any) {
        const pptx = new pptxgen();
        const ImagePath = background || '/def.jpg';

        for (const slide of slidesData) {
            const { title, content, image } = slide;
            const slideLayout = pptx.addSlide();

            slideLayout.background = {
                path: ImagePath,
            };

            slideLayout.addText(title, {
                x: 0.5,
                y: 0.5,
                w: '45%',
                h: 1.0,
                align: 'left',
                color: fntColor,
                fontSize: 24,
                bold: true,
            });

            slideLayout.addText(content, {
                x: 0.5,
                y: 1.5,
                w: '45%',
                h: 3.0,
                align: 'left',
                color: fntColor,
                fontSize: 16,
            });

            if (image) {
                slideLayout.addImage({
                    path: image,
                    x: '55%',
                    y: 0.5,
                    w: '35%',
                    h: '75%',
                });
            }
        }

        const arrayBuffer = await pptx.write();
        const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        });
        const fileName = `presentation_${Date.now()}.pptx`;
        const fileRef = ref(storage, `presentations/${fileName}`);

        try {
            const uploadTask = uploadBytesResumable(fileRef, blob);
            await new Promise<void>((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    null,
                    (error) => {
                        console.error('Error uploading file:', error);
                        reject(error);
                    },
                    async () => {
                        resolve();
                    },
                );
            });
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            console.log('url here:' + url);
            return url;
        } catch (error) {
            console.error('Error uploading file:', error);
            return null;
        }
    }

    async function ViewPreview(slides: any) {
        setIsLoadingp(true);
        try {
            let parsedData = [];
            if (Array.isArray(slides)) {
                parsedData = slides;
            } else {
                parsedData = JSON.parse(slides);
            }

            if (!parsedData) {
                notification.error({ message: 'We are unable to process your file' });
                return;
            }

            const previewUrl = await preview(parsedData);
            if (previewUrl) {
                setPreviewUrl(previewUrl);
                toast.success('Preview generated');
            } else {
                notification.error({ message: 'Failed to generate preview' });
            }
        } catch (error: any) {
            notification.error({ message: error.message });
        } finally {
            setIsLoadingp(false);
        }
    }
    const handleOk = async () => {
        try {
            let parsedData = [];
            if (Array.isArray(slides)) {
                parsedData = slides;
            } else {
                parsedData = JSON.parse(slides);
            }

            if (!parsedData) {
                notification.error({ message: 'We are unable to process your file' });
                return;
            }
            await ViewPreview(parsedData);
        } catch (error) {}
        console.log(previewUrl);
        setIsModalVisible(true);
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        setTimeout(async () => {
            try {
                let parsedData = [];
                if (Array.isArray(slides)) {
                    parsedData = slides;
                } else {
                    parsedData = JSON.parse(slides);
                }

                if (!parsedData) {
                    notification.error({ message: 'We are unable to process your file' });
                    return;
                }
                await generatePresentation(parsedData);
                toast.success('Voila, your file is downloaded, check the beautiful creation');
            } catch (error: any) {
                notification.error({ message: error.message });
            } finally {
                setIsLoading(false);
                // setShowModal(true);
            }
        }, 3000);
    };
    const handleColorChange = (value: any, hex: string) => {
        setfntColor(hex);
    };

    return (
        <div className="text-[#232323] font-[500] text-[36px] max-w-[50%]  max-sm:max-w-full overflow-auto">
            <Toaster richColors />
            <button
                onClick={Back}
                className="text-center text-[12px] flex justify-center items-center mb-[2] pt-2 gap-3 "
            >
                <ChevronLeft className="bg-gray-200 rounded-full !font-[300] text-gray-700 !text-sm" />
                <span>Back</span>
            </button>
            <h1>Generate Slides</h1>
            <p className="text-gray-500 font-[400] text-[14px] mb-0">
                Choose the method to get your slides' background
            </p>
            <Tooltip title="Click the small box to change font color">
                <div className="flex gap-4 items-center text-center text-[19px] p-3 pl-0 mb-4">
                    <small>➤ Select preffered font color, default is white</small>
                    <ColorPicker defaultValue="#ffffff" onChange={handleColorChange} />
                </div>
            </Tooltip>
            <Radio.Group
                className="text-[8px] flex text-gray-500 !mb-4 !text-sm gap-4"
                value={selectedOption}
                onChange={(e) => handleRadioChange(e.target.value)}
            >
                <div
                    className={`flex items-center space-x-2 border border-[gray] p-1 rounded-md pr-2 ${
                        selectedOption === 'ai' ? 'bg-[#E55109] text-white border-none' : ''
                    }`}
                >
                    <Radio
                        className={`${selectedOption === 'ai' ? ' text-white border-white' : ''}`}
                        value="ai"
                        id="r1"
                    />
                    <label htmlFor="r1">AI-Generated</label>
                </div>
                <div
                    className={`flex items-center space-x-2 border border-[gray] p-1 rounded-md pr-2 ${
                        selectedOption === 'default' ? 'bg-[#E55109] text-white border-none' : ''
                    }`}
                >
                    <Radio
                        className={`${selectedOption === 'default' ? ' text-white border-white' : ''}`}
                        value="default"
                        id="r2"
                    />
                    <label htmlFor="r2">Select Template</label>
                </div>
                <div
                    className={`flex items-center space-x-2 border border-[gray] p-1 rounded-md pr-2 ${
                        selectedOption === 'upload' ? 'bg-[#E55109] text-white border-none' : ''
                    }`}
                >
                    <Radio
                        className={`${selectedOption === 'upload' ? ' text-white border-white' : ''}`}
                        value="upload"
                        id="r3"
                    />
                    <label htmlFor="r3">Upload</label>
                </div>
            </Radio.Group>
            {selectedOption === 'ai' && (
                <div>
                    <label className="text-gray-500 text-[14px]" htmlFor="ai-command">
                        Enter command for AI
                    </label>
                    <div className="flex items-center text-center justify-center gap-2 ">
                        <Input
                            className="focus:border-[#E55109] active:border-[#E55109]"
                            id="ai-command"
                            placeholder={`Enter description for AI for Slide Background,click enter to generate ↵`}
                            value={aiCommand}
                            onChange={(e) => setAiCommand(e.target.value)}
                            onKeyDown={(e) => handleKeyPress(e)}
                        />
                        {/* <small className='text-[12px]'>Enter</small> */}
                        <Tag onClick={() => handleGenerateAIImage()} className="text-[12px] cursor-pointer">
                            Enter
                        </Tag>
                    </div>
                    {showProgress && <Progress percent={progress} />}
                    {showCheck && (
                        <div className="flex text-[12px] py-4">
                            <CheckCheckIcon style={{ color: 'green' }} />
                            Generated image
                        </div>
                    )}{' '}
                    {background && <img src={background} className="h-18 w-20" alt={`Slide ${+1}`} />}
                </div>
            )}
            {selectedOption === 'default' && (
                <div>
                    <BackgroundTemplateDisplayer />
                </div>
            )}
            {selectedOption === 'upload' && (
                // <Upload {...uploadProps} className="mt-[10%]">
                //     <Button icon={<UploadOutlined />}>Click to Upload</Button>
                // </Upload>
                <Upload
                    {...uploadProps}
                    // name="avatar"
                    listType="picture-card"
                    className="avatar-uploader mt-[10%]"
                    showUploadList={false}
                    // className="mt-[10%]"
                    // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                    // beforeUpload={beforeUpload}
                    // onChange={handleChange}
                >
                    {background ? (
                        <img src={background} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                        <Button icon={<UploadOutlined />}></Button>
                    )}
                </Upload>
            )}
            <div className="flex gap-6 items-center w-full justify-center">
                <button
                    className="bg-none border border-gray-400 text-[black] mt-6  hover:bg-gray-600 text-sm py-2 px-3 !mb-36 disabled:bg-[#e4af95] rounded-md"
                    onClick={Back}
                    // disabled={loading}
                >
                    Back to Edit
                </button>
                <button
                    className="bg-[#E55109] text-[white] mt-6 border-none hover:bg-[#b6511e] text-sm py-2 px-3 !mb-36 disabled:bg-[#e4af95] rounded-md"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {!loading ? (
                        'Generate you Slides'
                    ) : (
                        <div className="flex items-center text-sm text-center">
                            <Spinner />
                            <small>Generating your slides'</small>
                        </div>
                    )}
                </button>
                <button
                    className="bg-gray-400 text-[white] mt-6 border-none hover:bg-gray-600 text-sm py-2 px-3 !mb-36 disabled:bg-[#e4af95] rounded-md"
                    onClick={() => handleOk()}
                    disabled={loading}
                >
                    {!loadingp ? (
                        'Preview Slides'
                    ) : (
                        <div className="flex items-center text-sm text-center">
                            <Spinner />
                            {/* <small>Generating your preview'</small> */}
                        </div>
                    )}
                </button>
            </div>
            {/* {isModalVisible && <PptViewer fileUrl={previewUrl} />} */}

            <div>
                <Modal
                    title="G-mind Generated PowerPoint Preview"
                    visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                    width="80%"
                    height="80%"
                >
                    <div className="flex justify-between text-center items-center gap-4 p-4 mt-4 !bg-white">
                        <div className="flex gap-2">
                            <button
                                // onClick={handleShare}
                                className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                            >
                                <Share2 size="14px" color="#E55109" />
                                <small>Share</small>
                            </button>
                            <button
                                className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                                // onClick={handleCopy}
                            >
                                <Copy size="14px" color="#E55109" />
                                <small>Copy</small>{' '}
                            </button>
                            <button
                                className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                                // icon={<Download />}
                                // onClick={handleDownload}
                            >
                                <Download size="14px" color="#E55109" />
                                <small>Download</small>{' '}
                            </button>
                        </div>
                        <div className="flex gap-2">
                            <Repeat
                                color="#E55109"
                                className="hover:cursor-pointer"
                                size={'14px'}
                                // onClick={handleRegenerate}
                            />
                            <MessageCircleMore
                                color="#E55109"
                                className="hover:cursor-pointer"
                                size={'14px'}
                                // onClick={OpenCommentModal}
                            />
                            <ThumbsUp color="#E55109" className="hover:cursor-pointer" size={'14px'} />
                            <ThumbsDown color="#E55109" className="hover:cursor-pointer" size={'14px'} />
                        </div>
                    </div>
                    <iframe
                        src={`https://docs.google.com/gview?url=${encodeURIComponent(previewUrl)}&embedded=true`}
                        title="G-mind Generated PowerPoint Preview"
                        width="100%"
                        height="500px"
                        className=""
                        style={{ border: 'none' }}
                    />

                    <div>{/* <h1>hh</h1> */}</div>
                </Modal>
            </div>
        </div>
    );
}

export default ThirdForm;
