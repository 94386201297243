import About from "../components/about";
import Header from "../components/header/index.tsx";
import wavyDesign from "../../../assets/Wave element.png";
import Playground from "../components/playground/index.tsx";
import Partners from "../components/partners";
import QRSection from "../components/qr_section";
// import Engagement from "../components/engagement";
import Testimonies from "../components/testimonies";
import {Footer} from "../components/footer/footer";
import FAQ from "../components/faq";
import Services from "../components/services/indes";
import { GetStartedButton, GetStartedForFree } from "@src/components/GetStartedButton/index.tsx";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import { landingPagePrps } from "@src/core/interfaces/general.tsx";
import FeaturesImg  from "@assets/features_img.png"
import AdvancedFeatures from "../features/components/advanced_features.tsx";


const LandingPage = ({faqRef, featuresRef, isLoggedIn}: landingPagePrps) => {
    const navigatePage = useNavigate();

    return (
        <div className="w-full bg-white pt-2">
            <Helmet>
                <title>Home - GMIND Ai: Best AI Assistant for TEACHERS and CONTENT CREATORS</title>
                <meta title="GMIND Ai: Best AI Assistant for TEACHERS and CONTENT CREATORS" name="description" content="Explore Gmind AI – the platform that boosts learning and creativity with AI-driven tools. Enhance productivity and gain insights. Start today!" />
                <link rel="canonical" href="https://gmind.ai/" />
                <meta name="keywords" content="gmind, ai, chat" />
            </Helmet>
            <div className="relative w-full h-auto md:h-[65vh] mb-20 md:mb-[8rem] z-10">
                <div className="w-full px-10 md:px-10">
                    <About isLoggedIn={isLoggedIn} />
                </div>
                <img className="absolute top-[95%] md:top[90%] lg:top[80%] bottom-0 w-full h-[5rem] md:h-[7rem] z-0" src={wavyDesign} alt="wavyDesign" loading="lazy" />
            </div>
            {/* <section className="w-full flex items-center justify-center mb-10">
                <img className="self-center hidden md:flex w-[45rem] h-[30rem]" src={FeaturesImg} alt="feature_img" loading="lazy" />
            </section> */}
            <div className="flex md:hidden justify-center items-center">
                <GetStartedForFree isLoggedIn={isLoggedIn} className="w-[10rem]" onclick={() => {
                     if ( isLoggedIn ) {
                        navigatePage("/dashboard/chat");
                     }else {
                       navigatePage("/signup?forFree=1"); 
                     } 
                }}  />
            </div>
            <div className="w-full">
                <section ref={featuresRef}  className="w-full mt-5 md:mt-0 px-0 md:px-20">
                    <Playground />
                    {/* <Partners /> */}
                </section>

                <section className="w-full mt-10">
                    <AdvancedFeatures />
                </section>
                
                {/* <div className="w-full mt-5 md:mt-0 px-10 md:px-20">
                    <QRSection />
                </div> */}
                {/* <section className="w-full mt-20 md:mt-5 px-10 md:px-20 mb-10">
                    <Services />
                </section> */}
                {/* <section className="w-full mt-20 md:mt-0 px-10 md:px-20">
                    <Engagement />
                </section> */}
                <section className="w-full mt-10 md:mt-5 px-10 md:px-20">
                    <Testimonies />
                </section>
                <section ref={faqRef} className="w-full mt-5 md:mt-5 px-10 md:px-20">
                    <FAQ />
                </section>
            </div>
            <section>
                <Footer />
            </section>
        </div>
    );
}


export default LandingPage