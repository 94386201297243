import useFormStore from '@src/providers/formStore';
import usePowerPointStore from '@src/providers/store';
import React, { useEffect, useState } from 'react';
import DashboardFrame from '@src/components/DashboardFrame';
import { ChatType, getHistory } from '../endpoints';
import FirstScreen from './components/first-screen';
import SecondForm from '../pages/slide/component/Forms/second-form';
import SecondScreen from './components/second-screen';

type Props = {};

function page({}: Props) {
    const { currentStep, nextStep, previousStep } = useFormStore();
    const { ...formState } = usePowerPointStore();
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);

    const pageHistory = 'image_maker';

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    const getPageHistory = () => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                const filtered = data?.map((c: any) => {
                    return [...c.conversation, { id: c._id }];
                });
                setHistories(filtered);
            }
        });
    };
    useEffect(() => {
        getPageHistory();
    }, []);
    const setClickedHistory = (id: string) => {
        const filterHistory = histories
            .filter((h: any) => {
                const { id: history_id } = h[h.length - 1];
                return String(history_id) === String(id);
            })
            .flat()
            .filter((h: any) => !h?.id);
        setChatList(filterHistory);
    };
    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return <FirstScreen />;
            case 1:
                return <SecondScreen />;
            case 2:
                return <FirstScreen />;
            default:
                return <div>Unknown Step</div>;
        }
    };
    return (
        <DashboardFrame
            showSidebar={!isMobile}
            showHistory={!isMobile}
            showTop={!isMobile}
            history={histories}
            selectedHistory={(v: string) => {
                setClickedHistory(v);
            }}
            showHistoryOption={false}
            onDeleteHistory={()=> {}}
            canNavigateHome={true}

        >
            <div className="w-full px-10">{renderStepContent()}</div>
        </DashboardFrame>
    );
}

export default page;
