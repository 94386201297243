/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import send from '@assets/send.svg';
import microphone from '@assets/microphone.svg';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import { useEffect, useRef, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';
import { toast } from 'react-toastify';

const Input = ({
    value,
    showLeftField,
    placeholder = 'Ask your question',
}: {
    value: (v: string) => void;
    showLeftField?: boolean;
    placeholder?: string;
}) => {
    const inputRef = useRef<any>();
    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Value, setValue] = useState('');
    function handleSent() {
        value(Value);
        setValue('');
    }
    const { startRecording, stopRecording, isRecording, recordingBlob } = useAudioRecorder();

    useEffect(() => {
        if (recordingBlob) {
            console.log('there is audio');
            uploadAudio(recordingBlob);
        }

        return () => {
            stopRecording();
            setValue('');
        };
    }, [recordingBlob]);

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            handleSent();
        }
    }

    async function handleMicrophone() {
        console.log('mic started');
        if (isRecording) {
            setRecording(false);
            stopRecording();
        } else {
            setRecording(true);
            startRecording();
        }
    }

    async function uploadAudio(audio: Blob) {
        try {
            setLoading(true);
            const response = await axios.post(
                'https://api-inference.huggingface.co/models/openai/whisper-large-v3',
                audio,
                {
                    headers: {
                        'Authorization': `Bearer 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`, //--production api key
                        'Content-Type': 'audio/*',
                    },
                },
            );

            const { data, status } = response;
            console.log(status);

            if (status === 200) {
                setLoading(false);
                setValue(data.text);
            }
        } catch (error) {
            setLoading(false);
            toast.error('Sorry, voice translation is not available at this time.');
            console.error(error);
        }
    }

    return (
        <div className="space-y-2 max-md:h-fit flex flex-col max-md:py-1 bg-white">
            <section className="flex max-md:flex-col items-center gap-2 h-full">
                {showLeftField && (
                    <input
                        type="text"
                        className="border flex-1 h-12 placeholder:font-normal placeholder:text-gray-400 placeholder:text-xs w-full basis-[20%] border-[#f5b99d] transition-all focus:border-brandOrange p-2 px-4 text-nowrap rounded-xl  text-xs flex items-center outline-none max-md:py-4"
                        placeholder="Enter your field"
                    />
                )}
                <div
                    style={
                        loading
                            ? { animation: 'pulse 1s ease infinite', userSelect: 'none' }
                            : { userSelect: 'auto', animation: 'none' }
                    }
                    className="flex flex-1 basis-[80%] items-center border bg-white border-gray-400 rounded-2xl h-full w-full px-3"
                >
                    {!recording && (
                        <img
                            onClick={() => {
                                if (loading) {
                                    toast.info('Relax, your speech will soon be converted to text 🙂');
                                }
                                !loading && handleMicrophone();
                            }}
                            role="button"
                            src={microphone}
                            className="flex-1 basis-[5%] w-full h-6"
                        />
                    )}
                    {recording && (
                        <BounceLoading
                            onClick={() => {
                                if (loading) {
                                    toast.info('Relax, your speech will soon be converted to text 🙂');
                                }
                                !loading && handleMicrophone();
                            }}
                            className="h-6 w-6 flex-1 basis-[5%]"
                        />
                    )}

                    <input
                        ref={inputRef}
                        onInput={(e: any) => {
                            setValue(e.target.value);
                        }}
                        value={Value}
                        onKeyDown={handleKeyDown}
                        type="text"
                        className="w-full h-[50px] flex-1 basis-[90%] outline-none border-gray-300 text-sm px-2 placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm"
                        placeholder={placeholder}
                        disabled={loading}
                    />
                    <img
                        onClick={handleSent}
                        role="button"
                        src={send}
                        alt="send"
                        className="block w-full h-7 flex-1 basis-[5%]  send"
                    />
                </div>
            </section>
            <p className=" text-gray-400 text-xs">
                Gmind can make mistakes. It's advisable to verify crucial information.
            </p>
        </div>
    );
};

const BounceLoading = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
    return (
        <section
            role="button"
            onClick={onClick && onClick}
            className={`bounce_parent flex justify-center items-center gap-[0.1rem] ${className}`}
        >
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
        </section>
    );
};

export default Input;
