import useFormStore from '@src/providers/formStore';
import usePowerPointStore from '@src/providers/store';
import React, { useState } from 'react';
import { generateSonObjects, handleAutoGenerate } from '../../helper';
import { Button, Input, Select } from 'antd';
import { toast } from 'react-toastify';
// import { Button } from "@/components/ui/button";
// import { Input } from "@/components/ui/input";
// import { Label } from "@/components/ui/label";
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "@/components/ui/select";
// import { Textarea } from "@/components/ui/textarea";
// import usePowerPointStore, { Slide } from "@/app/providers/store";
// import useFormStore from "@/app/providers/formStore";
// import {
//   generateSonObjects,
//   handleAutoGenerate,
// } from "@/app/dashboard/new-slide/helper";
// import { ThreeDots } from "react-loader-spinner";
const { TextArea } = Input;
const { Option } = Select;

type Props = {};

const FirstForm: React.FC<Props> = () => {
    const setSlidesDescription = usePowerPointStore((state) => state.setSlidesDescription);
    const setPages = usePowerPointStore((state) => state.setPages);
    const setContentSource = usePowerPointStore((state) => state.setContentSource);
    const nextStep = useFormStore((state) => state.nextStep);
    const slideDescription = usePowerPointStore((state) => state.slidesDescription);

    const setSlides = usePowerPointStore((state) => state.setSlides);

    const [slidesDescription, setSlideDescription] = useState(slideDescription);
    const [loading, setIsLoading] = useState(false);
    const [slidePages, setSlidePages] = useState<number | undefined>(undefined);
    const [contentSource, setContentSourceState] = useState< ''| 'ai' | 'manual'>('');

    const handleGetStarted = async () => {
        try {
            setIsLoading(true);
            setSlidesDescription(slidesDescription);
            setPages(slidePages ?? 0);
            setContentSource(contentSource);
            if ( contentSource == '' ){
                toast.error('Please select content source');
                return;   
            }
            
            if (contentSource === 'manual') {
                const generatedSlides = generateSonObjects(slidePages ?? 0);
                if (generatedSlides) {
                    setSlides(generatedSlides);
                    nextStep();
                }
            } else {
                const generatedSlides = await handleAutoGenerate(slidePages ?? 0, slidesDescription);
                if (generatedSlides) {
                    setSlides(generatedSlides);
                    nextStep();
                }
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="text-[#232323]">
            <h1 className=" font-[500] text-[36px] md:mt-[5%]">Create slides</h1>
            <p className="text-gray-500 font-[400] text-[14px] mb-8">
                Provide us the information requested below to create slides.
            </p>
            <div className="space-y-2 max-w-[50%] max-sm:max-w-full">
                <div className="space-y-1 mb-4">
                    <label htmlFor="slide-type" className="text-[#434246] font-[400] text-[13px]">
                        Describe the type of slides you want to generate
                    </label>
                    <TextArea
                        className="focus:border-[#E55109] active:border-[none] focus:outline-none focus:shadow-none hover:border-[#E55109] "
                        id="slide-type"
                        placeholder="E.g. Business presentation, Educational slides, etc."
                        value={slidesDescription}
                        onChange={(e) => setSlideDescription(e.target.value)}
                    />
                </div>
                <div className="space-y-1 mb-4">
                    <label className="text-[#434246] font-[400] text-[13px]" htmlFor="slide-count">
                        How many slide pages do you want?
                    </label>
                    <Input
                        className="focus:border-[none] focus:border-[#E55109] active:border-[none] focus:outline-none focus:shadow-none hover:border-[#E55109] placeholder:text-grey-100"
                        id="slide-count"
                        placeholder="Number of slides you want"
                        type="number"
                        value={slidePages}
                        onChange={(e) => setSlidePages(parseInt(e.target.value, 10))}
                    />
                </div>
                <div className="space-y-1 flex flex-col mb-4 !mt-4">
                    <label className="text-[#434246] font-[400] text-[13px]" htmlFor="content-source">
                        Content source
                    </label>

                    <Select
                        className="text-[#434246] font-[400] focus:border-[#E55109] active:border-[none] focus:outline-none focus:shadow-none hover:border-[#E55109] "
                        value={contentSource}
                        onChange={(value) => setContentSourceState(value as '' | 'ai' | 'manual')}
                        placeholder="Select content source"
                    >
                        <Option value="">Select Content Source</Option>
                        <Option value="manual">Manual</Option>
                        <Option value="ai">AI Generated</Option>
                    </Select>
                </div>
            </div>
            <button
                className="bg-[#E55109] text-[white] mt-6 border-none hover:bg-[#b6511e] text-sm py-2 px-3 disabled:bg-[#e4af95] rounded-md"
                disabled={loading}
                onClick={handleGetStarted}
            >
                {!loading ? (
                    'Get started'
                ) : (
                    <div className="flex items-center text-sm text-center">
                        <Spinner />
                        <small>Generating your slides' content...</small>
                    </div>
                )}
            </button>
        </div>
    );
};

export default FirstForm;

export const Spinner = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ margin: 'auto', background: 'none', display: 'block' }}
        width="20px"
        height="20px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
    >
        <circle
            cx="50"
            cy="50"
            r="32"
            strokeWidth="8"
            stroke="#fff"
            strokeDasharray="50.26548245743669 50.26548245743669"
            fill="none"
            strokeLinecap="round"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;1"
                values="0 50 50;360 50 50"
            ></animateTransform>
        </circle>
    </svg>
);
