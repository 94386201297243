import React, { useEffect, useState } from 'react';
import { Input, Tag, Button, notification, Select } from 'antd';
import { User } from 'lucide-react';
import useSpeechSynthesizerStore, { VoiceModel } from '@src/providers/useSpeechSynthesizerStore';
import { textToSpeech } from './openaiTTS';
import { sound_models } from '.';
import { Toaster, toast } from 'sonner';
// import { sound_models } from '@src/providers/soundModels'; // Adjust the import path as necessary

const { TextArea } = Input;
const { Option } = Select;

const TextBox: React.FC = () => {
    const { textContent, setTextContent, voiceModel, setVoiceModel } = useSpeechSynthesizerStore();
    const { setAudioUrl } = useSpeechSynthesizerStore((state) => ({
        setAudioUrl: state.setAudioUrl,
    }));
    const [loading, setLoading] = useState<boolean>(false);
    const [charCount, setCharCount] = useState<number>(0);

    useEffect(() => {
        setCharCount(textContent.length);
        if (textContent.length > 4096) toast.error('Your character counts has exceeded 4096, please try reducing it');
    }, [textContent]);

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextContent(e.target.value);
    };

    const handleGenerateAudio = async () => {
        if (!textContent) {
            notification.error({ message: 'Please enter some text to synthesize.' });
            return;
        }

        try {
            setLoading(true);
            if (!voiceModel) {
                notification.error({ message: 'Please select a voice model.' });
                return;
            }
            const audioUrl = await textToSpeech(textContent, voiceModel);
            setAudioUrl(audioUrl);
            notification.success({ message: 'Audio generated successfully.' });
        } catch (error) {
            notification.error({ message: 'Failed to generate audio. Please try again.' });
        } finally {
            setLoading(false);
        }
    };

    const handleVoiceModelChange = (event: any) => {
        const { value } = event.target;
        setVoiceModel(value);
    };

    return (
        <div className="bg-[#F9FAFC] rounded-lg shadow p-6">
            <Toaster richColors />
            <div className="flex items-center mb-4">
                <User className="mr-2 bg-[#e55901] rounded-full p-1 text-white" />
                <span className="text-[12px] font-semibold">Write Text To Synthesize</span>
            </div>
            <TextArea
                placeholder="Enter Audio description & click Generate to get started"
                value={textContent}
                onChange={handleTextChange}
                className="mb-2 !min-h-[40vh] md:!min-h-[30vh] hover:border-[#e55901] focus-within:border-[#e55901] focus:border-[#e55901] focus-visible:border-[#e55901] active:border-[#e55901]"
                rows={4}
                disabled={loading}
                maxLength={4096}
            />
            <div className="text-right text-[12px] text-gray-500 mb-2">{charCount} / 4096 characters</div>
            <div className="flex flex-col md:flex-row gap-y-3 justify-between items-center text-center">
                <div className="flex gap-1 items-center !text-[12px]">
                    <label>Voice Model:</label>
                    <select
                        value={voiceModel || ''}
                        onChange={handleVoiceModelChange}
                        className="!text-[12px] border p-[0.5px] rounded-sm"
                    >
                        <option value="" disabled>
                            Select Voice Model
                        </option>
                        {sound_models.map((model) => (
                            <option key={model.name} value={model.name}>
                                {model.title}
                            </option>
                        ))}
                    </select>
                </div>
                <button
                    className="bg-[#E55109] text-white text-center text-[10px] px-3 py-2 rounded w-[7rem]"
                    onClick={handleGenerateAudio}
                    disabled={loading}
                >
                    {loading ? 'Generating...' : 'Generate Audio'}
                </button>
            </div>
        </div>
    );
};

export default TextBox;
