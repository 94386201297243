import useSpeechSynthesizerStore from '@src/providers/useSpeechSynthesizerStore';
import ManualScreen from './manual-option';
import UploadOption from './upload-option';

type Props = {};

function ThirdScreen({}: Props) {
    const { textSource } = useSpeechSynthesizerStore();
    if (textSource === 'manual') {
        return (
            <section>
                <ManualScreen />
            </section>
        );
    } else {
        return (
            <section>
                <UploadOption />
            </section>
        );
    }
}

export default ThirdScreen;
