import { useState } from "react";
import { curriculumOptions, questionTypeOptions } from "../../curriculum/constants";
import { InputTypeProps, SubPageeProps } from "../interface";
import { toast } from "react-toastify";
import { Form } from "antd";
import { Select } from "@src/pages/dashboard/components/Select";



const QuizAssessment = ({ onSubmit, nextPage }: SubPageeProps) => {
    const [inputValues, setInputValues] = useState<{[key: string]: string }>({
        'no_of_question': '0',
        'question_type': '',
    });

    const handleOnChange = (value: string, keyString: string) => {
        setInputValues({
            ...inputValues,
            [keyString]: value
        })
    };

    const inputData: Array<InputTypeProps> = [
            {
            name: "Number of Questions*",
            placeholder: "Enter number of questions... e.g 10 - 15 questions",
            value: 'no_of_question',
            onChange: (value, key)  => handleOnChange(value, key),
            type: 'input',
        },
        {
            name: "Question Types*",
            placeholder: "Select your question type",
            value: 'question_type',
            onChange: (value, key)  => handleOnChange(value, key),
            type: 'select',
            options: questionTypeOptions
        }, 
    ];

    const onSubmitData = () => {
        let canProcced: boolean = true;
        let baseToSend = '';
        inputData.forEach((item) => {
            if ( !inputValues[item.value]  ){
                canProcced = false;
                return;
            }else{
                baseToSend += `${item.name.replace("*", "")}: ${ ( inputValues[item.value].toLowerCase() === 'mcqs' )? 'Multiple Choice': inputValues[item.value] }\n`;
            }
        })


        if ( !canProcced ){
            toast.error('Kindly enter all values');
            return;
        }
        
        console.log(baseToSend);

        onSubmit(baseToSend);
    }


    return (
        <div className="w-full flex flex-col gap-y-3">
            <div className="flex flex-col gap-y-3 w-full md:w-[70%]">
                <h2 className="font-medium text-[2rem] md:text-[2.75rem] text-[var(--gmind-black)]">Create Assessment</h2>
                <p className="text-[0.875rem] text-[var(--gmind-white60)] text-normal">Provide us the information requested below to create an assessment.</p>
            </div>

            <Form layout="vertical" onFinish={onSubmitData} className="flex flex-col gap-y-3 gap-x-3 w-full">
                {
                    inputData.map((item, index) => (
                        <Form.Item
                            key={index}
                            className="w-full md:w-[48%]"
                            name={item.name}
                            label={<label className="curriculum--label">{item.name}</label>}
                            rules={[
                                {
                                    message: `${item.name} is required`,
                                    required: false,
                                },
                            ]}
                        >
                            {
                                item.type == 'input' && (
                                    <input
                                        className="w-full rounded-[0.25rem] outlline-none"
                                        style={{
                                            border: '1px solid #DBDADE',
                                            borderRadius: '4px',
                                            backgroundColor: 'transparent',
                                            padding: '7px 10px',
                                        }}
                                        value={inputValues[item.value]}
                                        onChange={ (e) =>  item.onChange(e.target.value, item.value) } 
                                        placeholder={item.placeholder}
                                    />
                                )
                            }
                            {
                                item.type == 'select' && (
                                    <Select
                                        className="w-full rounded-[0.25rem]"
                                        onSelect={ (e) =>  item.onChange(e, item.value) } 
                                        options={item.options ?? []}
                                    />
                                )
                            }

                        </Form.Item>
                    ))
                }
                <button type="submit" className="text-white transcribe">
                    Generate
                </button>
            </Form>
        </div>
    );
}

export default QuizAssessment;