// PaymentComponent.js
"use client";
import React, { useState } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "./ui/button";
import { BASE_URL } from "@src/utils/constants";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Modal, Form, Input } from 'antd';

const FlutterwareComponent = ({ amount, currency, title, cssStyle, pricingPeriod }: any) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  
  
  const handlePayment = async (discountCode: string) => {
    const token = localStorage.getItem("token");
    if ( !token ) {
      toast.error("Please login to continue");
      setTimeout(() => {
        navigate(`/login?fromPricing=1&amount=${amount}&currency=${currency}&pricingPeriod=${pricingPeriod}`);
      }, 2000)
      return;
    }

    const user = localStorage.getItem("user");

    if ( !user ) return;

    let userDetails = JSON.parse(user);

    setLoading(true);

    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    
    const checkoutUrl = `${protocol}//${hostname}/dashboard/payment-success`;
    const cancelUrl = `${protocol}//${hostname}/dashboard/pricing`;
    
    console.log('Checkout URL:', checkoutUrl);
    console.log('Cancel URL:', cancelUrl);

    const baseurl = BASE_URL;

    const res = await fetch(`${baseurl}/user/create-checkout-flutterwave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount, currency, checkoutUrl, cancelUrl, repayment: pricingPeriod, discountCode }),
    });
    

    const session = await res.json();

    if ( session.statusCode != 1 ){
      toast.error(session.message);
      setLoading(false);
      return;
    }

    const sessionData = session.data;

    window.location = sessionData.url;
  }

  return (
    <>
      <CodeEntryModal loading={loading} isModalVisible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOkay={handlePayment} />
      <Button
        className={cssStyle}
        variant="outline"
        disabled={loading}
        onClick={ () => {
          const token = localStorage.getItem("token");
          if ( !token ) {
            toast.error("Please login to continue");
            setTimeout(() => {
              navigate(`/login?fromPricing=1&amount=${amount}&currency=${currency}&pricingPeriod=${pricingPeriod}`);
            }, 2000)
            return;
          }
          setIsModalVisible(true)
        }}
      >
        {title}
      </Button>
    </>
    
  );
};

const StripeComponent = ({ amount, currency, title, cssStyle, pricingPeriod }: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
 


  const handleClick = async (discountCode: string) => {
    // check if user is logged in
    const token = localStorage.getItem("token");
    if ( !token ) {
      toast.error("Please login to continue");
      setTimeout(() => {
        navigate(`/login?fromPricing=1&amount=${amount}&currency=${currency}&pricingPeriod=${pricingPeriod}`);
      }, 2000)
      return;
    }

    const user = localStorage.getItem("user");

    setLoading(true);

    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    
    const checkoutUrl = `${protocol}//${hostname}/dashboard/payment-success`;
    const cancelUrl = `${protocol}//${hostname}/dashboard/pricing`;
    
    console.log('Checkout URL:', checkoutUrl);
    console.log('Cancel URL:', cancelUrl);

    const baseurl = BASE_URL;

    const res = await fetch(`${baseurl}/user/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount, currency, checkoutUrl, cancelUrl, repayment: pricingPeriod, discountCode }),
    });
    

    const session = await res.json();

    if ( session.statusCode != 1 ){
      toast.error(session.message);
      setLoading(false);
      return;
    }

    const sessionData = session.data;

    console.log("session", sessionData);
   

    const stripePromise = loadStripe(
      "pk_live_51PVl0ZIU4MeowKFNhRw1waVtl9ZRNJIdclbrem4ogfnO6Fr7ylon26fPYKwMgu64AZwbsjG9ZrzTW4vuDDJD5JY500F22m1JLe"
    ); // Replace with your Stripe publishable key
    const stripe = await stripePromise;

    if (stripe != null) {
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionData.id,
      });

      if (error) {
        console.error("Stripe Checkout error", error);
        setLoading(false);
      }
    } else {
      console.error("Stripe Checkout error");
      setLoading(false);
    }
  };



  return (
    <>
      <CodeEntryModal loading={loading} isModalVisible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOkay={handleClick} />
      <Button
        role="link"
        onClick={ () => {
          const token = localStorage.getItem("token");
          if ( !token ) {
            toast.error("Please login to continue");
            setTimeout(() => {
              navigate(`/login?fromPricing=1&amount=${amount}&currency=${currency}&pricingPeriod=${pricingPeriod}`);
            }, 2000)
            return;
          } 
          setIsModalVisible(true)
        }}
        className={cssStyle}
        disabled={loading}
      >
        {loading ? "Processing..." : title}
      </Button>
    </>
    
  );
};

const CodeEntryModal = ({
  isModalVisible, 
  onCancel, 
  onOkay,
  loading,
}: {
  isModalVisible: boolean, 
  loading: boolean, 
  onCancel: () => void,
  onOkay: (discountCode: string) => void,
}) => {
  const [code, setCode] = useState<string>('');


  const handleOk = () => {
    onOkay(code);
  };

  return (
    <>
      <Modal 
        title="Have a discount code?" 
        open={isModalVisible} 
        onOk={handleOk}
        okText= "Continue with payment"
        footer={[
          <Button
            role="link"
            onClick={handleOk}
            className={`text-white text-[0.8rem] bg-[var(--gmind-orange)]`}
            disabled={loading}
          >Proceed to Payment</Button>
        ]}
        onCancel={() => onCancel()}
      >
        <Form layout="vertical" name="codeEntryForm">
          <Form.Item
            name="code"
            label="Code"
            rules={[{ message: 'Please enter your code!' }]}
          >
            <Input 
              className="w-[80%]"
              onChange={(e) => setCode(e.target.value)} 
              value={code}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export { FlutterwareComponent, StripeComponent };
