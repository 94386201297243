import create from 'zustand';
import { fabric } from 'fabric';

interface StoreState {
    imageUrl: string;
    setImageUrl: (url: string) => void;
    canvas: fabric.Canvas | null;
    setCanvas: (canvas: fabric.Canvas) => void;
    canvasStates: string[];
    currentStateIndex: number;
    saveState: () => void;
    undo: () => void;
    redo: () => void;
}

export const useImageEditorStore = create<StoreState>((set) => ({
    imageUrl: '',
    setImageUrl: (url) => set({ imageUrl: url }),
    canvas: null,
    setCanvas: (canvas) => set({ canvas }),
    canvasStates: [],
    currentStateIndex: -1,
    saveState: () =>
        set((state) => {
            const newStates = state.canvasStates.slice(0, state.currentStateIndex + 1);
            newStates.push(JSON.stringify(state.canvas?.toJSON()));
            return { canvasStates: newStates, currentStateIndex: newStates.length - 1 };
        }),
    undo: () =>
        set((state) => {
            if (state.currentStateIndex > 0) {
                const previousState = state.canvasStates[state.currentStateIndex - 1];
                state.canvas?.loadFromJSON(previousState, () => {
                    state.canvas?.renderAll();
                });
                return { currentStateIndex: state.currentStateIndex - 1 };
            }
            return state;
        }),
    redo: () =>
        set((state) => {
            if (state.currentStateIndex < state.canvasStates.length - 1) {
                const nextState = state.canvasStates[state.currentStateIndex + 1];
                state.canvas?.loadFromJSON(nextState, () => {
                    state.canvas?.renderAll();
                });
                return { currentStateIndex: state.currentStateIndex + 1 };
            }
            return state;
        }),
}));
