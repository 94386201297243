import React from 'react';
import { Collapse, Tooltip } from 'antd';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';
import {
    LucideArrowUp,
    LucideArrowDown,
    LucideLock,
    LucideEyeOff,
    LucideRotateCcw,
    LucideRotateCw,
} from 'lucide-react';
import TextTool from './text-box';
import ShapeTool from './shape-tool';

const { Panel } = Collapse;

const RightSidebar = () => {
    const { canvas, undo, redo, saveState } = useImageEditorStore();

    const handleMoveLayer = (direction: 'up' | 'down') => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                if (direction === 'up') {
                    canvas.bringForward(activeObject);
                } else {
                    canvas.sendBackwards(activeObject);
                }
                canvas.renderAll();
                saveState();
            }
        }
    };

    const handleLockLayer = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                activeObject.lockMovementX = !activeObject.lockMovementX;
                activeObject.lockMovementY = !activeObject.lockMovementY;
                canvas.renderAll();
                saveState();
            }
        }
    };

    const handleHideLayer = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                activeObject.visible = !activeObject.visible;
                canvas.renderAll();
                saveState();
            }
        }
    };

    return (
        <div className=" bg-white text-gray-500 p-4">
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Layer Controls" key="1">
                    <Tooltip title="Move the selected layer up">
                        <button
                            className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px] mb-2"
                            onClick={() => handleMoveLayer('up')}
                        >
                            <LucideArrowUp size="14px" color="#E55109" />
                            <small>Move Up</small>
                        </button>
                    </Tooltip>
                    <Tooltip title="Move the selected layer down">
                        <button
                            className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px] mb-2"
                            onClick={() => handleMoveLayer('down')}
                        >
                            <LucideArrowDown size="14px" color="#E55109" />
                            <small>Move Down</small>
                        </button>
                    </Tooltip>
                    <Tooltip title="Lock or unlock the selected layer">
                        <button
                            className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px] mb-2"
                            onClick={handleLockLayer}
                        >
                            <LucideLock size="14px" color="#E55109" />
                            <small>Lock/Unlock</small>
                        </button>
                    </Tooltip>
                    <Tooltip title="Show or hide the selected layer">
                        <button
                            className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px] mb-2"
                            onClick={handleHideLayer}
                        >
                            <LucideEyeOff size="14px" color="#E55109" />
                            <small>Delete</small>
                        </button>
                    </Tooltip>
                </Panel>
                <Panel header="Undo/Redo" key="2">
                    <Tooltip title="Undo the last action">
                        <button
                            className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px] mb-2"
                            onClick={undo}
                        >
                            <LucideRotateCcw size="14px" color="#E55109" />
                            <small>Undo</small>
                        </button>
                    </Tooltip>
                    <Tooltip title="Redo the last undone action">
                        <button
                            className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px] mb-2"
                            onClick={redo}
                        >
                            <LucideRotateCw size="14px" color="#E55109" />
                            <small>Redo</small>
                        </button>
                    </Tooltip>
                </Panel>
                <Panel header="Text Tool" key="3">
                    <TextTool />
                </Panel>
                <Panel header="Shape Tool" key="4">
                    <ShapeTool />
                </Panel>
            </Collapse>
        </div>
    );
};

export default RightSidebar;
