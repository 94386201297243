import LoadingComponent from "@src/components/LoadingComponent";
import { ChatType } from "@src/core/interfaces/chat";
import { handleAddHistory } from "@src/pages/dashboard/endpoints";
import { prompts } from "@src/utils/prompts";
import { Checkbox, Form, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { io, Socket } from "socket.io-client";
import { v4 as uuidv4 } from 'uuid';
import MarkdownDesign from '@src/components/Markdown';
import Like from '@public/like.svg';
import DisLike from '@public/dislike.svg';
import Message from '@public/message-circle-2.svg';
import Regenerate from '@assets/repeat.svg';
import Profile from '@assets/user-circle.svg';
import { contentPromptOptionsData2 } from "../constants";
import { openEditor } from "@src/utils/app_functions";
import { useNavigate } from "react-router";
import { InputTypeProps } from "../../assessment_2/interface";
import { socialMedias } from "../../curriculum/constants";
import { BASE_URL } from "@src/utils/constants";
import { Chats } from "@src/pages/dashboard/components/interface/interface";

const ContentWriter = ({isComprehensive = true, isSocial = false }: {isComprehensive: boolean, isSocial?: boolean}) => {
    const [response, setResponse] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [description, setDescription] = useState<string>('');
    const [prompt, setPrompt] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const pageHistory = 'contents';
    const [generating, setGenerating] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [navigate, setNavigate] = useState(false);
    const [pageId, setPageId] = useState('');
    const [showRefrence, setShowReference] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<Chats>({role: 'assistant', content: ''});
    const bottomRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if ( isTyping && response != '' ){
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
        
    }, [isTyping, response]);
   


    const naviagtePage = useNavigate();

    const options = contentPromptOptionsData2({
        response,
        showDownload: () => {},
    });

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const onGenerateHandler = async (message: string, humanize = false) => {
        // console.log(isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent,);
        // return;
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setResponse('');

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent,
                            type: 'gpt-4'
                        },
                        msgs,
                    ],
                },
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            const uuid = uuidv4();
            DocumentId.current = uuid;
            const user = JSON.parse(localStorage.getItem('user') || '');
            setStreamEndMessage(data.assistant)
            setIsTyping(false);
            setDescription('');
            if (streamId === StreamId.current) {
                StreamId.current = '';
                // setStreaming(false);
              
                try {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: assistant.content,
                        value: assistant.content,
                        owner_id: user?.id,
                    });
                } catch (error) {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: assistant.content,
                        value: assistant.content,
                    });
                }
                socket.emit('get-documents', user?.id);
                setNavigate(true);
                setPageId(uuid)
            }
            
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const handleStreamEnd = async (prompt: string, data: Chats ) => {
    
        // let dataToSave: Chats[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        await handleAddHistory(dataToSave, pageHistory);
    }

    useEffect(() => {
        if ( !isTyping && response != '' ){
            handleStreamEnd(prompt, streamEndMessage);
        }
        const promptSent = isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent;
        if ( navigate && response != '' ){
            openEditor({response, pageId, promptSent: prompt, promptType: promptSent, navigate: naviagtePage});
        }
    }, [response, navigate, isTyping])
    

    const onFinish = async () => {
        if ( !description ) {
            toast.error("Enter your description"); 
            return;
        }

        let message = `Generate a Content of the topic described with this details: ${description}`
        setIsTyping(true);
        setPrompt(message);
        await onGenerateHandler(message);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    }
    const regenerate = async (humanize = false) => {
        let newPrompt = prompt;
        setPrompt(newPrompt);
        await onGenerateHandler(newPrompt, humanize);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    }

    return (
        <div className="w-full px-0 md:px-5 pt-10 bg-white flex flex-col md:flex-row gap-x-10 gap-y-3">
            <div className={`${isMobile ? !isTyping ? 'flex': 'hidden' : 'flex' } flex-col gap-y-3 h-[50vh] md:h-[25.875rem] w-full md:w-[26.438rem] rounded-[0.5rem] px-10 py-5 shadow-2xl`}>
                <p className="font-medium text-[1rem] text-[var(--gmind-light-black)]">Content description</p>
                <Form layout="vertical" className='flex flex-col flex-wrap gap-x-5' onFinish={onFinish}>
                    <Form.Item
                        className='w-full mb-1'
                        name={'description'}
                        rules={[
                            {
                                message: 'field is required',
                                required: false,
                            },
                        ]}
                    >
                        <Input.TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className='w-full'
                            placeholder="E.g What is Osmosis E.g Rewrite in short sentence E.g Generate a resume with 5 years experience." 
                            style={{
                                border: '1px solid #E5E8F1',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                            rows={isMobile ? 4 : 8}
                        />
                    </Form.Item>


                    {
                    isComprehensive && !isSocial && <div className="w-full items-center justify-between flex gap-x-2 my-3">
                        <p className="font-medium text-[0.75rem]">Show References</p>

                        <div className="flex items-center gap-x-2">
                            {/* <p className="font-medium text-[0.75rem]">Yes / No </p> */}
                            <Checkbox
                                value={showRefrence? true : false}
                                onChange={(e) => setShowReference(!showRefrence)}
                                className='' 
                                style={{
                                    border: '1px solid #E5E8F1',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="checkbox"
                            />

                            {/* <p className="font-medium text-[0.75rem]">No</p>
                            <Checkbox
                                value={!showRefrence ? true: false}
                                onChange={(e) => setShowReference(e.target.value)}
                                className='' 
                                style={{
                                    border: '1px solid #E5E8F1',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="checkbox"
                            />   */}
                        </div>
                        
                    </div>
                    }

                    {/* <Form.Item
                        className='w-full flex flex-row gap-x-4 mb-0'
                        name={'description'}
                        label={
                            <label className="w-[30%]">Show references</label>
                        }
                    >
                        
                    </Form.Item> */}

                    <button disabled={isTyping} type="submit" className="my-5 text-white transcribe">
                        Generate
                    </button>
                </Form>
            </div>
            <div className="w-full md:w-[46.563rem] flex flex-col gap-y-3">
                <div ref={bottomRef} className={`${response ? 'overflow-scroll': ''} px-10 py-5 h-[25.875rem] w-full md:w-[46.563rem] rounded-[0.5rem] p-3 shadow-2xl`}>
                    {
                        !generate ? (
                            <div className="mt-5 h-full flex-col flex items-center justify-between">
                                <div className="flex flex-col gap-y-3">
                                    <p className="text-center font-medium text-[1.5rem] text-[var(--gmind-black)]">Create Content</p>
                                    <p className="text-center text-[1rem] text-[var(--gmind-light-black25)] font-normal">Generate content for course</p>
                                </div>

                                <p className="mb-10 text-center text-[1rem] text-[var(--gmind-light-black25)] font-normal">Enter content description & click Generate to get started</p>
                            </div>
                        ):(
                            <div>
                                {generating ? 
                                    (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <MarkdownDesign className="">{response}</MarkdownDesign>
                                    )
                                }

                            </div>
                        )
                    }
                </div>
                {/* <div id="controls" className="w-full flex items-center justify-between">
                    <div className="flex gap-x-5 w-[50%] justify-start">
                        <div role="button" onClick={() => regenerate(true)} className="flex items-center">
                            <img src={Profile} alt="" />
                            <p>Humanize</p>
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={option.onClick}
                                className="rounded-[1rem] px-[1rem] py-[0.3rem] bg-[var(--gmind-header)] flex gap-x-3 cursor-pointer"
                            >
                                <img className='w-[1rem] h-[1rem]' src={option.icon} alt="" />
                                <div className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]">
                                    {option.name}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex gap-x-5 w-[50%] justify-end">
                        <div
                            className="hidden md:flex items-center flex-row"
                            style={{ cursor: 'pointer' }}
                            onClick={() => regenerate()}
                        >
                            <img src={Regenerate} alt="" />{' '}
                            <span
                                className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]"
                                style={{ marginLeft: '2px' }}
                            >
                                Regenerate
                            </span>
                        </div>
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Message} alt="" />
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Like} alt="" />
                        <img
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={()=> {}}
                            src={DisLike}
                            alt=""
                        />
                    </div>
                </div> */}
            </div>

        </div>
    )
}

export default ContentWriter;