import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router';
import { planAmountDetails } from '@src/core/interfaces/general';
import { toast } from 'react-toastify';
import { BASE_URL } from '@src/utils/constants';
import { getRegion } from '@src/utils/app_functions';
import { Button as CustomButton } from "@src/pages/acquisition/pricing/components/ui/button";

interface expiredModalProps {
    isVisible: boolean;
    packageName: string;
    amountDetails?: planAmountDetails;
}

const ExpiredPackageModal = ({isVisible, packageName, amountDetails}: expiredModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(isVisible);
  const [planInterval, setPlanInterval] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if ( amountDetails ){
        setPlanInterval(` ${amountDetails.interval}`); 
    }
  }, [amountDetails])

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible])

  const handleRenew = async () => {
    if ( !amountDetails ) return;
    const token = localStorage.getItem("token");
    if ( !token ){
        toast.error("Please login to continue");
        return;
    } 

    setLoading(true);

    const baseurl = BASE_URL;

    let currency = (await getRegion()).location;

    let amount = currency == 'usd' ? amountDetails.amountInUSD : amountDetails.amountInUSD;

    let finalUrl = currency == 'usd' ? `${baseurl}/user/create-checkout-session` : `${baseurl}/user/create-checkout-flutterwave`;


    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    
    const checkoutUrl = `${protocol}//${hostname}/dashboard/payment-success`;
    const cancelUrl = `${protocol}//${hostname}/dashboard/pricing`;
    
    console.log('Checkout URL:', checkoutUrl);
    console.log('Cancel URL:', cancelUrl);


    const res = await fetch(finalUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount, currency, checkoutUrl, cancelUrl, repayment: amountDetails.interval }),
    });
    

    const session = await res.json();

    if ( session.statusCode != 1 ){
      toast.error(session.message);
      setLoading(false);
      return;
    }

    setLoading(false);

    const sessionData = session.data;

    window.location = sessionData.url;
  };

  const handleUpgrade = () => {
    navigate('/dashboard/pricing');
  };
  return (
    <div>
      <Modal
        title={<h2 className='text-center text-[0.9rem]' >{`Your Gmind ${packageName}${planInterval} plan has expired`}</h2>}
        open={isModalVisible}
        onOk={handleRenew}
        onCancel={() => {}}
        closable={false}
        maskClosable={false}
        width={500}
        footer={[
            <Button key="ok" type="primary" className='bg-[#75e900] text-white' onClick={handleUpgrade}>
                Upgrade Plan
            </Button>,
            amountDetails && (
                <CustomButton key="ok" disabled={loading} variant={'outline'} className='bg-[var(--gmind-orange)] text-white'  onClick={handleRenew}>
                    Renew
                </CustomButton>
            )
        ]}
      >
        <p>Renew now to continue enjoying uninterrupted access to all your favorite features. Don't miss out—renew today and stay connected!</p>
      </Modal>
    </div>
  );
};

export default ExpiredPackageModal;