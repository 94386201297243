/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import DashboardFrame from '@src/components/DashboardFrame';
import './style.css';
import toPlaintext from 'quill-delta-to-plaintext';

import { useEffect, useRef, useState } from 'react';
import {
    AddComment,
    GetAllComments,
    GetDocument,
    GetVersionHistories,
    UpdateDocument,
} from '@src/core/endpoints/essay';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import moment from 'moment';
import TopNavMobile from '@src/components/Top/mobile';
import share from '@assets/chat/share.svg';
import copy from '@assets/chat/copy.svg';
import { pdfExporter } from 'quill-to-pdf';
import download from '@assets/chat/download.svg';
import { toast } from 'react-toastify';
import { Socket, io } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { SubNavBar } from './SubNav';
import axios from 'axios';
import TopNavBar from '@src/components/Top';

import { saveAs } from 'file-saver';
import { Share } from '@src/pages/dashboard/components/Share';
import { Download } from '@src/pages/dashboard/components/Share/Download';
import markdownToDelta from 'markdown-to-quill-delta';
import { GiShipWheel } from 'react-icons/gi';
import Like from '@public/like.svg';
import DisLike from '@public/dislike.svg';
import Regenerate from '@assets/repeat.svg';
import SunEditorComponent from '../sun_editor';
import { marked } from 'marked';
import { ChatType } from '@src/core/interfaces/chat';
import onGenerateFromEditor from '@src/pages/dashboard/function';
import { Document as DocumentContainer, Page } from 'react-pdf';
import { DisLikeImage, LikedImage } from './svgs';
import html2md from 'html-to-md'
import { copyData, handleTextReplace, isHtmlString, replaceImageElement } from '@src/utils/app_functions';
import { convertHTMLToPDF, convertHTMLToWord, onRegenerateConfirm } from '../functions';
import { prompts } from '@src/utils/prompts';


type DataType<T> = {
    title: T;
    content: T | any;
    id: T;
};

const EditorPage = () => {
    const [searchParams] = useSearchParams();
    const [editorContent, setEditorContent] = useState<any>('');
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [socket, setSocket] = useState<Socket | null>(null);
    const [edit, setEdit] = useState<boolean>(true);
    const [comments, setComments] = useState<DataType<string>[]>([]);
    const [hasAccess, setHasAccess] = useState(false);
    const [middleOption, setMiddleOption] = useState('Comments');
    const [currentData, setCurrentData] = useState<DataType<string>[]>([]);
    const [right, setRight] = useState<{ name: string; placeholder: string; date?: number }>({
        name: '',
        placeholder: '',
        date: Date.now(),
    });
    const token = localStorage.getItem('token');
    const UrlRef = useRef<string>('');
    const [streamEnded, setStreamEnded] = useState(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [versionHistories, setVersionHistories] = useState<DataType<string>[]>([]);
    const userStorage = localStorage.getItem('user');
    const user = userStorage ? JSON.parse(userStorage) : { id: '' };
    const documentId = searchParams.get('document');
    const [translated, setTranslated] = useState<boolean>(true);
    const Document = useRef<any>();
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState(true);
    const [mount, setMount] = useState(false);
    const navigate = useNavigate();
    // Humanize
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [humanizing, setHumanizing] = useState(false);
    const [editorData, setEditorData] = useState('');
    const [pageFrom, setPageFrom] = useState<string | null>(null);
    const [pageId, setPageId] = useState<string>('');
    const [showContent, setShowContent] = useState(false);
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [response, setResponse] = useState<string>('');
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [isOwner, setIsOwner] = useState(false);
    const [isLiked, setIsLike] = useState(false);
    const [disLike, setDisLike] = useState(false);
    const [replacedText, setReplacedText] = useState<string[]>([]);
    const [canTranslate, setCanTranslate] = useState(false);
    const [canHumanize, setCanHumanize] = useState(false);
    const [pdfDownloading, setPdfDownloading ] = useState(false);
    const [wordDownloading, setWordDownloading ] = useState(false);

    const continueRegenrating = () => {
        const promptData = localStorage.getItem('prompt_data');
        const jsonData = promptData ? JSON.parse(promptData) : null;
        const promptType = jsonData ? jsonData.promptType: prompts.chat;
        const replaceData = replaceImageElement(editorContent);

        const contentToRegenerate = html2md(replaceData.replacedText);

        const extractedDivs = replaceData.extractedDivs;

        let newData = '';
        extractedDivs.forEach((element) => {
            newData = `${element}\n`;
        })

        setResponse(newData);

        onGenerateFromEditor(socket, contentToRegenerate, promptType,);
    }

    const regenrate = async () => {
        onRegenerateConfirm({
            onConfirm: continueRegenrating
        }); 
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const pageDetails = queryParams.get('fromPage');
        const pageId = queryParams.get('document');
        setPageFrom(pageDetails);
        setPageId(pageId ?? '');
    }, []);

    useEffect(() => {
        let canUseTranslator = ( user.plan && user.plan?.restrictions ) ?  user.plan.restrictions.contentTranslator: ( user.freeTrialAvailable);
        let canUseHumanizer = ( user.plan && user.plan?.restrictions ) ? user.plan.restrictions.canHumanize : ( user.freeTrialAvailable);

        setCanTranslate(canUseTranslator);
        setCanHumanize(canUseHumanizer);
    }, [])

    useEffect(() => {
        const Socket = io(BASE_URL.slice(0, -2));
        setSocket(Socket);

        const essayDocument = localStorage.getItem('essay_data');

        if (!essayDocument) {
            getDocument()
                .then((d) => {
                    console.log(d, 'is d');
                    setEditorData(d?.data ?? '');
                })
                .catch((e) => {
                    console.log('error is', e);
                });
        }
        setEditorData(essayDocument ?? '');
        setEditorContent(essayDocument ?? '');

        !essayDocument && getDocument();
        !essayDocument && getVersionHistories();
        setMount(true);
    }, []);

    async function getDocument() {
        try {
            const response = await GetDocument(token, documentId);
            if (response.statusCode !== 1) {
                setHasAccess(false);
                console.error('Something went wrong while getting documents');
                return;
            }
            if (response?.owner) {
                setIsOwner(true);
                setHasAccess(true);
            } else if (response?.hasAccess) {
                setHasAccess(true);
            }
            Document.current = response.data;
            return response.data;
        } catch (error) {
            console.log(error, 'is an getdoc error');

            return { error };
        }
    }

    const handledLike = () => {
        setIsLike(!isLiked);
    };

    const handledDisLike = () => {
        setDisLike(!disLike);
    };

    const handleRefetchUnevenInitilDocumentFetch = async () => {
        if (!Document?.current) {
            await getDocument();
        }
    };

    async function getVersionHistories() {
        const response = await GetVersionHistories(token, documentId);

        if (response.statusCode !== 1) {
            console.log("Can't get version histories due to a problem");
            return;
        }

        setVersionHistories(response.data);
        return response.data;
    }

    function handleSelectedVersion(id: string) {
        const filtered = versionHistories.filter((v) => v.id === id);
        if (!filtered) return;
        setHumanizedContent('');
        setTranslatedContent(filtered[0].content.ops);
    }

    async function handleShare() {
        await handleRefetchUnevenInitilDocumentFetch();
        let data = '';

        if ( isHtmlString(editorContent) ){
            data = html2md(editorContent);
        }else{
            data = editorContent;
        }
        // const data = Document.current?.data;

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        const response = await axios.post(`${BASE_URL.replace('/v', '')}/document/markdown-to-doc`, { content: data }, {});

        const { data: d } = response;
        if (d?.statusCode === 1) {
            const url = `${BASE_URL.replace('/v', '')}/${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        }
    }

    async function handleCopy() {
        const replaceData = replaceImageElement(editorContent);

        const contentToCopy = html2md(replaceData.replacedText);

        copyData(contentToCopy)
    }

    async function handlePDF() {
        let data = '';

        if ( isHtmlString(editorContent) ){
            data = editorContent;
        }else{
            data = await marked( editorContent );
        }

        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(data);
        } catch (error) {
            toast.error("Error downloading documents");
        }finally {
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        let to_html = '';

        if ( isHtmlString(editorContent) ){
            to_html = editorContent;
        }else{
            to_html = await marked(editorContent);
        }

        try {
            setWordDownloading(true);
            await convertHTMLToWord(to_html);
        } catch (error) {
            toast.error("Error downloading documents");
        }finally {
            setWordDownloading(false);
        }

        // const response = await axios.post(`${BASE_URL.slice(0, -3)}/html-to-doc`, { content: to_html }, {});
        // const { data: d } = response;
        // if (d?.statusCode === 1) {
        //     const link = document.createElement('a');
        //     link.href = `${BASE_URL.slice(0, -3)}/${d?.url}`;
        //     link.download = 'document.docx';
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //     toast.success('Download successful');
        // }
    }

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; messages: ChatType[] }) => {
            const { streamId, messages } = data;
            if (streamId === StreamId.current) {
                StreamId.current = '';
                let id = pageId;
                DocumentId.current = pageId;
                const user = JSON.parse(localStorage.getItem('user') || '');
                
                socket.emit('get-documents', user?.id);
            }

            setIsRegenerating(false);
        });

        


        return () => {
            socket.off('message');
        };
    }, [socket]);

    useEffect(() => {
        if (response != '') {
            setEditorData(response);
        }
    }, [response]);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    async function TranslateContent(content: string, target: string, textsToReplace: string[] ) {
        try {
            const response = await axios.post(`${BASE_URL}/translator`, { content, target }, {});

            const { data } = response;
            setHumanizedContent('');
            let translatedContent = handleTextReplace(data?.data, textsToReplace);
            // console.log(convertToMarkdownFormat(translatedContent))
            setTranslatedContent(translatedContent);
            setEditorContent(translatedContent);
            return data;
        } catch (error: any) {
            toast.error(error);
            return 0;
        }
    }

    async function handleTranslate(language: string) {
        setTranslated(false);
        let contentToTranslate = '';
        let textsToReplace: string[] = [];
        
        if ( isHtmlString(editorContent) ){
            const replaceData = replaceImageElement(editorContent);
            contentToTranslate = html2md(replaceData.replacedText);
            textsToReplace = replaceData.extractedDivs;
        }else{
            contentToTranslate = editorContent; 
        }
       
        const translated_response = await TranslateContent(contentToTranslate, language, textsToReplace);
        if (translated_response?.statusCode !== 1) {
            toast.error('Failed to translate: ' + translated_response?.message);
            setTranslated(true);
            return;
        }
        setTranslated(true);
        toast.success('Content Translated');
    }

    async function humanizeContent(content: string, textsToReplace: string[]) {
        try {
            const response = await axios.post(`${BASE_URL}/gmind/humanize`, { content }, {});
            const { data } = response;
            let humanizedContent = handleTextReplace(data?.data, textsToReplace);
            setHumanizedContent(humanizedContent);
            // set the editor content after humanizing
            setEditorContent(humanizedContent);
            return data;
        } catch (error: any) {
            toast.error(error);
            return 0;
        }
    }

    async function handleHumanize() {
        setHumanizing(true);
        let contentToHumanize = '';
        let textsToReplace: string[] = [];

        if ( isHtmlString(editorContent) ){
            let replaceData = replaceImageElement(editorContent);
            contentToHumanize = html2md(replaceData.replacedText);
            textsToReplace = replaceData.extractedDivs;
        }else {
            contentToHumanize = editorContent;
        }
        

        const response = await humanizeContent(contentToHumanize, textsToReplace);
        if (response?.statusCode !== 1) {
            toast.error('Failed to humanize: ' + response?.message);
            setHumanizing(false);
            return;
        }
        setHumanizing(false);
        toast.success('Content Humanized');
    }

    function getComments() {
        GetAllComments(token, documentId)
            .then((d) => {
                if (d?.statusCode === 1) {
                    const Comments = d?.data?.comments as any[];
                    const formatted = Comments.map((c) => {
                        return {
                            title: c.highlightedText,
                            content: c.comment,
                            id: c._id,
                            date: moment(c.updatedAt).format('HH:mm DD-MM-YYYY'),
                        };
                    });
                    setComments(formatted as { title: string; content: string; id: string }[]);
                    setCurrentData(formatted);
                }
            })
            .catch((e) => {
                notification.error({ message: 'Something went wrong: ' + e?.message });
            });
    }

    function handleMiddleOption(v: string, setContent = true) {
        setMiddleOption(v);
        if (setContent) {
            setShowContent(true);
        }

        switch (v) {
            case 'Comments':
                if (v === right.name) {
                    setRight({ name: '', placeholder: '', date: Date.now() });
                }
                setTimeout(() => {
                    setRight((curr) => {
                        return { ...curr, name: 'Comments', placeholder: 'Be the first to add a comment!' };
                    });
                    setCurrentData(comments);
                }, 10);
                break;
            case 'Resources':
                setRight((curr) => {
                    return {
                        ...curr,
                        name: 'Resources',
                        placeholder: 'No resources yet. Add a resource.',
                        date: Date.now(),
                    };
                });
                setCurrentData([]);
                break;

            case 'Version History':
                getVersionHistories().then((d) => {
                    if (d) {
                        const formatted = d.map(({ title, content, id }: any) => {
                            const plainText = toPlaintext(content.ops);
                            return {
                                title,
                                id,
                                content: plainText,
                            };
                        });
                        setCurrentData(formatted);
                    }
                });
                setRight((curr) => {
                    return { ...curr, name: 'Version History', placeholder: 'Start by adding a version history' };
                });
                break;

            case 'History':
                getVersionHistories().then((d) => {
                    if (d) {
                        const formatted = d.map(({ title, content, id }: any) => {
                            const plainText = toPlaintext(content.ops);
                            return {
                                title,
                                id,
                                content: plainText,
                            };
                        });
                        setCurrentData(formatted);
                    }
                });
                setRight((curr) => {
                    return { ...curr, name: 'History', placeholder: 'Start by adding a version history' };
                });
                break;

            default:
                !isMobile &&
                    setRight((curr) => {
                        return { ...curr, name: 'Comments', placeholder: 'Be the first to add a comment!' };
                    });
                setCurrentData(comments);
                break;
        }
    }

    async function handleCommentSubmission(text: string, range: any) {
        const response = await AddComment(token, {
            documentId,
            userId: user?.id,
            comment: text,
            index: range?.index ?? '',
            length: range?.length ?? '',
            highlightedText: range?.highlightedText,
        });

        if (response?.statusCode === 1) {
            notification.success({ message: response?.message });
            getComments();
        }
    }

    return (
        <DashboardFrame
            canNavigateHome={true}
            showMobileHistory={false}
            showRightComponent={false}
            rightComponentName={right.name}
            rightComponentPlaceholder={right.placeholder}
            onDeleteHistory={(id) => {}}
            rightComponentData={currentData}
            middleClass="w-screen bg-gray-100 fixed"
            selectedHistory={(v) => {}}
            rightComponentClick={(id: string) => {
                handleSelectedVersion(id);
            }}
            showHistory={false}
            showSidebar={false}
            showTop={false}
            historyVisible={showContent}
            onCloseHistory={() => setShowContent(false)}
            
        >
            { !isMobile && <TopNavBar
                canNavigateHome={true}
                sidebarCollapse={false}
                isMobile={isMobile}
                showHistoryOption={false}
                breadCrumbPage={pageFrom ?? undefined}
            /> }
            {isMobile && (
                <div className="sticky top-0 z-[200]">
                    <TopNavMobile
                        title="Editor"
                        onBack={() => {
                            if (pageFrom == null) {
                                navigate('/dashboard/article');
                            } else {
                                navigate(-1);
                            }
                        }}
                        options={['Comments', 'Resources']}
                        selectedOption={(option: string) => {
                            if (option === right.name) {
                                setRight(() => {
                                    return { name: '', placeholder: '' };
                                });
                            }
                            setMiddleOption('');
                            handleMiddleOption(option);
                        }}
                        selected={(language: string) => handleTranslate(language)}
                        showActions={true}
                        canHumanize={canHumanize}
                        canTranslate={canTranslate}
                        loading={translated}
                        onHumanizeClick={() => {
                            handleHumanize();
                        }}
                        humanized={!humanizing}
                    />
                </div>
            )}

            {!isMobile && edit && (
                <SubNavBar
                    onHumanizeClick={() => {
                        handleHumanize();
                    }}
                    // showActions={isOwner}
                    showActions={true}
                    humanized={!humanizing}
                    onBack={() => {
                        console.log('back');
                        if (pageFrom == null) {
                            navigate('/dashboard/article');
                        } else {
                            navigate(-1);
                        }
                    }}
                    canHumanize={canHumanize}
                    canTranslate={canTranslate}
                    className="mx-40"
                    loading={translated}
                    title={Document.current?.title?.slice(33)}
                    selected={(language: string) => handleTranslate(language)}
                />
            )}

            <div className="mx-0 md:mx-40 h-full">
                <SunEditorComponent
                    // humanizeded_content={Document.current?.data.ops}
                    // hasAccess={hasAccess}
                    hasAccess={true}
                    initial_content={editorData}
                    humanized_content={humanizedContent}
                    translated_content={translatedContent}
                    onChangeEditorContent={(e: string) => {
                        setEditorContent(e);
                    }}
                   
                />
                {/* <TextEditor
                    humanized_content={humanizedContent}
                    translated_content={translatedContent}
                    isMobile={isMobile}
                    submitCommentButton={(v, obj) => {
                        handleCommentSubmission(v, obj);
                    }}
                    onHighlightingText={(e) => {}}
                    onCommentTextChange={(e) => {}}
                    MiddleComponent={
                        <Middle isMobile={isMobile} selectedOption={(v: string) => handleMiddleOption(v)} />
                    }
                    EndComponent={
                        <div className="flex">
                            <button className="bg-[var(--gmind-orange)] rounded-[0.45rem] px-3 py-1 font-normal text-[0.8rem] text-white">
                                    Paraphrase
                                </button>
                            <button
                                onClick={() => (humanizing ? null : handleHumanize())}
                                className={`${humanizing ? 'bg-[#f2f2f2] text-[#000000]' : 'bg-[var(--gmind-orange)] text-white'} rounded-[0.45rem] px-3 py-1 font-normal text-[0.8rem] `}
                            >
                                {humanizing ? 'Humanizing' : 'Humanize'}
                            </button>
                            {humanizing && <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />}
                        </div>
                    }
                /> */}
                <div
                    style={
                        isMobile
                            ? { position: 'fixed', justifyContent: 'space-between', padding: '0 1rem' }
                            : { padding: '0 1rem', borderTop: '1px solid #f2f2f2' }
                    }
                    className="gap-4 flex items-center bg-white sticky bottom-0 left-0 right-0"
                >
                    <div className="max-md:justify-between w-full flex items-center gap-4 p-[5px]">
                        <div className='flex gap-x-5 w-[75%]'>
                            <button
                                onClick={handleShare}
                                className="flex items-center gap-2 text-xs bg-gray-200 rounded-xl p-1 px-3"
                                type="button"
                            >
                                <img className="w-3" src={share} alt="share" />
                                <span>Share</span>
                            </button>
                            <button
                                onClick={() => {
                                    handleRefetchUnevenInitilDocumentFetch();
                                    setShowDownload(true);
                                }}
                                className="flex items-center gap-2 text-xs bg-gray-200 rounded-xl p-1 px-3"
                                type="button"
                            >
                                <img className="w-3" src={download} alt="download" />
                                <span>Export</span>
                            </button>
                        </div>
                        <div className="flex gap-x-5 w-[20%] justify-end">
                            <div
                                className="hidden md:flex items-center flex-row"
                                style={{ cursor: 'pointer' }}
                                onClick={() => regenrate()}
                            >
                                <img src={Regenerate} alt="" />{' '}
                                <span
                                    className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]"
                                    style={{ marginLeft: '2px' }}
                                >
                                    Regenerate
                                </span>
                                {isRegenerating && (
                                    <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />
                                )}
                            </div>
                            {/* <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Message} alt="" /> */}
                            {isLiked ? (
                                <LikedImage onClick={handledLike} />
                            ) : (
                                <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        handledLike();
                                    }}
                                    src={Like}
                                    alt=""
                                />
                            )}

                            {disLike ? (
                                <DisLikeImage onClick={handledDisLike} />
                            ) : (
                                <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        handledDisLike();
                                    }}
                                    src={DisLike}
                                    alt=""
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
            {showDownload && (
                <Download
                    handlePDF={handlePDF}
                    handleWord={handleWord}
                    url={UrlRef.current}
                    onClose={() => setShowDownload(false)}
                    isPdfDownloading={pdfDownloading}
                    isWordDownloading={wordDownloading}
                />
            )}
        </DashboardFrame>
    );
};

const Middle = ({ selectedOption, isMobile }: { selectedOption: (v: string) => void; isMobile?: boolean }) => {
    const [selected, setSelected] = useState('');

    // useEffect(() => {
    //     const editor_middle_selected = JSON.parse(localStorage.getItem('editor_middle_selected') ?? '[]');

    //     if (editor_middle_selected) {
    //         setSelected(editor_middle_selected.name);
    //     }
    // }, []);

    useEffect(() => {
        !isMobile && selectedOption(selected);
    }, [selected]);
    return (
        <section>
            {!isMobile && (
                <ul role="button" className="list-none flex items-center gap-2 gap-x-5 relative right-4">
                    <li
                        onClick={() => setSelected('Comments')}
                        style={selected === 'Comments' ? { color: '#ed5405' } : {}}
                        className="text-xs"
                    >
                        Comments
                    </li>
                    <li
                        onClick={() => setSelected('Resources')}
                        style={selected === 'Resources' ? { color: '#ed5405' } : {}}
                        className="text-xs"
                    >
                        Resources
                    </li>
                    {/* <li
                        onClick={() => setSelected('Version History')}
                        style={selected === 'Version History' ? { color: '#ed5405' } : {}}
                        className="text-xs"
                    >
                        Version History
                    </li> */}
                </ul>
            )}
        </section>
    );
};

export default EditorPage;
