import { MySmallLogoIcon } from '@src/components/Svg';

interface PromptResponseProps {
    promptResponse: JSX.Element;
}

const ResponseTextBox = ({ promptResponse }: PromptResponseProps) => {
    return (
        <div className="w-full py-[1.5rem] gap-y-5" style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="flex items-center justify-between w-full">
                <MySmallLogoIcon />
            </div>
            <div
                className="text-[0.8rem] pr-5 font-normal text-[var(--gmind-light-black)] text-container"
                style={{
                    fontFamily: 'Inter',
                    overflow: 'hidden',
                    maxHeight: '28rem',
                    height: '25rem',
                }}
            >
                {promptResponse}
            </div>
        </div>
    );
};

export default ResponseTextBox;
