import React, { useState, useRef } from 'react';
import { Play, Pause, PauseCircle, PlayCircle } from 'lucide-react';
import { SoundModelType } from '.';
import useSpeechSynthesizerStore from '@src/providers/useSpeechSynthesizerStore';

interface AudioCardProps {
    model: SoundModelType;
}

const AudioCard: React.FC<AudioCardProps> = ({ model }) => {
    const { voiceModel, setVoiceModel } = useSpeechSynthesizerStore();
    const isSelected = voiceModel === model.name;
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleCardClick = () => {
        setVoiceModel(model.name);
    };

    const togglePlayPause = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div
            className={`border ${isSelected ? 'bg-[#E4ECFD]' : 'bg-[#f9f9f9]'} border-[#E4ECFD] rounded-[12px] w-full md:w-[200px] max-h-[96px] flex items-center p-2 cursor-pointer justify-between`}
            onClick={handleCardClick}
        >
            <img src={model.avatar_img} alt={model.name} className="w-[48px] h-[48px] rounded-lg mr-2" />
            <div>
                <div className="text-[18px] font-bold ml-[-20px]">{model.title}</div>
                <div className="text-[13px] text-gray-600 ml-[-20px]">{model.gender}</div>
            </div>
            <button onClick={togglePlayPause} className="justify-end items-end text-orange-500">
                {isPlaying ? <PauseCircle size={34} color="#E55109" /> : <PlayCircle size={34} color="#E55109" />}
            </button>
            {model.sound_url && <audio ref={audioRef} src={model.sound_url} onEnded={() => setIsPlaying(false)} />}
        </div>
    );
};

export default AudioCard;
