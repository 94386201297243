import React from 'react';
import { Modal, Button, Input } from 'antd';
import { toast } from 'sonner';
const { TextArea } = Input;

interface CommentModalProps {
    isOpen: boolean;
    onClose: () => void;
}
function comment() {
    toast.success('Comment added sucessfully');
    // onclose();
}

const CommentModal: React.FC<CommentModalProps> = ({ isOpen, onClose }) => {
    return (
        <Modal title="Add Comment" open={isOpen} onCancel={onClose} footer={null} width={300}>
            <div className=" dark:bg-gray-950 rounded-md  p-3 space-y-4">
                <div className="flex flex-col items-center gap-3">
                    <TextArea
                        placeholder="Write your comment here..."
                        className="flex-1 rounded-md border border-gray-300 dark:border-gray-700 p-3 text-sm focus:outline-none focus:ring-2 focus:ring-[#E55109] dark:bg-gray-800 dark:text-gray-200"
                    />
                    <button
                        onClick={comment}
                        className="bg-[#E55109] hover:bg-[#c74608] text-white font-medium rounded-md px-4 py-2 text-sm text-center"
                    >
                        Send
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default CommentModal;
