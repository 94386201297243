/* eslint-disable @typescript-eslint/no-unused-vars */
import Login from "@src/pages/acquisition/login/components/Login";
import Header from "../components/header";
import { useNavigate } from "react-router";
import ExampleCard from "@src/components/example_card";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";

export default function LoginPage() {
    const navigatePage = useNavigate();
    
    return (
        <div className="w-full flex flex-col h-screen bg-white gap-y-5 py-5 px-5 md:px-20">
            <Helmet>
                <title>Login</title>
                <meta title="GMIND Ai: Best AI Assistant for TEACHERS and CONTENT CREATORS" name="description" content="Login to Gmind AI to enjoy the ultimate AI tool for transforming educational content generation." />
                <link rel="canonical" href="https://gmind.ai/login" />
                <meta name="keywords" content="gmind, ai, chat" />
            </Helmet>
            <div className="flex flex-col md:flex-row items-center justify-between">
              <Login 
                handleForgetPasssword={() => navigatePage("")}
                handleCreateAccount={() => navigatePage("/signup")}
              /> 
              <ExampleCard /> 
            </div>
            <ToastContainer />
        </div>
    )
}
