/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from 'react';
import cancel from '@assets/cancel.svg';
import search from '@assets/search.svg';
import type { ChatType, HistoryTypeFormat } from '@src/core/interfaces/chat';
import { dateFormat, extractAssistantData, handleRoute } from '@src/utils/app_functions';
import { dictionary, reverseDictionary } from '@src/utils/constants';
import right from '@assets/right_gray.svg';
import deleteImage from "@icons/delete.svg";
import { IoMdMore } from 'react-icons/io';

/* eslint-disable @typescript-eslint/no-unused-vars */

interface HistoryType {
    click: (v: string) => void;
    data: HistoryTypeFormat[];
    name?: string;
    className?: string;
    date?: string;
    style?: React.CSSProperties;
    onClose?: () => void;
    hidden?: boolean;
    topChild?: ReactNode;
    closeId?: string;
    onDeleteHistory: (id: string) => void;
    onDeleteAllHistory: () => void;
}

interface openSidebarValues {
    id: string;
    value: boolean;
}

const HistoryModal = ({ click, data, name = 'History', className, onClose, date, style, hidden, topChild, closeId, onDeleteHistory, onDeleteAllHistory }: HistoryType) => {
    const [collapse, setCollapse] = useState(!hidden);
    const [Name, setName] = useState(name);
    const [paths, setPaths] = useState<any[]>([]);
    const [key, setKey] = useState(0);
    const [histories, setHistories] = useState<HistoryTypeFormat[]>(data);
    const [optionOpened, setOpenOption] = useState<Map<string, boolean>>(new Map());

    useEffect(() => {
        setHistories(data);
    }, [data])

    useEffect(() => {
        generateAllOptionValue();
    }, [histories]);

    const getSideBarValue = (id: string): boolean => {
        return optionOpened.get(id) || false;
    };

    const setSideBarValue = (id: string) => {
        setOpenOption((prevOptionOpened) => {
            const newOptionOpened = new Map(prevOptionOpened);
            newOptionOpened.set(id, !newOptionOpened.get(id));
            return newOptionOpened;
        });
    };


    const generateAllOptionValue = () => {
        const openSideValues = new Map<string, boolean>();
        histories.forEach((element) => {
            element.histories.forEach((values) => {
                const historyData = extractAssistantData(values);
                if (historyData) {
                    openSideValues.set(historyData.id, false);
                }
            });
        });
    
        setOpenOption(openSideValues);
    };

    const refresh = () => {
        setKey(prevKey => prevKey + 1);
        // setHistories(data);
    };

    const onSearch = (input: string) => {
        if ( input == '' ){
            setHistories(data);
            return;
        }

        const filtered = data.map((historyFormat) => {
            const filteredHistories = historyFormat.histories.filter((chatArray) =>
                chatArray.some((chat) => chat.content.includes(input))
            );
    
            return {
                ...historyFormat,
                histories: filteredHistories,
            };
        }).filter(historyFormat => historyFormat.histories.length > 0);

        setHistories(filtered);
    }

    useEffect(() => {
        setCollapse(!hidden);
    }, [hidden]);

    useEffect(() => {
        console.log(data);
    }, [data]);

    useEffect(() => {
        const { pathname } = window.location;
        const split = pathname.split('/').slice(2);
        const formatPaths = split.map((path: string) => dictionary[path]);
        setPaths(formatPaths);
        console.log(formatPaths);
    }, [window.location.pathname]);

    function handleClick(id: string) {
        setCollapse(false);
        onClose && onClose();
        click(id);
    }

    const Data: ChatType[][] = [
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '1' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '2' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '3' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '4' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '5' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '6' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '7' },
        ],
    ];

    return (
        <section
            key={key}
            style={
                collapse
                    ? { userSelect: 'none', opacity: '0', zIndex: '-1', ...style }
                    : { userSelect: 'auto', opacity: '1', ...style }
            }
            className={
                'bg-white transition-all fixed left-auto inset-0 w-screen h-screen flex flex-col gap-6 z-[500] p-5 px-169 ' +
                className
            }
        >
            <button
                id={closeId ?? ''}
                className="ms-auto block"
                onClick={() => {
                    setCollapse(!collapse);
                    onClose && onClose();
                }}
                type="button"
            >
                <img className="w-6" src={cancel} alt="cancel" />
            </button>
            <div className="pl-10 md:pl-[15%] pr-10 md:pr-[15%] w-full flex flex-col gap-6">
                <div className="flex items-center gap-x-3">
                        <p
                            style={paths.length === 0 ? { fontWeight: '600' } : { fontWeight: '400' }}
                            role="button"
                            onClick={() => handleRoute('/dashboard/chat')}
                            className="m-0 text-xs"
                        >
                            Home
                        </p>
                        {paths.length && <img src={right} className="w-3" />}
                        {paths.map((p, i) => (
                            <div key={i} className="flex gap-1 items-center">
                                <p
                                    style={paths.length === i + 1 ? { fontWeight: '600' } : { fontWeight: '300' }}
                                    role="button"
                                    className=" m-0 text-xs font-light text-nowrap"
                                    onClick={() => {
                                        const new_path = paths
                                            .slice(0, i + 1)
                                            .map((c) => reverseDictionary[c])
                                            .join('/');
                                        localStorage.removeItem("activeChat")
                                        handleRoute(`/dashboard/${new_path}`);
                                    }}
                                >
                                    {p}
                                </p>
                                {paths.length !== i + 1 && <img src={right} className="w-3" />}
                            </div>
                        ))}
                    {topChild}
                    {paths.length && <img src={right} className="w-3" />}
                    <span className="font-semibold text-xs">{name}</span>
                </div>
                <div className='flex justify-between'>
                    <span className="border relative hover:border-gray-500 border-gray-300 transition-all rounded-md p-2">
                        <input
                            onChange={(e) => {
                                onSearch(e.target.value)
                            }}
                            className="text-xs w-[60vh] font-normal pe-6"
                            type="text"
                            placeholder="Search by date or topic"
                        />
                        <img className="w-4 absolute right-3 top-[50%] translate-y-[-50%]" src={search} alt="search" />
                    </span>

                    <p onClick={onDeleteAllHistory} className='text-[0.75rem] text-[var(--gmind-orange)] font-normal mr-5'>Clear All</p>
                </div>
                <div className="space-y-5 h-[70vh] overflow-y-auto pe-5">
                    {
                        histories.map((sortedHistory: HistoryTypeFormat, index) => {
                            return (
                                <div key={index} className="flex flex-col gap-y-3 p-3">
                                    <h3 className='ml-5 font-optimistic text-black text-[0.9rem]'>{sortedHistory.date}</h3>
                                    {sortedHistory.histories?.map((history: ChatType[]) => {
                                        // // const { content: assistantContent } = history[1];
                                        // const { content: userContent } = history[0];
                                        // // const { id } = history[history.length - 1];
                                        // // const { date } = history[history.length - 1];

                                        let historyData = extractAssistantData(history);


                                        // console.log(historyData);


                                        if ( !historyData ) return (<></>)

                                            let date = historyData?.date;
                                            let id = historyData?.id;
                                            let assistantContent = historyData?.content;

                                        return (
                                            <section
                                                role="button"
                                                key={id}
                                                className="flex items-center justify-between gap-x-3 px-4"
                                            >
                                                <div className="w-full rounded-lg hover:border hover:border-gray-500 transition-all flex items-center justify-between py-1 px-4"  onClick={() => { 
                                                        // console.log(history);
                                                        handleClick(id ?? ''); 
                                                    }}>
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-normal text-xs">
                                                            { assistantContent ? assistantContent?.length > 80 
                                                                ? assistantContent?.slice(0, 80) + '...'
                                                                : assistantContent : ''}
                                                        </span>
                                                    </div>
                                                    <div className="flex flex-col gap-y-0 items-center">
                                                        <span className="text-[0.6rem] text-brandOrange">{ dateFormat({ dateSent:  date ?? '14:23 12-8-2023'} ).split(" ")[1] }</span>
                                                        <span className="text-[0.6rem] text-brandOrange">{ dateFormat({ dateSent:  date ?? '14:23 12-8-2023'} ).split(" ")[0] }</span>
                                                    </div>
                                                </div>

                                                <IoMdMore role="button" onClick={(e) => { 
                                                     e.stopPropagation(); // Prevent event bubbling
                                                     setSideBarValue(id);
                                                 }} className="text-[1.3rem] hover:flex text-gray-200  hover:text-black" />

                                                {getSideBarValue(id) && (
                                                    <ul className="bg-white shadow-md px-4 py-2 list-none rounded-md absolute right-[12%]">
                                                        
                                                        <li
                                                            role="button"
                                                            onClick={() => { onDeleteHistory(id ?? ''); refresh(); }}
                                                            className="p-2 select-none"
                                                        >
                                                            {
                                                                <div className="relative  flex items-center">
                                                                    <p
                                                                        className="text-sm p-2 py-1 text-[var(--gmind-orange)] font-medium"
                                                                    >
                                                                        <span>Delete</span>
                                                                    </p>
                                                                    <img className='w-[1rem] h-[1rem] cursor-pointer' src={deleteImage} alt="delete" />
                                                                </div>  
                                                            }
                                                        </li>
                                                    </ul>
                                                )}

                                            </section>
                                        );
                                    })}
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
            
        </section>
    );
};

export default HistoryModal;
