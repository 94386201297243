import play from '@icons/play.svg';
import { useRef, useState } from 'react';

type sideBarType = {
    header: string;
    content: string;
    videoUrl: string;
    thumbnailUrl: string;
};

const Playground = () => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const videoRef = useRef<HTMLVideoElement>(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
    };

    const handlePlayPause = async () => {
        if (videoRef.current) {
            try {
                if (isPlaying) {
                    await videoRef.current.pause();
                } else {
                    await videoRef.current.play();
                }
                setIsPlaying(!isPlaying);
            } catch (error) {
                console.error('Error playing/pausing the video:', error);
            }
        }
    };

    const handleSidebarClick = async (index: number) => {
        if (videoRef.current && isPlaying) {
            try {
                await videoRef.current.pause();
                setIsPlaying(false);
            } catch (error) {
                console.error('Error pausing the video:', error);
            }
        }
        setSelectedIndex(index);
    };

    const sidebarItems: Array<sideBarType> = [
        {
            header: 'Ask G-mind AI',
            content: 'Enjoy Smooth and effortless process of sending and receiving cash.',
            videoUrl: "https://www.youtube.com/embed/9uRjm63PGcY?rel=0&vq=hd1080",
            thumbnailUrl: "https://img.youtube.com/vi/9uRjm63PGcY/maxresdefault.jpg",
        },
        {
            header: 'Generate Content',
            content: 'Creating engaging, relevant content tailored to your interests.',
            videoUrl: "https://www.youtube.com/embed/HUngQLvzFsc?rel=0&vq=hd1080",
            thumbnailUrl: "https://img.youtube.com/vi/HUngQLvzFsc/maxresdefault.jpg",
        },
        {
            header: 'Curriculum Planner',
            content: 'Efficiently organize and streamline your teaching with our planner.',
            videoUrl: "https://www.youtube.com/embed/GINg82_wXbo?rel=0&vq=hd1080",
            thumbnailUrl: "https://img.youtube.com/vi/GINg82_wXbo/maxresdefault.jpg",
        },
        {
            header: "Slide Maker",
            content: "Effortlessly design professional slides using our slide maker.",
            videoUrl: "https://www.youtube.com/embed/Z1eAI0GSVJY?rel=0&vq=hd1080",
            thumbnailUrl: "https://img.youtube.com/vi/Z1eAI0GSVJY/maxresdefault.jpg",
        },
        {
            header: 'Transcriber',
            content: 'Record or upload audio for accurate transcript in real time',
            videoUrl: "https://www.youtube.com/embed/JXpvRPQqgH0?rel=0&vq=hd1080",
            thumbnailUrl: "https://img.youtube.com/vi/JXpvRPQqgH0/maxresdefault.jpg",
        },
        {
            header: 'Speech Synthesizer',
            content: 'Easily converts written text into spoken words.',
            videoUrl: "https://www.youtube.com/embed/5y38SneJiR4?rel=0&vq=hd1080",
            thumbnailUrl: "https://img.youtube.com/vi/5y38SneJiR4/maxresdefault.jpg",
        },
        {
            header: 'Image Maker',
            content: 'Crafting visual stories with innovative image-making techniques.',
            videoUrl: "https://www.youtube.com/embed/VSXgrBFBxmY?rel=0&vq=hd1080",
            thumbnailUrl: "https://img.youtube.com/vi/VSXgrBFBxmY/maxresdefault.jpg",
        },
    ];
    
    
    
    

    return (
        <div className="w-full px-10 md:py-10 lg:py-2 lg:h-[44rem] lg:max-h-full h-auto md:h-auto b-white lg:bg-[var(--gmind-playground-grey)] flex flex-col gap-x-10 gap-y-5 rounded-[2.75rem] overflow-y-hidden">
            <div className="self-center flex flex-col items-center justify-center">
                <h2 className="text-center text-[0.8rem] md:text-[1.5rem] font-medium text-[var(--gmind-black)]">
                    Explore Gmind Features.
                </h2>
                <h2 className="text-center text-[1.5rem] md:text-[1.8rem] font-medium text-[var(--gmind-black)]">
                    The Ultimate AI Tool for Transforming Educational Content Generation.
                </h2>
                <p className="text-center text-[1rem] md:text-[0.875rem] font-normal text-[var(--gmind-light-black)]">
                    Gmind <span className="font-normal md:font-medium">revolutionizes education</span> with
                    personalized, interactive, and efficient learning experiences.
                </p>
            </div>

            <div className="flex lg:hidden  flex-col gap-4 h-auto">
                {sidebarItems.map((item, index) => (
                    <div key={index} className="flex flex-col gap-y-3">
                        <h2 className="text-[1rem] font-medium text-[var(--gmind-plain-black)]">{item.header}</h2>
                        <p className="text-[0.813rem] font-normal text-[var(--gmind-accordion-header)]">
                            {item.content}
                        </p>

                        <div className="relative">
                            <div
                                id="player"
                                className=" rounded-[1rem] w-full  h-[250px] bg-[var(--gmind-grey-color50)] shadow-md"
                                style={{
                                    backgroundImage: `url(${item.thumbnailUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            >
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={item.videoUrl}
                                    title={item.header}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="lg:flex lg:gap-x-4 lg:w-full lg:h-auto hidden">
                <div
                    id="sidebar"
                    className="w-[24.5rem] p-5 flex flex-col gap-y-3 min-h-[80%] max-h-full overflow-scroll overflow-y-scroll h-[35rem] overflow-x-hidden"
                >
                    {sidebarItems.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => handleSidebarClick(index)}
                            className={`w-[20.5rem] cursor-pointer flex flex-col gap-y-2 w-full ${selectedIndex == index ? 'bg-[var(--gmind-selected-bg)]' : 'bg-white'} rounded-[1rem] border border-[var(--gmind-border-black10)] p-[1.5rem]`}
                        >
                            <h2 className="md:text-[1.5rem] lg:text-[1.5rem] font-medium text-[var(--gmind-plain-black)]">{item.header}</h2>
                            <p className="text-[0.8rem] font-normal text-[var(--gmind-accordion-header)]">
                                {item.content}
                            </p>
                        </div>
                    ))}
                </div>
                {sidebarItems.map((item, index) => (
                    index == selectedIndex && (
                        <div
                            id="player"
                            key={index}
                            className="relative rounded-[1rem] w-[43rem] h-full bg-[var(--gmind-grey-color50)]"
                            style={{
                                backgroundImage: `url(${item.thumbnailUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <iframe
                                width="100%"
                                height="100%"
                                src={item.videoUrl}
                                title={item.header}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                            {/* <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                                <button
                                    className="w-[8rem] h-[8rem] rounded-full bg-[var(--gmind-white40)] flex items-center justify-center"
                                    onClick={handlePlayPause}
                                >
                                    <img onMouseEnter={handleShow} src={isPlaying ? play : play} alt="play-pause-button" onMouseLeave={() => setShow(false)} />
                                </button>
                            </div> */}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default Playground;
