import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import BackButton from '@components/BackButton';
import { toast } from 'react-toastify';
import useEndpoint from '../../auth/auth_endpoints';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

interface LoginProps {
    handleSignIn: () => void;
}


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};


export const CreateAcount = ({ handleSignIn }: LoginProps) => {
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ passwordVisibility, setPasswordVisibility ] = useState<{[key: string]: boolean}>({
        'password': false,
        'confirmPassword': false,
    });

    const query = useQuery();
    const navigate = useNavigate();
    const [freeTrial, setFreeTrial] = useState<boolean>(false);

    useEffect(() => {
        const free = query.get('forFree') || '';

        setFreeTrial(free == '1' ? true: false);

        // navigate({
        //   pathname: window.location.pathname,
        //   search: ''
        // }, { replace: true });
    
    }, []);

    const handleClickPassVisibleIcon = (key: string) => {
        setPasswordVisibility((prevData) => ({
            ...prevData,
            [key]: !prevData[key]
        }))
    }

    const onFinish = (values: any) => {
        try {
            const { email, firstname, lastname, phonenumber, password, confirmpassword } = values;

            if (!email || !firstname || !lastname || !phonenumber || !password || !confirmpassword) {
                toast.error('All fields are required');
                return;
            }

            if (password !== confirmpassword) {
                toast.error('Passwords do not match');
                return;
            }

            const dataToSend = {
                firstName: firstname,
                lastName: lastname,
                phonenumber,
                email: email,
                password: password,
                cpassword: confirmpassword,
                freeTrial: freeTrial ? 1 : 0,
            };

            console.log(dataToSend);

            setLoading(true);
            useEndpoint()
                .signup(dataToSend)
                .then((res: any) => {
                    setLoading(false);
                    if (res?.success) {
                        localStorage.setItem('token', res?.data?.token);
                        const { id, firstName, lastName, email, isVerified, plan } = res.data;
                        localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified }));
                        const callback = localStorage.getItem('callback') ?? '';

                        if (callback) {
                            window.location.href = callback;
                        } else {
                            window.location.href = '/verify?email=' + email;
                        }
                        toast.success(res?.message);
                    } else {
                        toast.error(res?.message);
                    }
                });

            console.log(email);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="create-account-container gap-y-3 w-full  md:w-[516px] py-0 px-4 md:px-10">
            <BackButton />
            <div className="ctitle text-[1.5rem] md:text-[2.5rem] mb-3">Create Account</div>
            <Form layout="vertical" onFinish={onFinish} initialValues={{ rememberMe }}>
                <div className="flex flex-col md:flex-row md:gap-x-5 md:gap-y-2">
                    <Form.Item
                        name="firstname"
                        label={<label className="label">First Name</label>}
                        rules={[
                            {
                                message: 'First name is required',
                                required: true,
                            },
                        ]}
                        initialValue=""
                        className="w-full md:w-1/2"
                    >
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type="text" />
                    </Form.Item>
                    <Form.Item
                        name="lastname"
                        label={<label className="label">Last Name</label>}
                        rules={[
                            {
                                message: 'Last name is required',
                                required: true,
                            },
                        ]}
                        initialValue=""
                        className="w-full md:w-1/2"
                    >
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type="text" />
                    </Form.Item>
                </div>
                <div className="flex flex-col md:flex-row md:gap-x-5 md:gap-y-2">
                    <Form.Item
                        name="phonenumber"
                        label={<label className="label">Phone Number</label>}
                        rules={[
                            {
                                message: 'Phone number is required',
                                required: true,
                            },
                        ]}
                        initialValue=""
                        className="w-full md:w-1/2"
                    >
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type="text" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={<label className="label">Email</label>}
                        rules={[
                            {
                                message: 'Email is required',
                                required: true,
                            },
                        ]}
                        initialValue=""
                        className="w-full md:w-1/2"
                    >
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type="email" />
                    </Form.Item>
                </div>
                <Form.Item
                    name="password"
                    label={<label className="label">Set Password</label>}
                    rules={[
                        {
                            message: 'Password is required',
                            required: true,
                        },
                    ]}
                    initialValue=""
                >
                    <div className='flex items-center relative'>
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type= { passwordVisibility['password'] ? 'text' : "password" } />
                        <span onClick={() => handleClickPassVisibleIcon('password')} className='cursor-pointer absolute right-4'>
                           { 
                              passwordVisibility['password'] ?  
                                <EyeOutlined />
                              :  <EyeInvisibleOutlined />
                           }
                        </span>
                    </div>
                </Form.Item>
                <Form.Item
                    name="confirmpassword"
                    label={<label className="label">Confirm Password</label>}
                    rules={[
                        {
                            message: 'Confirm password is required',
                            required: true,
                        },
                    ]}
                    initialValue=""
                >
                    <div className='flex items-center relative'>
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type= { passwordVisibility['confirmPassword'] ? 'text' : "password" } />
                        <span onClick={() => handleClickPassVisibleIcon('confirmPassword')} className='cursor-pointer absolute right-4'>
                           { 
                              passwordVisibility['confirmPassword'] ?  
                                <EyeOutlined />
                              :  <EyeInvisibleOutlined />
                           }
                        </span>
                    </div>
                </Form.Item>
                <div
                    className="block md:hidden"
                    style={{
                        fontSize: '13px',
                        fontWeight: '400',
                        fontFamily: "'Inter', sans-serif",
                        width: '218px',
                    }}
                >
                    Already have an account?{' '}
                    <span style={{ color: '#e55109', cursor: 'pointer' }} onClick={handleSignIn}>
                        Sign In
                    </span>
                </div>

                <div className="create-account-button-container flex flex-col md:flex-row items-center md:items-end justify-between mt-3">
                    <button
                        type="submit"
                        className="create-account px-5 w-full md:w-40 py-2 bg-orange-500 text-white rounded-md mb-3 md:mb-0"
                    >
                        Create Account
                    </button>

                    <div
                        className="hidden md:block"
                        style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            fontFamily: "'Inter', sans-serif",
                            width: '218px',
                        }}
                    >
                        Already have an account?{' '}
                        <span style={{ color: '#e55109', cursor: 'pointer' }} onClick={handleSignIn}>
                            Sign In
                        </span>
                    </div>
                </div>
            </Form>
        </div>
    );
};
